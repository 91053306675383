<template>
  <TransitionRoot appear :show="localIsOpen" as="template">
    <Dialog
      as="div"
      class="relative"
      style="z-index: 9999"
      @close="localIsOpen = false"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="flex w-full max-w-lg transform flex-col gap-3 overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl shadow-primary-900 transition-all"
            >
              <slot name="body"></slot>

              <div class="flex flex-row justify-end gap-2">
                <BaseButton
                  :title="$t('button.close')"
                  icon="times"
                  size="small"
                  theme-color="bg-secondary text-contrast-secondary"
                  @click="localIsOpen = false"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

export default {
  name: 'ModalResultScan',
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    BaseButton
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  emits: ['update:isOpen'],
  computed: {
    localIsOpen: {
      get() {
        return this.isOpen
      },
      set(value) {
        this.$emit('update:isOpen', value)
      }
    }
  }
}
</script>
