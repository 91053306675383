import apiClient from '@u/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch

  fetchNewPayment: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`model/new?model=payment&invoice_id=${payload.invoice_id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  fetchPaymentById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoicing/${payload.invoice_id}/payment/${payload.payment_id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // region store
  storeNewPayment: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise(async (resolve, reject) => {
      let url = `invoicing/${payload.invoice_id}/payment`;
      if (payload.change_status_dossier === 1) {
        url += `?change_status_dossier=1`;
      }
      apiClient
        .post(url, payload.payment)
        .then((response) => {
          if (response.status === 200) {
            commit('setInvoice', response.data.invoice)
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updatePaymentById: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      let url = `invoicing/${payload.invoice_id}/payment/${payload.payment_id}`;
      if (payload.change_status_dossier === 1) {
        url += `?change_status_dossier=1`;
      }
      apiClient
        .put(url, payload.payment)
        .then((response) => {
          commit('setInvoice', response.data.invoice)
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  deletePayment: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`invoicing/${payload.invoice_id}/payment/${payload.payment_id}`)
        .then((response) => {
          commit('setInvoice', response.data.invoice)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async undoDeletePayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(
          `invoicing/${payload.invoice_id}/payment/${payload.payment_id}/undo-delete`
        )
        .then((response) => {
          commit('setInvoice', response.data.invoice)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
