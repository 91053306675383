<template>
  <Listbox v-model="localTheme">
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full cursor-default rounded-lg border bg-white py-1 pl-2 pr-10 text-left dark:bg-slate-400 dark:text-gray-600 sm:text-sm"
      >
        <span class="flex flex-row items-center gap-2">
          <span
            :data-theme="
              listThemes.find((item) => item.key === localTheme)?.label
            "
            class="inline-block h-3 w-3 rounded bg-gradient-to-br from-primary-400 to-primary-600"
            >&nbsp;</span
          >
          <span class="block truncate text-left capitalize">{{
            listThemes.find((theme) => theme.key === localTheme)?.label
          }}</span>
        </span>

        <span
          class="pointer-events-none absolute inset-y-0 right-2 flex items-center"
        >
          <FontAwesomeIcon :icon="['fal', 'chevron-down']" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute bottom-0 mb-8 max-h-60 w-full origin-bottom-left overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-400 dark:text-gray-600 sm:text-sm"
        >
          <ListboxOption
            v-for="themes in listThemes"
            v-slot="{ active, selected }"
            :key="themes.key"
            :value="themes.key"
            as="template"
          >
            <li
              :data-theme="themes.key"
              :class="[
                selected
                  ? 'bg-primary-500 text-white dark:bg-slate-700 dark:text-gray-300'
                  : active
                  ? 'bg-primary-400 text-white dark:text-gray-300'
                  : 'text-gray-900',
                'relative cursor-default select-none py-1 pl-8 pr-4'
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'flex items-center gap-2 truncate'
                ]"
              >
                <span
                  class="inline-block h-3 w-3 rounded bg-gradient-to-br from-primary-400 to-primary-600"
                >
                  &nbsp;
                </span>
                <span class="capitalize">{{ themes.label }}</span>
              </span>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-white"
              >
                <FontAwesomeIcon :icon="['fas', 'check']" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { defineComponent } from 'vue'

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'ThemeSelector',
  components: {
    FontAwesomeIcon,
    ListboxOption,
    ListboxOptions,
    Listbox,
    ListboxButton
  },
  props: {
    theme: {
      type: String,
      required: true
    }
  },
  emits: { 'update:theme': null },
  data() {
    return {
      listThemes: [
        {
          key: 'blue',
          label: 'blue'
        },
        {
          key: 'tangerine',
          label: 'tangerine'
        },
        {
          key: 'red',
          label: 'red'
        },
        {
          key: 'green',
          label: 'green'
        },
        {
          key: 'mandy',
          label: 'mandy'
        },
        {
          key: 'waikawa-gray',
          label: 'waikawa gray'
        },
        {
          key: 'iron',
          label: 'iron'
        },
        {
          key: 'spectra',
          label: 'spectra'
        },
        {
          key: 'copper-rust',
          label: 'copper rust'
        },
        {
          key: 'bazaar',
          label: 'bazaar'
        }
      ]
    }
  },
  computed: {
    localTheme: {
      get() {
        return this.theme
      },
      set: function (value) {
        this.$emit('update:theme', value)
      }
    }
  }
})
</script>
