export default {
  getEvents(state) {
    return state.eventsList
  },
  getEvent(state) {
    return state.event
  },
  getFocusEvent(state) {
    return state.focusEvent
  },
  getDate(state) {
    return state.date
  },
  getPlanningMetadata(state) {
    return state.planningMetadata
  },
  getEventMetadata(state) {
    return state.eventMetadata
  },
  getEventDisponibilities(state) {
    return state.eventDisponibilities
  },
  getCalendarState(state) {
    return state.calendarState
  },
  getEventActivity(state) {
    return state.eventActivity
  },
  getDataTransferObject(state) {
    return state.dataTransferObject
  }
}
