<template>
  <div class="flex flex-col divide-y">
    <div class="pb-4">
      <NotifyByButton
        v-model="user.notify_me_by_notification"
        :label="$t('attributes.notify_me_by_notification')"
        name="notify_me_by_notification"
        :icon="['fal', 'bell']"
        :edition-mode="editionMode"
      />
    </div>
    <div class="py-4">
      <NotifyByButton
        v-model="user.notify_me_by_sms"
        :label="$t('attributes.notify_me_by_sms')"
        name="notify_me_by_sms"
        :icon="['fal', 'message-sms']"
        :edition-mode="editionMode"
      >
        <div v-if="$functionality.module.hasNot('sms')" class="col-span-2">
          <ModuleNotEnabledComponent module="sms" />
        </div>
        <div v-else>
          <BaseShowEditPhoneInput
            v-model:countryValue="user.mobile_country"
            v-model:numberValue="user.mobile_field"
            class="col-start-1"
            :label="$t('attributes.mobile')"
            :edition-mode="editionMode"
            name="mobile"
            :errors="errors"
            :metadata="metadata"
            required
          />
        </div>
      </NotifyByButton>
    </div>
    <div class="py-4">
      <NotifyByButton
        v-model="user.notify_me_by_slack"
        :label="$t('attributes.notify_me_by_slack')"
        name="notify_me_by_slack"
        :icon="['fab', 'slack']"
        iconType="fab"
        :edition-mode="editionMode && $functionality.module.has('slack')"
      >
        <div v-if="$functionality.module.hasNot('slack')" class="col-span-2">
          <ModuleNotEnabledComponent module="slack" />
        </div>
        <div v-else class="col-span-2">
          <BaseShowEditInput
            v-model="user.slack_url"
            :label="$t('attributes.slack_url')"
            name="slack_url"
            :errors="errors?.slack_url"
            :edition-mode="editionMode && $functionality.module.has('slack')"
            :required="true"
          />
        </div>
      </NotifyByButton>
    </div>
    <div class="hidden pt-4">
      <NotifyByButton
        v-model="user.notify_me_by_mobile_app"
        :label="$t('attributes.notify_me_by_mobile_app')"
        name="notify_me_by_mobile_app"
        :icon="['fal', 'mobile']"
        :edition-mode="editionMode"
      />
    </div>
  </div>
</template>
<script>
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseShowEditPhoneInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditPhoneInput.vue'
import NotifyByButton from '@/views/settings/user/form/tabs/userNotification/NotifyByButton.vue'
import ModuleNotEnabledComponent from '@c/GlobalFormComponents/ModuleNotEnabledComponent.vue'

export default {
  name: 'UserNotification',
  components: {
    ModuleNotEnabledComponent,
    BaseShowEditInput,
    BaseShowEditPhoneInput,
    NotifyByButton
  },
  props: {
    editionMode: {},
    errors: {},
    metadata: {},
    user: {}
  }
}
</script>
