<template>
  <DialogTitle
    as="h3"
    class="block items-center gap-2 text-lg font-medium leading-6 text-gray-900 capitalize-first"
  >
    {{ title }}
  </DialogTitle>
  <DialogDescription v-if="invoice" class="flex flex-col gap-2">
    <RouterLink
      class="text-primary"
      :to="{
        name: 'invoice_info_route',
        params: {
          invoicing_id: invoice.id
        }
      }"
      @click="$emit('close')"
    >
      <div class="flex flex-col rounded border p-2">
        <div class="flex gap-2">
          <span class="inline-block text-slate-700 capitalize-first">
            {{ $t('attributes.reference') }} :
          </span>
          <span class="font-semibold">
            {{ invoice.reference }}
          </span>
        </div>
        <div class="flex gap-2">
          <span class="inline-block text-slate-700 capitalize-first">
            {{ $t('attributes.date') }} :
          </span>
          <span class="font-semibold">
            {{ $h.formatDate(invoice.emission_date, 'LL') }}
          </span>
        </div>
      </div>
    </RouterLink>
    <div
      v-if="invoice.invoiceable_nomalized"
      class="flex flex-row rounded border p-2"
    >
      <div class="flex gap-2">
        <span class="inline-block text-slate-700 capitalize-first">
          {{ $t('attributes.name') }} :
        </span>
        <span class="font-semibold">
          {{ invoice.invoiceable_nomalized.full_name }}
        </span>
      </div>
    </div>
    <div v-if="invoice.dossier" class="flex flex-row rounded border p-2">
      <div class="flex-1">
        <RouterLink
          class="text-primary"
          :to="{
            name: 'dossier_form_edit_route',
            params: {
              id: invoice.dossier.id
            }
          }"
          @click="$emit('close')"
        >
          <template v-if="invoice.dossier.concernedPerson">
            <h3 class="font-bold">
              {{ invoice.dossier.concernedPerson.full_name }}
            </h3>
            <h4 class="font-semibold"># {{ invoice.dossier.reference }}</h4>
          </template>
          <h3 v-else class="font-bold">{{ invoice.dossier.reference }}</h3>
        </RouterLink>
      </div>
    </div>
  </DialogDescription>
</template>

<script>
import { mapActions } from 'vuex'
import { DialogDescription, DialogTitle } from '@headlessui/vue'
import DocTypes from '../../../assets/enums/invoicing/DocTypes'

export default {
  name: 'InvoiceSummary',
  components: { DialogDescription, DialogTitle },
  props: {
    invoiceId: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      invoice: null
    }
  },
  computed: {
    title() {
      if (!this.invoice) {
        return this.$t('global.loading')
      }
      switch (this.invoice.doc_type) {
        case DocTypes.INVOICE:
          return this.$t('attributes.invoice')
        case DocTypes.QUOTE:
          return this.$t('global.quote')
        case DocTypes.CREDIT_NOTE:
          return this.$t('global.credit_note')
        case DocTypes.PURCHASE_ORDER:
          return this.$t('global.purchase_order')
        default:
          return this.$t('global.undefined')
      }
    }
  },
  mounted() {
    this.processGetInvoiceByIdAction({
      id: this.invoiceId
    }).then((response) => {
      this.invoice = response.invoice
    })
  },
  methods: {
    ...mapActions({
      processGetInvoiceByIdAction: 'invoicing/fetchInvoiceID'
    })
  }
}
</script>
