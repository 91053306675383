const DashboardTypes = Object.freeze({
  // Dossier view
  DASHBOARD: 'dashboard',
  // Agenda view
  AGENDA: 'agenda',
  // Planning view
  PLANNING: 'planning',
  // Supplier order view
  SUPPLIER_ORDER: 'supplier_order',
  // Stats view
  ANALYTICS: 'analytics',
  // Invoice view
  INVOICE: 'invoice',
  PURCHASE_ORDER: 'purchase_order',
  QUOTE: 'quote',
  CREDIT_NOTE: 'credit_note',
})

export default DashboardTypes
