<template>
  <span v-if="errorsInTab?.length > 0" class="ml-1">
    <BaseBadge
      color="danger"
      icon="triangle-exclamation"
      :text="errorsInTab.length"
    />
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseBadge from '@/views/calendar/planning/components/BaseBadge'

export default {
  name: 'BaseSimpleTabError',
  components: { BaseBadge },
  props: {
    tab: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors',
      initial_metadata: 'metadata/getInitialMetadata'
    }),
    errorsInTab() {
      if (!this.tab.field_list || Object.keys(this.errors).length < 1) return []

      //#region funeral home partner
      if (
        this.tab.routeName.includes('funeral_home_partners_route') &&
        this.errors.funeral_home_partner
      ) {
        return this.tab.field_list.reduce((old, curr) => {
          if (this.errors.funeral_home_partner[curr]) old.push(curr)
          return old
        }, [])
      }
      //#endregion

      //#region translation
      if (this.tab.routeName.includes('translation_route')) {
        return this.tab.field_list.reduce((old, curr) => {
          this.initial_metadata?.settings?.available_languages?.forEach(
            (language) => {
              if (language !== this.$i18n.locale) {
                if (this.errors[curr] && this.errors[curr][language])
                  old.push(`${curr}.${language}`)
              }
            }
          )
          return old
        }, [])
      }
      //#endregion

      //#region item specifics
      if (this.tab.routeName.includes('specifics_route')) {
        return null
      }
      //#endregion

      return Object.keys(this.errors).reduce((old, curr) => {
        if (this.tab.field_list.includes(curr)) {
          if (
            [
              'name',
              'short_name',
              'variante_name',
              'remarks',
              'variant_name',
              'internal_note'
            ].includes(curr)
          ) {
            if (
              typeof this.errors[curr] === Array ||
              this.errors[curr][this.$i18n.locale]
            ) {
              console.log('bon translatable')
              old.push(curr)
            }
            {
            }
          } else {
            old.push(curr)
          }
        }
        return old
      }, [])
    }
  }
}
</script>
