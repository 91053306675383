<template>
  <div v-if="module" class="mt-2 flex h-full flex-col gap-10 overflow-y-auto">
    <div class="flex shrink-0 flex-row gap-4">
      <div class="shrink-0">
        <img :src="module.img" :alt="module.name" class="w-16" />
      </div>
      <div class="flex-1">
        <h2 class="text-2xl">{{ module.name }}</h2>
        <div class="flex">
          <span class="flex flex-row divide-x rounded border">
            <span class="px-2">{{ module.billing_type }}</span>
            <span class="px-2">
              {{
                module.pricing?.price
                  ? $h.formatCurrency(module.pricing.price)
                  : '--'
              }}
            </span>
          </span>
        </div>
      </div>
      <div class="shrink-0">
        <WalkmanButton
          v-model="localEnabled"
          :options="[
            {
              value: false,
              label: $t('global.disabled'),
              bgClass:
                'bg-yellow-500 hover:bg-yellow-400 focus:bg-yellow-400 active:bg-yellow-400'
            },
            {
              value: true,
              label: $t('global.enabled'),
              bgClass:
                'bg-green-500 hover:bg-green-400 focus:bg-green-400 active:bg-green-400'
            }
          ]"
          size="small"
        />
      </div>
    </div>
    <div class="flex flex-row gap-4">
      <img
        v-for="picture in module.gallery"
        :key="picture"
        class="h-32 rounded border"
        :src="picture"
        alt="SMS Screenshot"
      />
    </div>
    <div
      class="prose prose-sm max-w-full flex-1 prose-headings:m-0 prose-p:my-0.5"
      v-html="module.description"
    ></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import WalkmanButton from '@c/WalkmanButton.vue'

export default {
  name: 'TheMarketPlaceListPage',
  components: { WalkmanButton },
  props: {
    optionId: {
      type: String,
      required: true
    }
  },
  emits: ['reload-modules'],
  data() {
    return {
      module: null,
      enabledModules: []
    }
  },
  computed: {
    localEnabled: {
      get() {
        return this.enabledModules.includes(this.optionId)
      },
      set(value) {
        if (value !== this.enabled) {
          if (value) {
            this.processEnableMarketplaceModuleAction({
              module_id: this.optionId
            }).then((response) => {
              if (response.success) {
                this.fetchModule()
                this.processFetchAllMetadataAction().then((response) => {
                  this.emitter.emit('reload-menu')
                })
              }
            })
          } else {
            this.processDisableMarketplaceModuleAction({
              module_id: this.optionId
            }).then((response) => {
              if (response.success) {
                this.fetchModule()
                this.processFetchAllMetadataAction().then((response) => {
                  this.emitter.emit('reload-menu')
                })
              }
            })
          }
        }
      }
    }
  },
  mounted() {
    this.fetchModule()
  },
  methods: {
    ...mapActions({
      processEnableMarketplaceModuleAction:
        'marketplace/enableMarketplaceModule',
      processDisableMarketplaceModuleAction:
        'marketplace/disableMarketplaceModule',
      processGetMarketplaceSpecificModuleAction:
        'marketplace/getMarketplaceSpecificModule'
    }),
    fetchModule() {
      this.processGetMarketplaceSpecificModuleAction({
        module_id: this.optionId
      }).then((response) => {
        this.module = response.module
        this.enabledModules = [...response.metadata.module_enabled]
      })
    }
  }
}
</script>
