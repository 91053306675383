import TheItemsCatalogIndexPage from '@/views/catalog/TheItemsCatalogIndexPage.vue'

export const catalogRootPages = [
  {
    path: 'catalog',
    name: 'catalog_items_route',
    children: [
      {
        path: '',
        component: TheItemsCatalogIndexPage,
        name: 'catalog_list_items_route'
      },
      {
        path: ':itemId',
        children: [
          {
            path: 'price',
            component: TheItemsCatalogIndexPage,
            name: 'catalog_item_page_route',
            props: (route) => {
              return {
                section: 'price',
                ...route.params
              }
            }
          },
          {
            path: 'stock',
            component: TheItemsCatalogIndexPage,
            name: 'catalog_item_stock_route',
            props: (route) => {
              return {
                section: 'stock',
                ...route.params
              }
            }
          }
        ]
      }
    ]
  }
]
