<template>
  <template v-if="editionMode">
    <SelectComponent
      v-model="selectedValues"
      :name="name"
      :label="label ?? $t('attributes.location')"
      :values="filteredLocations"
      display-key="name"
      :multiple="multiple"
      :favorite="favoriteAsArray"
      :required="required"
      :edition-mode="editionMode"
      :errors="errors"
      :class="$attrs.class"
    />
  </template>
  <BaseShowLabel
    v-else
    :label="label ?? $t('attributes.location')"
    :model-value="selectedLabel"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { computed } from 'vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'

export default {
  name: 'SelectStorageLocation',
  components: { BaseShowLabel, SelectComponent },
  props: {
    modelValue: {
      type: [Array, Number],
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    errorKey: {
      type: String,
      required: true
    },
    filterSiteId: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: 'funeral_home'
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return { selectedValues }
  },
  computed: {
    ...mapGetters({
      locations: 'metadata/getLocationsMetadata',
      sites: 'metadata/getSitesMetadata'
    }),
    filteredLocations() {
      return this.locations
        .filter(
          (location) =>
            location.is_storage_location &&
            (!this.filterSiteId || location.site_id === this.filterSiteId)
        )
        .map((location) => {
          return {
            ...location,
            name: `${location.name} (${
              this.sites.find((site) => site.id === location.site_id)?.name ??
              '--'
            })`
          }
        })
    },
    favoriteAsArray() {
      return this.sites
        .filter((site) => site.favorite === 1)
        .map((site) => site.id)
    },
    selectedLabel() {
      return this.sites.find((site) => site.id === this.modelValue)?.name
    }
  }
}
</script>
