<template>
  <template v-if="content !== null">
    <div v-if="!big" class="cursor-default truncate" :data-tint="color">
      <span
        class="flex justify-center gap-1 rounded-md bg-primary px-1 py-0.5 text-contrast-primary"
      >
        <FontAwesomeIcon v-if="icon" :icon="['fal', icon]" />
        <span v-if="content" class="truncate capitalize-first">
          {{ content }}
        </span>
      </span>
    </div>

    <div
      v-else
      :data-tint="color"
      class="flex select-none truncate rounded-md bg-primary px-4 py-2.5 text-contrast-primary"
      aria-expanded="false"
    >
      <FontAwesomeIcon v-if="icon" :icon="['fal', icon]" />
      <span v-if="content" class="truncate capitalize-first">
        {{ content }}
      </span>
    </div>
  </template>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseLabel',
  components: { FontAwesomeIcon },
  props: {
    content: { type: String, required: true },
    color: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    big: { type: Boolean, required: false, default: false }
  },
  computed: {
    colorClass() {
      return this.color === '' ? 'bg-slate-300' : `bg-theme-${this.color}`
    }
  }
}
</script>
