const TheUsagePage = () =>
  import('@/views/settings/funeralManager/TheUsagePage.vue')

export const settingsFuneralManagerRoutes = [
  {
    path: 'usage',
    name: 'settings_usage_route',
    component: TheUsagePage
  }
]
