<template>
  <span class="relative mr-5 inline-block">
    <BaseButton
      :color="color"
      :icon="iconTest"
      :tooltip="tooltip"
      size="small"
      class="cursor-default"
      cursor="default"
      text-color="white"
    />
    <span
      v-if="count && icon"
      :class="`absolute left-1.5 right-0 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform select-none items-center justify-center rounded-full bg-red-500
                px-2.5 py-1 text-xs font-bold  leading-none text-white`"
    >
      {{ count }}
    </span>
  </span>
</template>

<script>
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'NotificationIconComponent',
  components: { BaseButton },
  props: {
    count: {},
    color: {},
    tooltip: {},
    icon: {}
  },
  computed: {
    colorClass() {
      return this.color ? 'bg-' + this.color : 'bg-red-600'
    },
    iconTest() {
      return this.icon.substring(3)
    }
  }
}
</script>
