<template>
  <div
    class="scrollbar-custom flex h-full flex-col overflow-auto rounded-2xl bg-white p-5"
  >
    <div class="flex flex-shrink-0 flex-row">
      <h2 class="text-2xl font-medium">Playground</h2>
    </div>
    <div class="grid min-h-0 grid-cols-4 gap-1">
      <div class="flex flex-row items-center justify-end">
        <BaseButton :icon="'bell'" size="extra-small" />
        <BaseButton :icon="'bell'" size="small" />
        <BaseButton :icon="'bell'" />
        <BaseButton :icon="'bell'" size="large" />
        <BaseButton :icon="'bell'" size="extra-large" />
      </div>
      <div class="flex flex-row items-center">
        <BaseShowEditInput />
      </div>
      <div class="col-start-1 flex flex-row items-center justify-end">
        <BaseButton :icon="'bell'" size="extra-small" />
        <BaseButton :icon="'bell'" title="test" size="extra-small" />
        <BaseButton title="test" size="extra-small" />
      </div>
      <div class="flex flex-row items-center">
        <BaseButton :icon="'bell'" size="small" />
        <BaseButton :icon="'bell'" title="test" size="small" />
        <BaseButton title="test" size="small" />
      </div>
      <div class="flex flex-row items-center">
        <BaseButton :icon="'bell'" />
        <BaseButton :icon="'bell'" title="test" />
        <BaseButton title="test" />
      </div>
      <div class="flex flex-row items-center">
        <BaseButton :icon="'bell'" size="large" />
        <BaseButton :icon="'bell'" title="test" size="large" />
        <BaseButton title="test" size="large" />
      </div>
      <div class="flex flex-row items-center">
        <BaseButton :icon="'bell'" size="extra-large" />
        <BaseButton :icon="'bell'" title="test" size="extra-large" />
        <BaseButton title="test" size="extra-large" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DateRangeNavigator from '@c/SelectComponent/DateRangeNavigator.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'

export default {
  name: 'PlaygroundComponent',
  components: {
    BaseShowEditInput,
    BaseButton,
    DateRangeNavigator
  },
  data() {
    return {
      start: '2024-09-08',
      end: '2024-09-12'
    }
  },
  computed: {
    ...mapGetters({
      users: 'metadata/getUsersMetadata'
    })
  }
}
</script>
