<template>
  <WalkmanButton
    v-model="localModelValue"
    :options="options"
    :size="size"
    :label="label"
    :edition-mode="editionMode"
  />
  <ErrorContainer :error-key="name" :errors="errors" />
</template>

<script>
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import WalkmanButton from '@c/WalkmanButton.vue'

export default {
  name: 'BaseBooleanRadioGroup',
  components: { WalkmanButton, ErrorContainer },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
      required: false,
      default: () => {}
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    options() {
      return [
        {
          value: 1,
          label: this.$t('enums.yesNo.1')
        },
        {
          value: 0,
          label: this.$t('enums.yesNo.0')
        }
      ]
    },
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
