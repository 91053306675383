<template>
  <PageSection :title="$t('attributes.address')">
    <div v-if="user" class="flex flex-row">
      <div class="w-1/2">
        <BaseShowEditAddress
          v-model:country="user.country"
          v-model:zip="user.zip"
          v-model:city="user.city"
          v-model:street="user.street"
          disable-extra-street
          disable-wiki-city
        />
      </div>
    </div>
  </PageSection>
</template>

<script>
import { mapGetters } from 'vuex'

import PageSection from '@c/PageSection'
import SectionTitle from '@c/SectionTitle'
import SelectCountries from '@c/InitialStateFormComponent/Enums/SelectCountries.vue'
import BaseShowEditAddress from '@c/BaseShowEditAddress.vue'

export default {
  name: 'UserAddress',
  components: {
    BaseShowEditAddress,
    SelectCountries,
    SectionTitle,
    PageSection
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    metadata: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors'
    })
  }
}
</script>
