<template>
  <div
    class="z-10 hidden min-h-screen flex-col items-center justify-center dark:border-r dark:border-r-white md:flex"
  >
    <div class="flex flex-col items-center space-y-6">
      <div class="p-6">
        <img
          alt="funeral manager"
          :src="fm_logo_full"
          width="250"
          class="logo-funeral-manager"
        />
        <img
          alt="funero"
          :src="funero_logo_full"
          width="250"
          class="logo-funero"
        />
      </div>
    </div>
  </div>
</template>

<script>
import fm_logo_full from '../../assets/images/funeral-manager-full.svg'
import funero_logo_full from '../../assets/images/funero-logo-simple.svg'

export default {
  name: 'LoginLeftSide',
  data() {
    return {
      funero_logo_full: funero_logo_full,
      fm_logo_full: fm_logo_full
    }
  }
}
</script>
