<template>
  <main class="flex h-full flex-col overflow-hidden rounded-xl bg-gray-50">
    <header class="shrink-0 border-b bg-white px-4 py-2">
      <BaseSimpleTab :tabs="tabs" />
    </header>
    <main v-if="user" class="min-h-0 flex-1">
      <div
        class="flex h-full flex-col gap-4 overflow-y-auto p-4 @container/dossier-main"
        v-if="currentView === 'information'"
      >
        <UserPersonalInformation
          :user="user"
          :metadata="metadata"
          edition-mode
          disable-sticky-margin
        />
        <UserAddress :user="user" :metadata="metadata" editionMode />
        <UserConnection :user="user" :metadata="metadata" edition-mode />
        <UserEmail :user="user" :metadata="metadata" edition-mode />
      </div>
      <div
        v-else-if="currentView === 'notification'"
        class="flex min-h-0 flex-1 flex-col gap-4 overflow-y-auto p-4 @container/dossier-main"
      >
        <PageSection title="notify me" disable-sticky-margin>
          <template #default>
            <UserNotification :errors="errors" :metadata="[]" :user="user" />
          </template>
        </PageSection>
      </div>
    </main>

    <footer class="flex shrink-0 justify-end border-t bg-white p-3">
      <BaseButton
        color="primary"
        icon="paper-plane"
        title="modifier"
        @click="submitForm"
      />
    </footer>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BasePageFull from '@c/addf-package/components/BasePage/BasePageFull.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import PageSection from '@c/PageSection.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import UserPersonalInformation from '@/views/settings/user/form/tabs/userInformation/components/UserPersonalInformation.vue'
import UserAddress from '@/views/settings/user/form/tabs/userInformation/components/UserAddress.vue'
import UserConnection from '@/views/settings/user/form/tabs/userInformation/components/UserConnection.vue'
import UserNotification from '@/views/settings/user/form/tabs/userNotification/UserNotification.vue'
import BaseShowEditDatePicker from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import ImageFileInput from '@c/ImageFileInput.vue'
import AvatarComponent from '@c/AvatarComponent.vue'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'
import UserEmail from '@/views/settings/user/form/tabs/userInformation/components/UserEmail.vue'
import BaseSimpleTab from '@c/addf-package/components/BaseTabs/BaseSimpleTab.vue'

export default {
  name: 'ProfilePage',
  components: {
    BaseSimpleTab,
    UserEmail,
    SelectLanguages,
    AvatarComponent,
    ImageFileInput,
    BaseShowEditDatePicker,
    UserNotification,
    UserConnection,
    UserAddress,
    UserPersonalInformation,
    BaseShowEditInput,
    PageSection,
    BaseButton,
    BasePageFull
  },
  props: {
    currentView: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      user: null,
      metadata: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/getAuth',
      errors: 'auth/getErrors'
    }),
    tabs() {
      return [
        {
          label: this.$t('global.informations'),
          icon: 'info-square',
          routeName: `profile_information_route`,
          gate: true
        },
        {
          label: this.$t('global.notifications'),
          icon: 'bell',
          routeName: `profile_notification_route`,
          gate: true
        }
      ]
    }
  },
  mounted() {
    this.processGetAuthUserAction().then((response) => {
      this.user = response.user
    })
  },
  methods: {
    ...mapActions({
      processGetAuthUserAction: 'auth/getAuthUser',
      processUpdateAuthUserAction: 'auth/updateAuthUser',
      processSetAuthAction: 'auth/setAuthUser'
    }),
    submitForm() {
      const user = this.user
      if (!user.password?.length) {
        delete user.password
        delete user.password_confirmation
      }
      this.processUpdateAuthUserAction(user).then((response) => {
        this.user = response.user
        this.processSetAuthAction({
          user: response.user
        })
        this.$h.toastSuccess(
          this.$t('toasts.auth_updated_m', {
            var: this.$t('attributes.user')
          })
        )
      })
    }
  }
}
</script>
