<template>
  <nav class="space-y-1">
    <div>
      <!--#region settings -->
      <div class="my-5">
        <SettingsNavLinkComponent
          route-name="sa_management_home_route"
          label="home"
          icon="home"
        />

        <SettingsNavLinkComponent
          route-name="management_import_route"
          label="import"
          icon="cloud-arrow-down"
        />
        <SettingsNavLinkComponent
          route-name="management_db_route"
          label="DB"
          icon="database"
        />
      </div>
      <!--#endregion -->
    </div>
  </nav>
</template>

<script>
import SettingsNavLinkComponent from './SettingsNavLinkComponent'

export default {
  name: 'TheSaManagementNav',
  components: {
    SettingsNavLinkComponent
  },
  data() {
    return {}
  }
}
</script>
