import { createStore } from 'vuex'
import { RayVuexPlugin } from 'vue-ray'

import sideMenuModule from './modules/side-menu/index'
import darkModeModule from './modules/dark-mode/index'

import authModule from './modules/auth/index'

import accountingCodeModule from './modules/settings/invoicing/accountingCode'
import accountingDiaryModule from '@/store/modules/settings/invoicing/accountingDiary'
import AddressModule from './modules/address/index'
import AnalyticsModule from './modules/analytics/index'
import AgendaModule from './modules/agenda/index'
import automationModule from './modules/automation/index'
import bankAccountModule from './modules/settings/invoicing/bankAccount/index'
import chatModule from './modules/chat/index'
import commentsModule from './modules/comments/index'
import companyModule from './modules/settings/corporation/company/index'
import contactModule from './modules/contact/index'
import dashboardModule from './modules/dashboard/index'
import documentModule from './modules/document/index'
import docVariableModule from './modules/settings/management/docVariable'
import dossierModule from './modules/dossiers/index'
import duplicateModelModule from '@/store/modules/settings/corporation/duplicateModel'
import electronicSignatureModule from './modules/electronicSignature/index'
import emailModule from './modules/email/index'
import funeralHomeModule from './modules/settings/corporation/funeralHome/index'
import funeralManagerModule from './modules/funeralManager/index'
import globalSearchModule from './modules/global_search/index'
import invoicingCategoryModule from './modules/settings/invoicing/invoicingCategory/index'
import invoicingModule from './modules/invoicing/index'
import itemCategoryModule from './modules/settings/invoicing/itemCategory/index'
import itemListModule from './modules/settings/general/itemList/index'
import itemModule from './modules/settings/invoicing/item/index'
import ListItemModule from '@/store/modules/settings/management/ListItem'
import locationModule from './modules/settings/corporation/location/index'
import materialResourceModule from '@/store/modules/settings/corporation/materialResource/index'
import messageTextModule from './modules/settings/management/messageText/index'
import marketplaceModule from './modules/marketplace/index'
import roleModule from './modules/settings/management/role/index'
import surveyModule from './modules/settings/management/survey/index'
import screenSettingsModule from '@/store/modules/settings/management/screenSettings/index'
import metadataModule from './modules/metadata/index'
import noteModule from './modules/note/index'
import notificationModule from './modules/notification/index'
import notificationSettingsModule from './modules/settings/general/notification/index'
import packModule from './modules/settings/invoicing/pack/index'
import PlanningModule from './modules/planning/index'
import PartnerModule from './modules/partner/index'
import saManagementModule from './modules/SAManagement/index'
import screenModule from './modules/screen/index'
import settingsModule from './modules/settings/index'
import siteModule from './modules/settings/corporation/site/index'
import statisticsModule from './modules/settings/general/statistics/index'
import supplierModule from './modules/supplier/index'
import supplierOrderModule from './modules/supplierOrder/index'
import taskDossierModule from './modules/taskDossier/index'
import taskModule from './modules/settings/management/task/index'
import userModule from './modules/settings/user/index'
import versionNoteModule from '@/store/modules/settings/versionNote'
import wikiModule from './modules/wiki/index'
import modelNotificationModule from './modules/modelNotification/index'
import mediaModule from './modules/media/index'
import dossierNumberingModule from '@/store/modules/settings/general/dossierNumbering'
import gravePlotModule from '@/store/modules/gravePlot'
import personModule from '@/store/modules/person'
import stockModule from '@/store/modules/stock'

// const dataState = createPersistedState({
//   paths: ['auth', 'sessionExpiration', 'settings.systemSettings']
// })

const store = createStore({
  modules: {
    accountingCode: accountingCodeModule,
    accountingDiary: accountingDiaryModule,
    dossierNumbering: dossierNumberingModule,
    address: AddressModule,
    agenda: AgendaModule,
    analytics: AnalyticsModule,
    auth: authModule,
    automation: automationModule,
    role: roleModule,
    survey: surveyModule,
    screenSettings: screenSettingsModule,
    bankAccount: bankAccountModule,
    chat: chatModule,
    comments: commentsModule,
    company: companyModule,
    contact: contactModule,
    darkMode: darkModeModule,
    dashboard: dashboardModule,
    document: documentModule,
    docVariable: docVariableModule,
    dossier: dossierModule,
    duplicateModel: duplicateModelModule,
    electronicSignature: electronicSignatureModule,
    email: emailModule,
    funeralManager: funeralManagerModule,
    funeralHome: funeralHomeModule,
    gravePlot: gravePlotModule,
    globalSearch: globalSearchModule,
    // i18n: i18nModule,
    invoicing: invoicingModule,
    invoicingCategory: invoicingCategoryModule,
    item: itemModule,
    itemCategory: itemCategoryModule,
    itemList: itemListModule,
    listItem: ListItemModule,
    location: locationModule,
    materialResource: materialResourceModule,
    marketplace: marketplaceModule,
    media: mediaModule,
    messageText: messageTextModule,
    metadata: metadataModule,
    modelNotification: modelNotificationModule,
    note: noteModule,
    notification: notificationModule,
    notificationSettings: notificationSettingsModule,
    pack: packModule,
    planning: PlanningModule,
    partner: PartnerModule,
    person: personModule,
    saManagement: saManagementModule,
    screen: screenModule,
    settings: settingsModule,
    sideMenu: sideMenuModule,
    site: siteModule,
    statistics: statisticsModule,
    stock: stockModule,
    supplier: supplierModule,
    supplierOrder: supplierOrderModule,
    task: taskModule,
    taskDossier: taskDossierModule,
    user: userModule,
    versionNote: versionNoteModule,
    wiki: wikiModule
  },
  plugins: [
    // dataState,
    RayVuexPlugin
  ]
})

export function useStore() {
  return store
}

export default store
