import apiClient from '@u/apiClient'

export default {
  fetchScreenConfigsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('screen-config')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchNewScreenConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=ScreenConfig')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchScreenConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`screen-config/${payload.screen_config_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  storeScreenConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`screen-config`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updateScreenConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`screen-config/${payload.id}`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getLastDossier({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`screen-config/get-last-dossier`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
