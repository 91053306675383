<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row items-center gap-3 text-lg">
      <FontAwesomeIcon :icon="icon" />
      <h3 class="text-base font-semibold">{{ label }}</h3>
    </div>
    <div>
      <WalkmanButton
        v-model="localModelValue"
        :options="[
          { value: 1, label: $t('enums.yesNo.1') },
          { value: 0, label: $t('enums.yesNo.0') }
        ]"
        size="extra-small"
        :edition-mode="editionMode"
      />
    </div>
    <div v-if="localModelValue" class="grid empty:hidden md:grid-cols-2">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import WalkmanButton from '@c/WalkmanButton.vue'

export default {
  name: 'NotifyByButton',
  components: { WalkmanButton, FontAwesomeIcon },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    icon: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
