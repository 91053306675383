<template>
  <button
    type="button"
    @click="open = true"
    class="flex flex-row items-center justify-center gap-2 rounded-md border bg-white p-2 font-bold dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
  >
    <FontAwesomeIcon :icon="['fal', 'headset']" size="lg" />
    <span>Support</span>
  </button>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" @close="open = false" class="relative z-100">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-3 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-4 pt-3 text-left align-middle shadow-xl transition-all dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-bold leading-6 text-gray-900 dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
              >
                Support
              </DialogTitle>
              <div class="mt-3">
                <div
                  v-if="['fr_FR'].includes($i18n.locale)"
                  class="flex flex-col gap-3 text-sm text-gray-600 dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
                >
                  <div class="flex flex-row items-center gap-2">
                    <div class="flex w-12 justify-end">
                      <FontAwesomeIcon :icon="['fal', 'phone']" />
                    </div>
                    <div class="flex-1">03 53 32 00 17</div>
                  </div>
                  <div class="flex flex-row items-center gap-2">
                    <div class="flex w-12 justify-end">
                      <FontAwesomeIcon :icon="['fal', 'envelope']" />
                    </div>
                    <div class="flex-1">
                      <a
                        href="mailto:support@funeralmanager.fr"
                        target="_blank"
                      >
                        support@funeralmanager.fr
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="['fr_BE', 'nl_BE'].includes($i18n.locale)"
                  class="flex flex-col gap-3 text-sm text-gray-600 dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
                >
                  <div class="flex flex-row items-center gap-2">
                    <div class="flex w-12 justify-end">
                      <FontAwesomeIcon :icon="['fal', 'phone']" />
                    </div>
                    <div class="flex-1">02 899 86 66</div>
                  </div>
                  <div class="flex flex-row items-center gap-2">
                    <div class="flex w-12 justify-end">
                      <FontAwesomeIcon :icon="['fal', 'envelope']" />
                    </div>
                    <div class="flex-1">
                      <a href="mailto:support@addf.be" target="_blank">
                        support@addf.be
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-end">
                <BaseButton :title="$t('global.close')" @click="open = false" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from '@headlessui/vue'
import CountryFlags from '@/assets/enums/config/CountryFlags'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'SupportModal',
  computed: {
    CountryFlags() {
      return CountryFlags
    }
  },
  data() {
    return {
      open: false
    }
  },
  components: {
    BaseButton,
    FontAwesomeIcon,
    Dialog,
    DialogTitle,
    DialogPanel,
    TransitionRoot,
    TransitionChild
  }
}
</script>
