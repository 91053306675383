export default {
  getIsModalOpen(state) {
    return state.isModalOpen
  },
  getEmail(state) {
    return state.email
  },
  getAttachable(state) {
    return state.attachable
  },
  getDefaultAttachments(state) {
    return state.defaultAttachments
  },
  getEmailStateHasChanged(state) {
    return state.hasChanged
  }
}
