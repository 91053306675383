import apiClient from '@u/apiClient'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

const base_errors = {}

export default {
  async fetchAttachmentCategoryIndex({commit}) {
    commit('auth/setErrors', base_errors, {root: true})
    return new Promise((resolve, reject) => {
      apiClient
        .get(`attachment-category`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  postAttachmentCategory({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    return new Promise((resolve, reject) => {
      apiClient
        .post(`attachment-category`, payload.attachment_category)
        .then(async (response) => {
          resolve(response.data)
          if (response.status === 200) {
            commit(
              'setAttachmentCategoryIndex',
              response.data.attachment_categories
            )
            processSuccessCallback(payload)
          }
        })
        .catch(async (reason) => {
          await console.error('postAttachmentCategory', reason)
        })
    })
  },
  updateAttachmentCategory({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `attachment-category/${payload.attachment_category_id}`,
          payload.attachment_category
        )
        .then(async (response) => {
          resolve(response.data)
          if (response.status === 200) {
            commit(
              'setAttachmentCategoryIndex',
              response.data.attachment_categories
            )
            processSuccessCallback(payload)
          }
        })
        .catch(async (reason) => {
          await console.error('updateAttachmentCategory', reason)
        })
    })
  },
  deleteAttachmentCategory({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`attachment-category/${payload.attachment_category_id}`)
        .then(async (response) => {
          resolve(response.data)
          if (response.status === 200) {
            commit(
              'setAttachmentCategoryIndex',
              response.data.attachment_categories
            )
            processSuccessCallback(payload)
          }
        })
        .catch(async (reason) => {
          await console.error('deleteAttachmentCategory', reason)
        })
    })
  },
  undoDeleteAttachmentCategory({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    return new Promise((resolve, reject) => {
     apiClient
      .get(`attachment-category/undo-delete/${payload.attachment_category_id}`)
      .then(async (response) => {
        resolve(response.data)
        if (response.status === 200) {
          commit(
            'setAttachmentCategoryIndex',
            response.data.attachment_categories
          )
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('undoDeleteAttachmentCategory', reason)
      })
    })
  }
}
