import apiClient from '@/utils/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  //region Fetch
  async fetchAllSupplierOrders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`fetch-supplier-orders`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchSupplierOrderByID: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      return apiClient
        .get(`supplier-order/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewSupplierOrder({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`model/new?model=supplierOrder`)
        .then((response) => {
          commit('setSupplierOrder', response.data.supplierOrder)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchItems({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`supplier-order/${payload.supplier_order_id}/items`)
        .then((response) => {
          commit('setSupplierOrder', response.data.supplierOrder)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  addItemStocksToSupplierOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(
          `supplier-order/${payload.supplier_order_id}/item-stocks`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  addItemsToSupplierOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(
          `supplier-order/${payload.supplier_order_id}/items`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateFreeItemStock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `supplier-order-item/${payload.supplier_order_item_id}/item-stocks`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  removeItemStocksFromSupplierOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`supplier-order/${payload.supplier_order_id}/item-stocks`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  exportSupplierOrderPDF: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/supplier-order/render/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async fetchDraftSupplierOrderBySupplierId({ commit }, payload) {
    await apiClient
      .get(`invoicing/draft-supplier-order/${payload.supplier_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setInvoiceIndex', response.data.invoices)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchDraftSupplierOrderBySupplierId', reason)
      })
  },

  async fetchCateringSupplierOrderByDossierId({ commit }, payload) {
    await apiClient
      .get(`catering-order-by-dossier/${payload.dossier_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setInvoiceIndex', response.data.invoices)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchCateringSupplierOrderByDossierId', reason)
      })
  },

  //endregion

  //region store
  storeSupplierOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`supplier-order`, payload.supplier_order)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  //endregion

  //region update
  updateSupplierOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `supplier-order/${payload.supplier_order_id}`,
          payload.supplier_order
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async receiveSupplierOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `supplier-order/receive/${payload.supplier_order_id}`,
          payload.supplier_order
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  //endregion

  // region delete
  deleteSupplierOrderById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`supplier-order/delete/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undoDelete
  undoDeleteSupplierOrderById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`supplier-order/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  sendSupplierOrderToSupplier({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`supplier-order/${payload.id}/send-to-supplier`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
