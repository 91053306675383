import TheMarketPlacePage from '@/views/marketplace/TheMarketPlacePage.vue'
import TheMarketPlaceListPage from '@/views/marketplace/list/TheMarketPlaceListPage.vue'
import TheMarketPlaceModulePage from '@/views/marketplace/list/TheMarketPlaceModulePage.vue'

export const marketplacePages = [
  {
    path: '/marketplace',
    name: 'marketplace_page_route',
    component: TheMarketPlacePage,
    children: [
      {
        path: '',
        name: 'marketplace_list',
        component: TheMarketPlaceListPage
      },
      {
        path: ':optionId',
        name: 'marketplace_option_info',
        component: TheMarketPlaceModulePage,
        props: true
      }
    ]
  }
]
