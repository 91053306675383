<template>
  <div
    class="relative aspect-square w-10 rounded-full border border-slate-300 text-slate-300 focus-within:border-slate-400 focus-within:bg-secondary focus-within:text-contrast-secondary"
  >
    <button
      class="absolute inset-0 flex items-center justify-center"
      @click="focusInput"
    >
      <FontAwesomeIcon :icon="['fal', 'scanner-gun']" size="lg" />
    </button>
    <input
      ref="inputQrCode"
      v-model="val"
      type="text"
      class="absolute -left-96 -top-96 -z-100 h-1 w-1"
      tabindex="-1"
      @keyup.enter="handleQRCode"
    />
  </div>
  <ModalResultScan v-model:is-open="modalResultScanOpened">
    <template #body>
      <InvoiceSummary v-if="type === 'INVOI'" :invoice-id="id" @close="close" />
      <ItemStockSummary
        v-else-if="type === 'STOCK'"
        :item-stock-id="id"
        @close="close"
      />
    </template>
  </ModalResultScan>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ModalResultScan from '@c/Scan/component/ModalResultScan.vue'
import InvoiceSummary from '@c/Scan/component/InvoiceSummary.vue'
import ItemStockSummary from '@c/Scan/component/ItemStockSummary.vue'

let valid_scan_type = ['STOCK', 'INVOI']

export default {
  name: 'ScanComponent',
  components: {
    ItemStockSummary,
    InvoiceSummary,
    ModalResultScan,
    FontAwesomeIcon
  },
  data() {
    return {
      val: '', // STIT|01hz4ghzhd2s6ghtp4rhygkdg4
      scanEnabled: false,
      modalResultScanOpened: false,
      type: null,
      id: null
    }
  },
  watch: {
    modalResultScanOpened(newValue, oldValue) {
      if (!newValue) {
        setTimeout(() => {
          this.val = ''
          this.type = null
          this.id = null
          this.focusInput()
        }, 250)
      }
    }
  },
  methods: {
    handleQRCode(e) {
      let qr_code = e.target.value
      console.log(qr_code)
      this.type = qr_code.slice(0, 5)
      this.id = qr_code.slice(5)
      console.log(this.type, this.id)
      if (
        valid_scan_type.includes(this.type) &&
        /^[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}$/.test(this.id.toUpperCase())
      ) {
        this.modalResultScanOpened = true
      } else {
        this.$h.toastWarning('le code scanné n est pas valide')
        this.type = null
        this.id = null
        this.modalResultScanOpened = false
        this.val = ''
        this.$refs.inputQrCode.focus()
      }
    },
    focusInput() {
      this.$refs.inputQrCode.focus()
    },
    close() {
      this.modalResultScanOpened = false
    }
  }
}
</script>
