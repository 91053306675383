<template>
  <div class="relative w-full rounded-md shadow-sm" :class="cGroupClass">
    <input
      v-model="modelValue"
      type="text"
      name="account-number"
      class="block w-full rounded-md border-slate-300 py-1.5 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      :placeholder="$t('global.placeholder.search')"
    />
    <div
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
    >
      <FontAwesomeIcon :icon="['fal', 'search']" />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseSimpleSearchInput',
  components: { FontAwesomeIcon },
  props: {
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    }
  },
  emits: ['update:modelValue'],
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? 'mt-1' : this.groupClass
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  }
}
</script>
