import apiClient from '@u/apiClient'
let base_errors = { deceased: {}, events: [] }
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  //region fetch
  async fetchSupplierList({ commit }) {
    await apiClient
      .get(`supplier`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setSupplierList', response.data.suppliers)
          commit('setMetadata', response.data.metadata)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchSupplierList', reason)
      })
  },
  fetchSupplierById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`supplier/${payload.supplier_id}`)
        .then((response) => {
          if (response.status === 200) {
            commit('setSupplier', response.data.supplier)
          }
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchNewSupplier({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`model/new?model=supplier`)
        .then((response) => {
          if (response.status === 200) {
            commit('setSupplier', response.data.supplier)
          }
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchSupplierItemsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`supplier-items/${payload.supplier_id}`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async fetchSupplierActivitiesBySupplierID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`supplier/fetch-activities/${payload.supplier_id}`)
      .then(async (response) => {
        await commit('setMetadataActivities', response.data.metadata)
        await commit('setSupplierActivity', response.data.activities)
      })
      .catch(async (reason) => {
        await console.error('fetchSupplierActivitiesByCompanyID', reason)
      })
  },
  //endregion

  //region store
  storeSupplier({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`supplier`, payload.supplier)
        .then((response) => {
          if (response.status === 201) {
            commit('setSupplier', response.data.supplier)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  //endregion

  //region update
  updateSupplier({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`supplier/${payload.supplier_id}`, payload.supplier)
        .then((response) => {
          commit('setSupplier', response.data.supplier)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  //endregion

  // region delete
  deleteSupplierById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`supplier/supplier-delete/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undoDelete
  undoDeleteSupplierById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`supplier/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
