import apiClient from '@u/apiClient'

export default {
  // region Fetch
  fetchAccountingCodeList: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get('accounting-code')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchAccountingCode: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`accounting-code/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchNewAccountingCode({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=accountingCode')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // endregion

  // region store
  storeAccountingCode: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post('accounting-code', payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  updateAccountingCode: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`accounting-code/${payload.id}`, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion
  // region delete
  deleteAccountingCodeById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`accounting-code/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undoDelete
  undoDeleteAccountingCodeById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`accounting-code/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
