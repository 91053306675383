import TheStockPage from '@/views/stock/TheStockPage.vue'
import TheSummaryPage from '@/views/stock/summary/TheSummaryPage.vue'
import TheStockItemPage from '@/views/stock/item/TheStockItemPage.vue'

export const stockSubPages = [
  {
    path: '/stock',
    name: 'stock_page_route',
    redirect: {
      name: 'stock_summary_route'
    },
    component: TheStockPage,
    children: [
      {
        path: '',
        name: 'stock_summary_route',
        component: TheSummaryPage
      },
      {
        path: 'location/:locationId',
        name: 'stock_location_summary_route',
        component: TheSummaryPage,
        props: true
      },
      {
        path: 'item/:itemId',
        name: 'stock_item_route',
        component: TheStockItemPage,
        props: true
      }
    ]
  }
]
