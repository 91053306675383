<template>
  <div
    class="flex select-none justify-between gap-2 truncate rounded border p-1.5 hover:bg-slate-50"
    :class="[dragAndDropEnabled ? 'cursor-grab' : '']"
    :draggable="dragAndDropEnabled"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
  >
    <QrcodeVue :value="itemStock.qr_code_value" level="L" size="30" />
    <span>
      <span
        :data-tint="status.color"
        class="rounded bg-primary px-1.5 text-xs text-contrast-primary"
      >
        {{ statusText }}
      </span>
    </span>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ItemStockComponent',
  components: { QrcodeVue },
  props: {
    itemStock: {
      type: Object,
      required: true
    },
    dragAndDropEnabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'metadata/getMetadataStockStatuses'
    }),
    status() {
      return this.statuses.find((status) => status.id === this.itemStock.status)
    },
    statusText() {
      return this.status?.name ?? '##'
    }
  },
  methods: {
    ...mapActions({
      processSetDataTransferObject: 'stock/setDataTransferObject'
    }),
    handleDragStart() {
      this.processSetDataTransferObject({
        type: 'item_stock',
        model: this.itemStock
      })
    },
    handleDragEnd() {
      this.processSetDataTransferObject(null)
    }
  }
}
</script>
