<template>
  <BaseSlideOverModal
    v-model:is-modal-open="localOpened"
    width="xl"
    :title="dossierNumbering ? dossierNumbering.name : $t('button.new')"
  >
    <template #cta>
      <div class="mt-1 flex h-full gap-x-1">
        <BaseButton
          :title="$t('button.save')"
          icon="paper-plane"
          size="small"
          @click="saveForm"
        />
      </div>
    </template>
    <template #body>
      <div
        v-if="!dossierNumbering"
        class="flex flex-row items-center justify-center"
      >
        <LoadingComponent />
      </div>
      <div v-else class="flex h-full w-full flex-col gap-3 overflow-y-auto">
        <div class="col-span-2">
          <BaseShowEditInput v-model="dossierNumbering.name" edition-mode />
        </div>
        <div class="col-span-2">
          <ReferenceConfigurator
            v-model:reference-array="dossierNumbering.pattern"
            edition-mode
          />
        </div>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapActions } from 'vuex'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import LoadingComponent from '@c/TheLoadingComponent.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import ReferenceConfigurator from '@c/GlobalFormComponents/ReferenceConfigurator.vue'

export default {
  name: 'DossierNumberingModal',
  components: {
    ReferenceConfigurator,
    BaseButton,
    LoadingComponent,
    BaseShowEditInput,
    SelectComponent,
    BaseSlideOverModal
  },
  emits: ['update:opened', 'need-refresh'],
  props: {
    opened: {
      type: Boolean,
      required: true
    },
    dossierNumberingId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      dossierNumbering: null
    }
  },
  computed: {
    localOpened: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      }
    }
  },
  watch: {
    opened(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        if (this.dossierNumberingId) {
          this.processFetchDossierNumberingAction({
            dossier_numbering_id: this.dossierNumberingId
          }).then(
            (response) => (this.dossierNumbering = response.dossier_numbering)
          )
        } else {
          this.dossierNumbering = {
            name: null,
            pattern: []
          }
          // this.processNewFetchDossierNumberingAction().then(
          //   (response) => (this.dossierNumbering = response.dossier_numbering)
          // )
        }
      } else if (newValue !== oldValue && !newValue) {
        this.dossierNumbering = null
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchDossierNumberingAction:
        'dossierNumbering/fetchDossierNumbering',
      processNewFetchDossierNumberingAction:
        'dossierNumbering/fetchDossierNumbering',
      processStoreDossierNumberingAction:
        'dossierNumbering/storeDossierNumbering',
      processUpdateDossierNumberingAction:
        'dossierNumbering/updateDossierNumbering'
    }),
    saveForm() {
      if (this.dossierNumbering.id) {
        this.processUpdateDossierNumberingAction({
          dossier_numbering_id: this.dossierNumbering.id,
          params: this.dossierNumbering
        }).then((_response) => {
          this.dossierNumbering = null
          this.$emit('need-refresh')
          this.localOpened = false
        })
      } else {
        this.processStoreDossierNumberingAction({
          params: this.dossierNumbering
        }).then((_response) => {
          this.dossierNumbering = null
          this.$emit('need-refresh')
          this.localOpened = false
        })
      }
    }
  }
}
</script>
