<template>
  <BaseDossierPageLayout
    :title="$t('navigation.sa_management')"
    :with-submit-button="false"
    :with-edition-mode-button="false"
    hide-footer-bar
    not-scrollable
  >
    <template #default>
      <div class="flex h-full flex-col gap-4 p-4">
        <PageSection
          v-if="automations?.length > 0"
          :title="$t('global.automations')"
        >
          <template v-for="(trigger, index) in metadata?.trigger" :key="index">
            <div
              v-if="automationsGroupedByTrigger[trigger.id]?.length > 0"
              class="flex flex-col gap-6"
            >
              <h3 class="font-bold">
                {{ $t(`enums.automation.trigger.${trigger.id}`) }}
              </h3>
              <div class="flex flex-col gap-3">
                <AutomationItem
                  v-for="automation in automationsGroupedByTrigger[trigger.id]"
                  :key="automation.id"
                  :automation="automation"
                  :metadata="metadata"
                />
              </div>
            </div>
          </template>
        </PageSection>
        <PageSection :title="$t('button.delete')">
          <template #default>
            <div>
              <BaseButton
                :title="$t('button.delete') + ' le dossier'"
                class="mt-1"
                :icon="'trash'"
                :icon-type="'fal'"
                :class-name="'bg-red-600'"
                @click="handleRemoveDossier"
              />
            </div>
            <ConfirmModal ref="confirmDeleteDossier" />
          </template>
        </PageSection>
      </div>
    </template>
  </BaseDossierPageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmModal from '@c/addf-package/components/modal/ConfirmModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseDossierPageLayout from '@c/addf-package/components/layout/BaseDossierPageLayout.vue'
import PageSection from '@c/PageSection.vue'
import AutomationItem from '@/views/dossier/form/subpages/managenment/component/AutomationItem.vue'

export default {
  name: 'TheDossierSuperAdminManagementPage',
  components: {
    AutomationItem,
    PageSection,
    BaseDossierPageLayout,
    BaseButton,
    ConfirmModal
  },
  data() {
    return {
      automations: [],
      metadata: []
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'dossier/getDossierMetadataActivities',
      dossier: 'dossier/getDossier'
    }),
    automationsGroupedByTrigger() {
      return Object.groupBy(this.automations, ({ trigger }) => trigger)
    }
  },
  mounted() {
    this.processFetchSettingsManagementDossierAction({
      dossier_id: this.$route.params.id
    }).then((response) => {
      this.automations = response.automations
      this.metadata = response.metadata
    })
  },
  methods: {
    ...mapActions({
      processDeleteDossierAction: 'dossier/deleteDossier',
      processFetchSettingsManagementDossierAction:
        'dossier/fetchSettingsManagementDossier'
    }),
    async handleRemoveDossier(e) {
      const confirm = await this.$refs.confirmDeleteDossier.show({
        title: this.dossier.concernedPerson?.full_name,
        message: this.$t('validation.confirm_delete_dossier'),
        okButton: this.$t('button.confirm')
      })
      if (confirm) {
        this.processDeleteDossierAction(this.$route.params.id).then(
          async (res) => {
            if (res.success) {
              await this.$router.push({
                name: 'dashboard_dossier_main_page_route'
              })
            }
          }
        )
      }
    }
  }
}
</script>
