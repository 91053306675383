<template>
  <button
    class="flex flex-row items-center justify-center gap-2 rounded-md border bg-white p-2 font-bold dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
    @click="isNotificationModalOpened = true"
  >
    <FontAwesomeLayers class="fa-lg">
      <FontAwesomeIcon :icon="['fal', 'bell']" />
      <FontAwesomeLayersText
        v-if="unread_notifications > 0"
        class="-right-[5px] -top-[5px] bg-red-400 text-4xl font-light"
        counter
        position="top-right"
        :value="unread_notifications"
      />
    </FontAwesomeLayers>
  </button>
  <BaseSlideOverModal
    v-model:is-modal-open="isNotificationModalOpened"
    width="md"
    :title="headerTitle"
    hide-close-button
  >
    <template #body>
      <div class="flex h-full w-full flex-col gap-2 overflow-y-auto">
        <div
          class="sticky left-0 top-0 flex w-full flex-col gap-y-2 bg-white pb-3"
        >
          <div class="flex w-full flex-row items-center justify-start gap-x-2">
            <button
              class="text-md rounded border px-2 py-1 first-letter:uppercase hover:border-blue-400 hover:bg-blue-400 hover:text-white"
              :class="{
                'pointer-events-none border-blue-400 bg-blue-400 text-white':
                  isNotificationTypeAll,
                'border-gray-300 bg-white': !isNotificationTypeAll
              }"
              @click.prevent="setNotificationType('all')"
            >
              {{ $trans('attributes.all') }}
            </button>

            <button
              class="text-md rounded border px-2 py-1 first-letter:uppercase hover:border-blue-400 hover:bg-blue-400 hover:text-white"
              :class="{
                'pointer-events-none border-blue-400 bg-blue-400 text-white':
                  isNotificationTypeUnRead,
                'border-gray-300 bg-white': !isNotificationTypeUnRead
              }"
              @click.prevent="setNotificationType('un_read')"
            >
              {{ $trans('global.un_read') }}
            </button>

            <button
              class="text-md rounded border px-2 py-1 first-letter:uppercase hover:border-blue-400 hover:bg-blue-400 hover:text-white"
              :class="{
                'pointer-events-none border-blue-400 bg-blue-400 text-white':
                  isNotificationTypeRead,
                'border-gray-300 bg-white': !isNotificationTypeRead
              }"
              @click.prevent="setNotificationType('read')"
            >
              {{ $trans('global.read') }}
            </button>

            <button
              :title="$trans('button.refresh')"
              class="text-md rounded border px-2 py-1 first-letter:uppercase hover:border-blue-400 hover:bg-blue-400 hover:text-white"
              @click.prevent="fetchNotification()"
            >
              <FontAwesomeIcon
                fixed-width
                :icon="['fal', 'arrows-rotate']"
                size="lg"
              />
            </button>
          </div>

          <div
            v-if="unread_notifications > 0"
            class="flex w-full items-center justify-end"
          >
            <button
              class="text-md rounded border px-2 py-1 first-letter:uppercase hover:border-blue-400 hover:bg-blue-400 hover:text-white"
              @click.prevent="markAsRead('all')"
            >
              {{ $trans('global.mark_all_as_read_copy') }}
            </button>
          </div>
        </div>

        <div
          v-for="notification in localNotifications"
          :key="notification.id"
          class="flex flex-col rounded border p-2"
          :class="{
            'cursor-pointer': !notification.read_at,
            'cursor-context-menu': !!notification.read_at
          }"
          @click.prevent="markAsRead(notification.id)"
        >
          <div class="flex flex-row gap-1">
            <div
              class="shrink-0 pt-0.5"
              :class="[notification.icon?.color ? notification.icon.color : '']"
            >
              <FontAwesomeIcon
                v-if="notification.icon?.fa"
                fixed-width
                :icon="notification.icon.fa"
                size="lg"
              />
              <FontAwesomeIcon
                v-else
                fixed-width
                :icon="['fal', 'info-circle']"
                class="text-primary"
                size="lg"
              />
            </div>
            <div class="min-w-0 flex-1">
              <p
                v-if="notification.data.type === 'free_text'"
                class="whitespace-pre-line"
              >
                {{ notification.data.text ?? '' }}
              </p>
              <p
                v-else
                v-html="
                  getNotificationText(
                    notification.data.translation.key,
                    notification.data.translation.params
                  )
                "
              ></p>
              <div class="flex justify-end text-xs text-slate-500">
                {{ $h.formatDate(notification.created_at, 'LLLL') }}
              </div>
            </div>
            <div v-if="!notification.read_at" class="shrink-0 text-primary">
              <FontAwesomeIcon
                fixed-width
                :icon="['fas', 'circle']"
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal.vue'

export default {
  name: 'NotificationComponent',
  components: {
    BaseSlideOverModal,
    FontAwesomeLayersText,
    FontAwesomeLayers,
    FontAwesomeIcon
  },
  data() {
    return {
      isNotificationModalOpened: false,
      unread_notifications: -1,
      notifications: [],
      notificationType: 'all'
    }
  },
  computed: {
    // ...mapGetters({
    //   proposeItem: 'dossier/getProposeItem'
    // }),
    localNotifications() {
      return this.notifications.filter((n) => {
        if (this.notificationType === 'un_read') {
          return n.read_at === null || n.read_at === undefined
        } else if (this.notificationType === 'read') {
          return !!n.read_at
        }

        return true
      })
    },
    isNotificationTypeAll() {
      return this.notificationType === 'all'
    },
    isNotificationTypeUnRead() {
      return this.notificationType === 'un_read'
    },
    isNotificationTypeRead() {
      return this.notificationType === 'read'
    },
    headerTitle() {
      const total = (this.notifications || []).length

      if (total > 0) {
        if (this.unread_notifications > 0) {
          return `${this.$t('global.notifications')} (${
            this.unread_notifications
          }/${total})`
        }

        return `${this.$t('global.notifications')} (${total})`
      }

      return this.$t('global.notifications')
    }
  },
  mounted() {
    this.fetchNotification()
  },
  methods: {
    ...mapActions({
      processFetchNotificationsAction: 'notification/fetchNotifications',
      notificationMarkAsRead: 'notification/markAsReadNotification'
    }),
    setNotificationType(type) {
      this.notificationType = type
    },
    fetchNotification() {
      this.processFetchNotificationsAction().then((response) => {
        this.makeNotificationsData(response.notifications)
      })
    },
    markAsRead(notification) {
      this.notificationMarkAsRead(notification).then((res) => {
        this.makeNotificationsData(res.notifications)
      })
    },
    makeNotificationsData(notifications) {
      this.notifications = notifications

      this.unread_notifications = this.notifications.filter(
        (n) => !n.read_at
      ).length
    },
    handleNotificationToast(data) {
      if (data.type === 'free_text') {
        this.$h.toastInfo(data.text)
      } else {
        this.$h.toast(
          data.type ?? 'info',
          this.getNotificationText(
            data.translation.key,
            data.translation.params
          ),
          null,
          data.uuid
        )
      }
    },
    getNotificationText(key, params) {
      let final_params = {}
      for (let property in params) {
        if (params[property].type && params[property].type === 'translation') {
          final_params[property] = this.$t(params[property].value)
        } else {
          final_params[property] = params[property]
        }
      }
      return this.$t(`notifications.${key}`, final_params)
    }
  }
}
</script>
