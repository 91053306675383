<template>
  <TransitionRoot appear :show="localOpened" as="template">
    <Dialog as="div" class="relative z-100" @close="closeModal">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="flex w-full max-w-lg transform flex-col gap-3 overflow-hidden rounded-2xl bg-white p-5 pt-3 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 capitalize-first"
              >
                {{ $t('button.print') }}
              </DialogTitle>

              <div>
                <div class="grid grid-cols-1 gap-3 md:grid-cols-2">
                  <BaseCheckBox
                    v-model="options.qr_code"
                    class="col-span-2"
                    label="QR Code"
                    name="qr_code"
                    edition-mode
                  />
                  <BaseCheckBox
                    v-model="options.price"
                    class="col-span-2"
                    :label="$t('attributes.price')"
                    name="price"
                    edition-mode
                  />
                </div>
              </div>

              <div class="flex items-center justify-center p-2">
                <div
                  class="flex h-16 w-48 flex-row gap-2 border border-slate-700 p-2"
                >
                  <div v-if="options.qr_code" class="aspect-square shrink-0">
                    <QrcodeVue value="test" level="L" class="!h-12 !w-12" />
                  </div>
                  <div class="flex flex-1 flex-col">
                    <div class="text-xs font-bold leading-none">TITLE</div>
                    <div v-if="options.price" class="text-xs leading-none">
                      0.00 €
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-row justify-end gap-3">
                <BaseButton
                  icon="times"
                  inverse-icon-order
                  :title="$t('button.cancel')"
                  color="danger"
                  size="small"
                  @click="closeModal"
                />
                <BaseButton
                  icon="print"
                  inverse-icon-order
                  :title="$t('button.print')"
                  size="small"
                  @click="print"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { mapActions } from 'vuex'
import printJS from 'print-js'
import QrcodeVue from 'qrcode.vue'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'

export default {
  name: 'PrintLabelModal',
  components: {
    BaseCheckBox,
    QrcodeVue,
    Dialog,
    DialogTitle,
    DialogPanel,
    TransitionRoot,
    TransitionChild,
    BaseButton
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
      required: true
    },
    itemId: {
      type: String,
      required: true
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  emits: ['update:opened'],
  data() {
    return {
      options: {
        qr_code: true,
        price: true
      }
    }
  },
  computed: {
    localOpened: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      }
    }
  },
  methods: {
    ...mapActions({
      processPrintLabelItemStockAction: 'stock/printLabelItemStock'
    }),
    print() {
      this.processPrintLabelItemStockAction({
        item_id: this.itemId,
        params: {
          ...this.extraParams,
          ...this.options
        }
      }).then((response) => {
        printJS({
          printable: response.base64,
          type: 'pdf',
          base64: true
        })
      })
    },
    closeModal() {
      this.$emit('update:opened', false)
    }
  }
}
</script>
