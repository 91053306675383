<template>
  <div v-if="editionMode">
    <div :class="cGroupClass">
      <BaseEditLabel :label="label" :required="required" />
      <multiselect
        :id="name"
        v-model="internalValue"
        multiple
        :label="attributeLabel"
        :track-by="trackBy"
        placeholder=""
        open-direction="bottom"
        :options="options"
        :value="defaultAjax"
        :multiple="multiple"
        :searchable="true"
        :loading="loading"
        :internal-search="true"
        :clear-on-select="true"
        :close-on-select="true"
        :options-limit="300"
        :limit="10"
        :limit-text="2"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        :taggable="taggable"
        :tag-placeholder="$t('button.add_tag_placeholder')"
        :select-label="$t('button.select')"
        :deselect-label="$t('button.unselect')"
        :selected-label="$t('button.selected')"
        :no-options="$t('global.is_empty_list')"
        no-results-text="pas de résultat trouvé"
        @tag="addTag"
        @search-change="fetchOption"
      >
        <template #option="{ option }">
          <div class="option__desc">
            <div class="flex gap-x-3 align-baseline">
              <div v-if="displayedOptionIcon && option.icon">
                <FontAwesomeIcon :icon="['fal', option.icon]" size="xl" />
              </div>
              <div>
                <div class="option__title font-semibold">
                  {{
                    option[attributeLabel]
                      ? option[attributeLabel]
                      : option.label
                  }}
                </div>

                <div
                  v-if="optionSubTitleLabel"
                  class="option__desc mt-1 text-sm font-light"
                >
                  {{
                    option['isDisabled']
                      ? missingInfoLabel ?? $t('global.missing_informations')
                      : option[optionSubTitleLabel]
                  }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #noOptions
          ><span>{{ $t('global.is_empty_list') }}</span></template
        >
        <template #noResults>{{ $t('global.no_result_found') }}</template>
      </multiselect>
    </div>
    <template v-if="errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </div>

  <div v-else>
    <BaseShowLabel
      :label="label"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </div>
</template>
<script>
import apiClient from '@u/apiClient'
import multiselect from 'vue-multiselect'
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseShowEditSelectMultipleTaggableAjax',
  components: { FontAwesomeIcon, BaseShowLabel, BaseEditLabel, multiselect },
  props: {
    editionMode: { type: Boolean, required: true },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    modelValue: { type: Object, required: false, default: null },
    taggable: { type: Boolean, required: false, default: false },
    defaultAjax: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    url: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    multiple: { type: Boolean, required: false, default: false },
    label: { type: String, required: false },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    fullModelResponse: { type: Boolean, required: false, default: false },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    displayedOptionIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    optionSubTitleLabel: {
      type: String,
      required: false,
      default: null
    },
    additionalTags: {
      type: Array,
      required: true
    },
    missingInfoLabel: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: [
    'update:modelValue',
    'update:stringValue',
    'change',
    'selected:value',
    'newTagSelected'
  ],
  data() {
    return {
      options: [],
      internalAdditionalOptions: [],
      loading: false
    }
  },
  computed: {
    internalValue: {
      get() {
        if (this.fullModelResponse) {
          return this.modelValue
        } else {
          return this.modelValue?.reduce((old, curr) => {
            old.push(
              this.options?.find((option) => option[this.trackBy] === curr)
            )
            return old
          }, [])
        }
      },
      set(newValue) {
        if (this.fullModelResponse) {
          this.$emit('update:modelValue', newValue)
          this.$emit('change', newValue)
        } else {
          let temp = []
          newValue.reduce((old, curr) => {
            old.push(curr[this.trackBy])
            return old
          }, temp)
          this.$emit('update:modelValue', temp)
          this.$emit('selected:value', temp)
          this.$emit('change', temp)
        }
      }
    },
    cDisplayedValueWhenNotEditionMode() {
      if (this.defaultValue) {
        if (this.attributeLabel && this.defaultValue[this.attributeLabel]) {
          return this.defaultValue[this.attributeLabel]
        }
        return '-'
      }
      return '-'
    },
    cGroupClass() {
      return this.groupClass === '' ? '' : this.groupClass
    }
  },
  mounted() {
    if (this.fullModelResponse) {
      if (this.modelValue.length > 0) {
        this.options = this.modelValue
      }
    } else {
      this.options = this.defaultAjax
    }
  },
  methods: {
    addTag(newTag) {
      const tag = {}
      tag[this.trackBy] = `new${this.additionalTags?.length}`
      tag[this.attributeLabel] = newTag
      this.options.push(tag)

      this.internalAdditionalOptions = [
        ...new Set([...this.internalAdditionalOptions, tag])
      ]
      if (!this.fullModelResponse) {
        this.$emit('update:additionalTags', [
          ...new Set([...this.additionalTags, newTag])
        ])
      }

      if (this.fullModelResponse) {
        this.$emit('tag', [...new Set([...this.modelValue, tag])])
      } else {
        this.$emit('tag', [...new Set([...this.modelValue, tag[this.trackBy]])])
      }
    },
    async fetchOption(keyword) {
      if (keyword.length > 2) {
        this.loading = true
        await apiClient
          .get(
            this.url +
              '?terms=' +
              keyword +
              '&enable=' +
              this.optionSubTitleLabel
          )
          .then(async (response) => {
            this.loading = false
            this.options = [...response.data, ...this.internalAdditionalOptions]
          })
      }
    }
  }
}
</script>

<style>
.v-select {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(74 85 104)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'/></svg>");
  background-size: 18px;
  background-position: center right 0.6rem;
}

.multiselect .multiselect__input {
  border-color: transparent !important;
  box-shadow: none !important;
}

.v-select .vs__dropdown-toggle {
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: #cbd5e0;
  background-repeat: no-repeat;
  padding-left: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
}

.v-select .vs__open-indicator {
  display: none;
}

.v-select .vs__search {
  margin-block: 5px;
}

.v-select .vs__search {
  padding: 0;
}
</style>
