export default {
  setEvents(state, eventsList) {
    state.eventsList = eventsList
  },

  setEvent(state, event) {
    state.event = event
  },
  setFocusEvent(state, focusEvent) {
    state.focusEvent = focusEvent
  },
  setDate(state, date) {
    state.date = date
  },
  setPlanningMetadata(state, metadata) {
    state.planningMetadata = metadata
  },
  setEventMetadata(state, metadata) {
    state.eventMetadata = metadata
  },
  setEventDisponibilities(state, timeslots) {
    state.eventDisponibilities = timeslots
  },
  setEventTimeSlotInEvent(state, timeslots) {
    state.event.timeslots = timeslots
  },
  setCalendarState(state, calendarState) {
    state.calendarState = calendarState
  },
  alterEvent(state, payload) {
    state.event[payload.attribute] = payload.value
  },
  deleteEventKey(state, attribute) {
    delete state.event[attribute]
  },
  replaceEventInList(state, event) {
    let tempList = state.eventsList

    var index = tempList?.findIndex((x) => x.id === event.id)
    tempList[index] = event
    state.eventsList = tempList
  },
  setEventActivity(state, activity) {
    state.eventActivity = activity
  },
  setDataTransferObject(state, payload) {
    state.dataTransferObject = payload
  }
}
