import apiClient from '@u/apiClient'

export default {
  getMarketplaceListModule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`marketplace/module`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getMarketplaceSpecificModule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`marketplace/module/${payload.module_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  enableMarketplaceModule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`marketplace/module/${payload.module_id}/enable`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  disableMarketplaceModule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`marketplace/module/${payload.module_id}/disable`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
