<template>
  <Popover>
    <Float
      placement="bottom-end"
      transition-name="slide-fade-from-top"
      :offset="10"
    >
      <PopoverButton
        class="flex flex-row items-center justify-center gap-2 rounded-md border bg-white p-2 font-bold dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
      >
        <img
          class="h-4 w-6 rounded"
          :src="LanguagesFlags[$i18n.locale]"
          :alt="$i18n.locale"
        />
      </PopoverButton>

      <PopoverPanel
        class="z-10 w-screen max-w-sm px-4 sm:px-0 lg:w-64"
        v-slot="{ close }"
      >
        <div
          class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
        >
          <div class="relative grid gap-1.5 bg-white p-3 dark:bg-slate-700">
            <template v-for="language in listLanguages" :key="language.id">
              <button
                :class="[
                  $i18n.locale === language.id
                    ? 'bg-primary-500 text-white'
                    : ''
                ]"
                class="flex flex-row items-center gap-2 rounded px-2 py-1 text-left hover:bg-gray-200 dark:border-slate-300 dark:text-gray-300 dark:hover:bg-gray-400"
                @click="changeLocal(language.id, close)"
              >
                <img
                  class="h-4 w-6 rounded"
                  :src="LanguagesFlags[language.id]"
                  :alt="language.id"
                />
                <span>{{ language.name }}</span>
              </button>
            </template>
          </div>
        </div>
      </PopoverPanel>
    </Float>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { mapActions, mapGetters } from 'vuex'
import i18n from '@/i18n'
import LanguagesFlags from '@/assets/enums/config/LanguagesFlags'
import { Float } from '@headlessui-float/vue'

export default {
  name: 'LanguageSelector',
  components: { Popover, PopoverButton, PopoverPanel, Float },
  computed: {
    LanguagesFlags() {
      return LanguagesFlags
    },
    ...mapGetters({
      availableLanguages: 'metadata/getAvailableLanguages',
      languages: 'metadata/getLanguages'
    }),
    listLanguages() {
      return this.availableLanguages.map((availableLanguages) =>
        this.languages.find((language) => availableLanguages === language.id)
      )
    }
  },
  methods: {
    ...mapActions({ fetchMetadata: 'metadata/fetchAllMetadata' }),
    changeLocal(locale, close) {
      i18n.setLocale(locale).then((_) => {
        close()
        this.fetchMetadata()
      })
    }
  }
}
</script>

<style>
.slide-fade-from-top-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-from-top-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-from-top-enter-from,
.slide-fade-from-top-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
