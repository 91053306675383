const ScreenRootLayout = () => import('@/views/screen/ScreenRootLayout.vue')
const ScreenLogin = () => import('@/views/screen/ScreenLogin.vue')
const ScreenShow = () => import('@/views/screen/ScreenShow.vue')
const ScreenDebug = () => import('@/views/screen/ScreenDebug.vue')

export const screenRootPages = [
  {
    path: '/screen',
    component: ScreenRootLayout,
    name: 'screen-root-layout',
    redirect: {
      name: 'screen-login'
    },
    children: [
      {
        path: 'login',
        name: 'screen-login',
        component: ScreenLogin
      },
      {
        path: ':screenId',
        name: 'screen-view',
        component: ScreenShow,
        props: true
      },
      {
        path: 'debug',
        name: 'screen-debug',
        component: ScreenDebug,
        props: true
      }
    ]
  }
]
