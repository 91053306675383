const TheMessageTextForm = () =>
  import('@/views/settings/management/messageText/form/TheMessageTextForm.vue')
const TheMessageTextIndexPage = () =>
  import(
    '@/views/settings/management/messageText/index/TheMessageTextIndexPage.vue'
  )

export const settingsMessageTextRoutes = [
  {
    path: 'message-text',
    children: [
      {
        path: '',
        name: 'settings_message_texts_route',
        component: TheMessageTextIndexPage
      },
      {
        path: ':messageTextId',
        name: 'settings_message_text_info_route',
        props: true,
        component: TheMessageTextForm
      },
      {
        path: 'new',
        name: 'settings_create_message_text_route',
        component: TheMessageTextForm
      }
    ]
  }
]
