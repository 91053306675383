import apiClient from '@u/apiClient'

// const base_errors = { deceased: {}, events: [] }

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async postDossierAttachments({ commit }, payload) {
    let formData = new FormData()

    formData.append('medias', JSON.stringify(payload.medias))

    await apiClient
      .post(`dossier-attachment/${payload.dossier_id}`, payload)
      .then(async (response) => {
        commit('setDossier', response.data.dossier)
        // processSuccessCallback(payload)
      })
      .catch(async (reason) => {
        await console.error('postDossierAttachments', reason)
        return error.data.errors
      })
  },
  // #region update

  async renameDossierAttachment({ commit }, payload) {
    await apiClient
      .put(
        `/dossier/${payload.dossier_id}/${payload.attachment_id}/attachment-rename`,
        { name: payload.new_name }
      )
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setDossier', response.data.dossier)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },

  // #endregion

  // #region delete

  /*
          DEPRECATED
   */
  async deleteDossierAttachment({ commit }, payload) {
    await apiClient
      .delete(
        `/dossier/${payload.dossier_id}/${payload.attachment_id}/attachment-delete`
      )
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setDossier', response.data.dossier)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },

  // #endregion

  // region undoDelete
  async undoDeleteDossierAttachment({ commit }, payload) {
    await apiClient
      .get(
        `/dossier/${payload.dossier_id}/${payload.attachment_id}/attachment-undo-delete`
      )
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setDossier', response.data.dossier)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteDossierAttachment', reason)
      })
  },
  // endregion

  postPicturesToDossierGallery: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/gallery`, payload.form_data)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  fetchDossierGallery: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/gallery`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  fetchDossierScreenConfig: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/screen-config`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierScreenConfig: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/${payload.dossier_id}/screen-config`, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchMediaById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(
          `/dossier/${payload.dossier_id}/gallery/picture/${payload.media_id}`
        )
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  deletePictureFromDossierGallery: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(
          `dossier/${payload.dossier_id}/gallery/picture/${payload.picture_id}`
        )
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  usePictureGalleryForPictureDossier: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/picture/picture`, {
          media_id: payload.media_id
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  updateDossierPictureGallery: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      payload.form_data.append('_method', 'PUT')
      apiClient
        .post(
          `dossier/${payload.dossier_id}/gallery/picture/${payload.media_id}`,
          payload.form_data
        )
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  }
}
