<template>
  <VDatePicker
    v-model="localDate"
    :popover="popover"
    :view="view"
    :locale="$i18n.locale.replace('_', '-')"
    :attributes="computedAttributes"
    :min-date="minDate"
    :max-date="maxDate"
    :columns="columns"
    :is-required="required"
    :is-dark="isDarkMode"
    @dayclick="onDayClick"
  >
    <template #default="{ inputValue, inputEvents }">
      <input
        v-mask="['##/##/####', '##-##-####']"
        type="text"
        :value="inputValue"
        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 outline-0 focus:ring-0 dark:bg-slate-500 dark:text-white sm:text-sm"
        v-on="inputEvents"
      />
    </template>
    <template #footer>
      <div
        v-if="displayEventLists && events && events.length"
        class="flex flex-col divide-y border-t px-2 text-xs"
      >
        <template v-for="event in events" :key="event.id">
          <div class="py-2">
            <div class="flex flex-row gap-1">
              <h4 class="font-bold">
                {{ $h.formatDate(event.date, 'DD MMM LT') }}
              </h4>
              <h4>
                {{ event.title }}
              </h4>
            </div>
          </div>
        </template>
      </div>
    </template>
  </VDatePicker>
</template>

<script>
import { DatePicker as VDatePicker } from 'v-calendar'
import moment from 'moment/moment'

export default {
  name: 'DatePicker',
  components: {
    VDatePicker
  },
  props: {
    date: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: false,
      default: 'monthly'
    },
    events: {
      type: Array,
      required: false,
      default: null
    },
    displayEventLists: {
      type: Boolean,
      required: false,
      default: true
    },
    minDate: {
      type: String,
      required: false,
      default: undefined
    },
    maxDate: {
      type: String,
      required: false,
      default: undefined
    },
    columns: {
      type: Number,
      required: false,
      default: 1
    },
    extraDots: {
      type: Array,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:date'],
  data() {
    return {
      popover: {
        visibility: 'click'
      }
    }
  },
  computed: {
    localDate: {
      get() {
        return this.date
      },
      set(value) {
        if (moment(value).isValid()) {
          this.$emit('update:date', value)
        } else {
          this.$emit('update:date', null)
        }
      }
    },
    isDarkMode() {
      return document.documentElement.classList.contains('dark')
    },
    computedAttributes() {
      let attributes = [
        {
          key: 'today',
          highlight: {
            fillMode: 'light'
          },
          dates: new Date()
        }
      ]
      if (this.events && this.events.length) {
        attributes = [
          ...attributes,
          ...this.events.map((event) => {
            return {
              key: `events-${event.id}`,
              dates: moment(event.date).toDate(),
              dot: {
                color: 'red'
              }
            }
          })
        ]
      }
      if (Array.isArray(this.extraDots) && this.extraDots.length) {
        attributes = [
          ...attributes,
          ...this.extraDots.map((dot) => {
            return {
              key: dot.key,
              dates: moment(dot.date).toDate(),
              dot: {
                color: dot.color
              }
            }
          })
        ]
      }
      return attributes
    }
  },
  methods: {
    onDayClick(day) {
      // console.log(day.date.format('Y-m-d'), this.date.format('Y-m-d'))
    }
  }
}
</script>

<style>
.vc-monthly .vc-pane .vc-week:last-of-type:not(:has(.is-last-day)) {
  display: none;
}
</style>
