import apiClient from '@u/apiClient'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  deleteContact({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`contact/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  undoDeleteContact: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`contact/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // region fetch
  fetchContactList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`contact`, {
          params: payload
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async fetchRoleContactList({ commit }, payload) {
    await apiClient
      .get(`contact?role=${payload.id}`)
      .then(async (response) => {
        commit('setRoleContactList', response.data.contacts)
        commit('setContactMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchContactList', reason)
      })
  },

  fetchContactById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`contact/${payload.contact_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchNewContact({ commit }, predefinedRole) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`model/new?model=contact`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  storeNewContact({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post('contact', payload.contact)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateContact({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`contact/${payload.id}`, payload.contact)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
