<template>
  commands
  <div
      class="px-14 pt-20 mx-auto my-10 box relative mx-5 flex h-full flex flex-col gap-4 p-4 overflow-y-auto bg-gray-50">
    <WorkInProgressComponent/>
  </div>

</template>
<script>
import {mapGetters} from 'vuex'
import WorkInProgressComponent from "@/views/wip/WorkInProgressComponent.vue";

export default {
  name: 'TheSAMCommandsPage',
  components: {
    WorkInProgressComponent
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      settings: 'settings/getSettings',
      auth: 'auth/getAuth'
    }),
    deathPlaceTypesOptions() {
      return this.initialMetadata?.enums?.place_types?.filter(place => place.id !== 'LDEC' && place.id !== 'LCER')
    }
  },

}
</script>
