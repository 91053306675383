import apiClient from '@u/apiClient'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    if ('new_id' in payload) {
      payload.successCallback(payload.new_id)
    } else {
      payload.successCallback()
    }
  }
}

export default {
  // region fetch


  fetchNewQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=question')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // fetchSurveyById({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     apiClient
  //       .get(`survey/${payload.id}`)
  //       .then((response) => {
  //         resolve(response.data)
  //       })
  //       .catch((reason) => {
  //         reject(reason)
  //       })
  //   })
  // },
  // endregion

  // region store
  storeNewQuestion: ({ commit }, payload) => {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`/question`, payload.question)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateQuestion: ({ commit }, payload) => {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise(async (resolve, reject) => {
      apiClient
        .put(`/question/${payload.id}`, payload.question)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
 // endregion
  //
  // // region delete
  deleteQuestionById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`/question/question-delete/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // // endregion

  undoDeleteQuestionById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`/question/undo-delete/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
