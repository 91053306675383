<template>
  <div v-show="cVisibility" class="block w-full overflow-x-auto">
    <div class="border-b border-gray-200">
      <nav
        class="-mb-px grid cursor-pointer grid-cols-1 space-x-3 overflow-x-auto bg-white bg-opacity-90 sm:grid-cols-2 md:flex"
        aria-label="Tabs"
      >
        <template v-for="tab in tabs" :key="tab.label">
          <RouterLink
            v-if="tab.gate !== false"
            :to="{ name: tab.routeName, params: tab.params }"
            class="group relative flex flex-row items-center gap-2 border-b-2 px-2 py-1 text-sm font-medium"
            :class="[
              $route.name === tab.routeName
                ? `border-primary-500 text-primary-600`
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            ]"
            :aria-current="tab.current ? 'page' : undefined"
          >
            <FontAwesomeIcon v-if="tab.icon" :icon="['fal', tab.icon]" />
            <span class="whitespace-nowrap capitalize-first scrollbar-thin">
              {{ tab.label }}
            </span>
            <BaseSimpleTabError :tab="tab" />
          </RouterLink>
        </template>
      </nav>
    </div>
  </div>
</template>

<script>
import BaseSimpleTabError from '@c/addf-package/components/BaseTabs/components/BaseSimpleTabError'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
  name: 'BaseSimpleTab',
  components: { FontAwesomeIcon, BaseSimpleTabError },
  props: {
    tabs: { type: Array, required: true },
    colorWhenSelected: {
      type: String,
      required: false,
      default: 'red-lighter'
    }
  },
  computed: {
    cVisibility() {
      return this.tabs.filter((tab) => tab.gate !== false).length > 1
    }
  }
}
</script>
