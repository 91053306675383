let _modules = []
let _version = 'basic'

let setupModule = (modules) => {
  _modules = modules
}

let setupVersion = (version) => {
  _version = version
}

let _moduleMethod = {
  has: (module) => {
    return _modules.includes(module)
  },
  hasNot: (module) => {
    return !_modules.includes(module)
  },
  all: () => _modules
}

let _versionMethod = {
  is: (version) => _version === version
}

export default {
  install(app) {
    app.config.globalProperties.$functionality = {
      module: _moduleMethod,
      version: _versionMethod
    }
  },
  setupModule,
  setupVersion
}
