import apiClient from '@u/apiClient'

export default {
  // region fetch
  fetchMessageTextList({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('message-text')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchMessageTextById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`message-text/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewMessageText({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=messageText')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // endregion

  // region store
  storeMessageText({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post('message-text', payload.message_text)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateMessageText({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`message-text/${payload.message_text.id}`, payload.message_text)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  deleteMessageText({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`message-text/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undoDelete
  undoDeleteMessageText({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`message-text/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  fetchMessageTextConverted: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/message-text/${payload.message_text_id}/convert`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
