<template>
  <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
    <div>
      <BaseShowEditInput
        v-model="localSettings.url"
        label="url"
        :errors="errors?.url"
        :edition-mode="editionMode"
        name="url"
      />
    </div>
    <div class="col-start-1">
      <BaseShowEditInput
        v-model="localSettings.token"
        label="token"
        :errors="errors?.token"
        :edition-mode="editionMode"
        name="token"
      />
    </div>
  </div>
</template>

<script>
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'

export default {
  name: 'FuneralManagerV2ImportSettings',
  components: { BaseShowEditInput },
  props: {
    errors: {
      type: Array,
      required: true
    },
    settings: {
      type: Array,
      required: true,
      default: () => []
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:settings'],
  computed: {
    localSettings: {
      get() {
        return this.settings
      },
      set(value) {
        this.$emit('update:settings', value)
      }
    }
  }
}
</script>
