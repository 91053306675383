<template>
  <div class="shrink-0">
    <h2 class="text-2xl font-bold capitalize-first">
      {{ $t('global.marketplace') }}
    </h2>
  </div>
  <div class="min-h-0 flex-1">
    <div class="flex h-full flex-col gap-4 overflow-y-auto">
      <div v-if="enabledModules.length > 0" class="flex flex-col gap-2">
        <h4 class="text-lg font-semibold capitalize-first">
          {{ $t('global.enabled') }}
        </h4>
        <div
          class="grid auto-rows-fr grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4"
        >
          <div v-for="option in enabledOptionFiltered" :key="option.id">
            <ListOptionComponent
              :option="option"
              :enabled="true"
              @reload-modules="fetchModule"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <h4 class="text-lg font-semibold capitalize-first">
          {{ $t('global.available') }}
        </h4>
        <div
          class="grid auto-rows-fr grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4"
        >
          <div v-for="option in disabledOptionFiltered" :key="option.id">
            <ListOptionComponent
              :option="option"
              :enabled="false"
              display-status
              @reload-modules="fetchModule"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListOptionComponent from '@/views/marketplace/list/ListOptionComponent.vue'
import { mapActions } from 'vuex'

export default {
  name: 'TheMarketPlaceListPage',
  components: { ListOptionComponent },
  data() {
    return {
      enabledModules: [],
      modules: []
    }
  },
  computed: {
    enabledOptionFiltered() {
      return this.modules
        .filter((option) => this.enabledModules.includes(option.id))
        .sort((a, b) => {
          return a.name.localeCompare(b.name.localeCompare)
        })
    },
    disabledOptionFiltered() {
      return this.modules
        .filter((option) => !this.enabledModules.includes(option.id))
        .sort((a, b) => {
          return a.name.localeCompare(b.name.localeCompare)
        })
    }
  },
  mounted() {
    this.fetchModule()
  },
  methods: {
    ...mapActions({
      processGetMarketplaceListModuleAction:
        'marketplace/getMarketplaceListModule'
    }),
    fetchModule() {
      this.processGetMarketplaceListModuleAction().then((response) => {
        this.modules = response.modules
        this.enabledModules = [...response.metadata.module_enabled]
      })
    }
  }
}
</script>
