import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import Toastify from 'toastify-js'
import filterHelper from '@u/helpers/filterHelper'
import searchListHelper from '@u/searchListHelper'
import settingsHelpers from '@u/helpers/settingsHelpers'
import stringHelpers from '@u/helpers/stringHelpers'
import dateHelpers from '@u/helpers/dateHelpers'
import store from '@/store'

import moment from 'moment'
import 'moment/dist/locale/fr-ch'
import 'moment/dist/locale/nl-be'
import 'moment/dist/locale/nl'
import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/ro'

dayjs.extend(duration)

const helpers = {
  ...filterHelper,
  ...searchListHelper,
  ...settingsHelpers,
  ...stringHelpers,
  ...dateHelpers,
  /**
   * @param data
   * @param name
   * @param formData
   * @return {FormData}
   */
  toFormData(data, name, formData = null) {
    if (!formData) {
      formData = new FormData()
    }

    for (const keyName in data) {
      const value = data[keyName]
      const formDataName = name ? `${name}[${keyName}]` : keyName

      if (value instanceof File) {
        formData.append(formDataName, value)
      } else if (value instanceof Array || typeof value === 'object') {
        this.toFormData(value, formDataName, formData)
      } else {
        formData.append(formDataName, value)
      }
    }

    return formData
  },
  setTheme(theme = null) {
    if (theme !== null) {
      document.documentElement.setAttribute('data-theme', theme)
      localStorage.setItem('theme', theme)
    } else {
      localStorage.removeItem('theme')
    }
  },
  getTheme() {
    return !!localStorage.getItem('theme')
  },
  getDevPort() {
    return window.location.port === '1600' ? 2005 : 8102
  },
  initTheme() {
    let theme = localStorage.getItem('theme')
    if (theme !== null) {
      if (theme.substring(0, 6) === 'theme-') {
        theme = theme.substring(7)
      }
      this.setTheme(theme)
      return this.setTheme(theme)
    }
    if (document.body.getAttribute('data-brand') === 'funero') {
      this.setTheme('tangerine')
      return this.setTheme('tangerine')
    } else {
      this.setTheme('blue')
      return this.setTheme('blue')
    }
  },
  initBrand(app) {
    const { hostname } = window.location
    const domain = hostname.split('.')[1]
    const subdomain = hostname.split('.')[0]
    let brand = 'funeral-manager'
    if (['ginkgho', 'funerali'].includes(subdomain)) {
      brand = 'ginkgho'
    } else if (domain === 'funero') {
      brand = 'funero'
    }
    document.body.setAttribute('data-brand', brand)
    if (app) {
      app.config.globalProperties.$brand = brand
    }
    this.initTheme()
  },
  toastify(text, param = {}) {
    Toastify({
      ...{
        text: text,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        className: 'toastify-settings'
      },
      ...param
    }).showToast()
  },
  toastifyHtml(param = {}) {
    Toastify({
      ...{
        duration: 3000,
        newWindow: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
        // escapeMarkup: false
      },
      ...param
    }).showToast()
  },
  getToastCloneElement(id) {
    let notificationHtmlElement = document.getElementById(id).cloneNode(true)
    notificationHtmlElement.removeAttribute('id')
    notificationHtmlElement.classList.remove('hidden')
    return notificationHtmlElement
  },
  toastAction(type, title, description, actions, icon) {
    let toastElement = this.getToastCloneElement('toast_action_content')
    toastElement.querySelector('.toast-title').innerHTML = title
    if (!description) {
      toastElement.querySelector('.toast-description').remove()
    } else {
      oastElement.querySelector('.toast-description').innerHTML = description
    }
    if (['info', 'delete'].includes(type)) {
      toastElement
        .querySelector(`.toast-icon-${type}`)
        .classList.remove('hidden')
    }
    if (Array.isArray(actions)) {
      let ctaContainer = toastElement.querySelector('.toast-cta-container')
      let actionButton = toastElement
        .querySelector('.template-button')
        .cloneNode(true)
      toastElement.querySelector('.template-button').remove()
      actions.forEach((action) => {
        let newButton = actionButton.cloneNode(true)
        newButton.classList.remove('hidden', 'template-button')
        newButton.innerText = action.title
        if (action.class) {
          newButton.classList.add(action.class)
        }
        if (action.action && typeof action.action === 'function') {
          newButton.onclick = () => {
            action.action()
            toastElement.remove()
          }
        }
        ctaContainer.append(newButton)
      })
    }
    Toastify({
      node: toastElement,
      duration: 5000,
      newWindow: false,
      close: false,
      gravity: 'top',
      position: 'right',
      stopOnFocus: false
    }).showToast()
  },
  toast(type, title, text, uniqId) {
    if (uniqId !== undefined && !!document.getElementById(uniqId)) {
      return
    }
    let notificationHtmlElement = document
      .getElementById('generic_toast')
      .cloneNode(true)
    notificationHtmlElement.removeAttribute('id')
    if (uniqId !== undefined) {
      notificationHtmlElement.setAttribute('id', uniqId)
    }
    notificationHtmlElement.classList.remove('hidden')
    notificationHtmlElement.getElementsByClassName('toast-title')[0].innerHTML =
      title
    if (text && text.length) {
      let descriptionElement =
        notificationHtmlElement.getElementsByClassName('toast-description')[0]
      descriptionElement.innerHTML = text
      descriptionElement.classList.remove('hidden')
    }
    notificationHtmlElement
      .getElementsByClassName(`toast-icon-${type}`)[0]
      .classList.remove('hidden')

    Toastify({
      node: notificationHtmlElement,
      duration: type === 'success' ? 2000 : 5000,
      newWindow: false,
      close: false,
      gravity: 'top',
      position: 'right',
      stopOnFocus: false
    }).showToast()
  },
  toastSuccess: (title, text, uniqId) => {
    helpers.toast('success', title, text, uniqId)
  },
  toastWarning: (title, text, uniqId) => {
    helpers.toast('warning', title, text, uniqId)
  },
  toastDanger: (title, text, uniqId) => {
    helpers.toast('danger', title, text, uniqId)
  },
  toastInfo: (title, text, uniqId) => {
    helpers.toast('info', title, text, uniqId)
  },
  unsavedChangesNotification(next) {
    let notificationHtmlElement = document
      .getElementById('unsaved_change_notification')
      .cloneNode(true)
    notificationHtmlElement.removeAttribute('id')
    notificationHtmlElement.classList.remove('hidden')

    notificationHtmlElement
      .getElementsByClassName('btn_stay_page')[0]
      .addEventListener('click', () => {
        notificationHtmlElement.classList.add('hidden')
      })
    notificationHtmlElement
      .getElementsByClassName('btn_next_page')[0]
      .addEventListener('click', () => {
        notificationHtmlElement.classList.add('hidden')
        next()
      })

    Toastify({
      node: notificationHtmlElement,
      duration: 5000,
      newWindow: false,
      close: false,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  },
  cutText(text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  formatDate(date, format, locale) {
    locale = locale !== undefined ? locale : 'fr'
    let _moment = moment(date)
    return _moment.isValid() ? _moment.locale(locale).format(format) : ''
  },
  momentLocalised(date, format, locale) {
    if (locale === undefined) {
      switch (store.getters['i18n/getLocal']) {
        case 'fr_BE':
        case 'fr_FR':
          locale = 'fr'
          break
        case 'fr_CH':
          locale = 'fr-ch'
          break
        case 'nl_BE':
          locale = 'nl-be'
          break
        case 'nl_NL':
          locale = 'nl'
          break
        default:
          locale = 'en'
      }
    }
    return moment(date).locale(locale).format(format)
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  formatCurrency(price, locale = 'fr-FR', currency = 'EUR') {
    return Number(price).toLocaleString(locale, {
      style: 'currency',
      currency: currency
    })
  },
  timeAgo(time) {
    const date = new Date((time || '').replace(/-/g, '/').replace(/[TZ]/g, ' '))
    const diff = (new Date().getTime() - date.getTime()) / 1000
    const dayDiff = Math.floor(diff / 86400)

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format('MMMM DD, YYYY')
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && 'just now') ||
          (diff < 120 && '1 minute ago') ||
          (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
          (diff < 7200 && '1 hour ago') ||
          (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
      (dayDiff === 1 && 'Yesterday') ||
      (dayDiff < 7 && dayDiff + ' days ago') ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
    )
  },
  diffTimeByNow(time) {
    const startDate = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss').toString())
    const endDate = dayjs(dayjs(time).format('YYYY-MM-DD HH:mm:ss').toString())

    const duration = dayjs.duration(endDate.diff(startDate))
    const milliseconds = Math.floor(duration.asMilliseconds())

    const days = Math.round(milliseconds / 86400000)
    const hours = Math.round((milliseconds % 86400000) / 3600000)
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000)
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    )

    if (seconds < 30 && seconds >= 0) {
      minutes += 1
    }

    return {
      days: days.toString().length < 2 ? '0' + days : days,
      hours: hours.toString().length < 2 ? '0' + hours : hours,
      minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
      seconds: seconds.toString().length < 2 ? '0' + seconds : seconds
    }
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  deepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomNumbers(from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  pluck(object, key) {
    return object.map((company) => company[key])
  },
  getTranslatedText(obj) {
    let local = store.getters['i18n/getLocal']
    if (obj[local]) return obj[local]
    if (obj['fr_BE']) return obj['fr_BE']
    return obj
  },
  downloadURI: (uri, name) => {
    const link = document.createElement('a')
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
  toggleElementInArray(array, element) {
    array.indexOf(element) !== -1
      ? array.splice(array.indexOf(element), 1)
      : array.push(element)
    return array
  }
}

const install = (app) => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }
