import helper from './helper'
import VueTheMask from 'vue-the-mask'

// import moment from 'moment'
// import 'moment/locale/fr'
// import 'moment/locale/fr-ch'
// import 'moment/locale/nl-be'
// import 'moment/locale/nl'
// import 'moment/locale/es'

export default (app) => {
  app.use(helper)
  app.use(VueTheMask)
  // app.use(moment)
}
