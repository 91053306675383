<template>
  <li class="flex flex-col py-3 pl-3 pr-4 text-sm">
    <div class="flex items-center gap-6">
      <div class="flex-1">
        <FontAwesomeIcon :icon="['fal', 'paperclip']" />
        <span class="ml-2 w-0 flex-1">{{ dump.title }}</span>
      </div>
      <div class="shrink-0">
        <button
          class="font-medium text-blue-600 hover:text-blue-500"
          @click="handleClickRestore"
        >
          restore
        </button>
      </div>
      <div class="shrink-0">
        <button
          class="font-medium text-red-600 hover:text-red-500"
          @click="handleClickRestore"
        >
          delete
        </button>
      </div>
    </div>
    <div v-if="showConfirmation" class="mt-4 flex justify-between gap-4">
      <div class="w-11/12">
        <label
          for="confirmationTexte"
          class="block text-sm font-medium leading-6 text-gray-600"
          >Pour confirmer la restauration, saisissez
          <span class="font-bold italic text-gray-900">
            restaurer cette sauvegarde
          </span>
          dans le champ de saisie de texte.
        </label>
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <input
              id="confirmationTexte"
              v-model="confirmationTexte"
              type="text"
              class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-slate-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              placeholder="restaurer cette sauvegarde"
            />
          </div>
          <button
            v-if="confirmationTexte"
            type="button"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-gray-900"
            @click="handleConfirmClick"
          >
            confirmer
          </button>
        </div>
        <p v-if="error" class="ml-1 mt-1 text-red-600">
          Le texte de confirmation est requis.
        </p>
      </div>
      <div v-if="loading" class="flex items-center justify-center">
        <!--        <mike-pad/>-->
      </div>
    </div>
  </li>
</template>
<script>
import { mapActions } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'DumpComponent',
  components: {
    FontAwesomeIcon
    // MikePad
  },
  props: {
    dump: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      showConfirmation: false,
      confirmationTexte: ''
    }
  },
  methods: {
    ...mapActions({ processRestoreDumpAction: 'saManagement/restoreDump' }),
    handleClickRestore() {
      this.showConfirmation = !this.showConfirmation
    },
    handleConfirmClick() {
      if (this.confirmationTexte === 'restaurer cette sauvegarde') {
        this.loading = true
        this.processRestoreDumpAction({
          dump: this.dump.id,
          successCallback: () => {
            this.$h.toastify('cette sauvegarde a bien été restaurée', {
              className: 'toastify-content success'
            })
            this.confirmationTexte = ''
            this.loading = false
            this.showConfirmation = false
            window.location.href = '/connect'
          }
        })
      } else {
        this.error = true
      }
    }
  }
}
</script>
