import apiClient from '@u/apiClient'
import apiClientScreen from '@u/apiClientScreen'

export default {
  authenticate({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      apiClientScreen
        .post(`/screen/login`, {
          access_code: payload.access_code
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  setScreenId({ commit }, payload) {
    commit('setScreenId', {
      screen_id: payload.screen_id
    })
  },
  getScreenById({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      apiClientScreen
        .get(`/screen/${payload.screen_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getScreenConfig({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      apiClientScreen
        .get(`/screen/${payload.screen_id}/config`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
