import apiClient from '@u/apiClient'

export default {
  // region fetch
  fetchNotifications() {
    return new Promise((resolve, reject) => {
      apiClient
        .get('notification')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async markAsReadNotification({ commit }, payload) {
    return apiClient
      .post(`/notification/mark-as-read/${payload}`, {})
      .then((response) => response.data)
  },

  async fetchNotificationSubscriptions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/fetch-notification-subscriptions/${payload.id}`, {
          params: {
            model: payload.model
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  //region edit
  updateNotificationSubscription: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`/update-notification-subscriptions/${payload.id}`, {
          model: payload.model,
          notification_subscriptions: payload.notification_subscriptions
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  //endregion

  sendNotificationToUsers: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/notification/notify-users`, {
          user_ids: payload.user_ids,
          text: payload.text,
          title: payload.title,
          via: payload.via
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
