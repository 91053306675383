<template>
  <Popover>
    <Float auto-placement portal :offset="10">
      <PopoverButton
        class="rounded-full border border-slate-300 shadow-xl dark:border dark:border-white dark:bg-slate-700 dark:text-white"
        :class="[
          badge
            ? ' flex flex-row items-center gap-2 bg-white pr-4'
            : 'inline-block',
          $attrs.class
        ]"
      >
        <div class="relative">
          <div
            v-if="user && user.avatar_url && imgAvailable"
            class="flex flex-row items-center gap-2"
          >
            <img
              :class="`h-${size} w-${size} overflow-hidden rounded-full border-2 border-white`"
              :src="user.avatar_url"
              :alt="user.initials"
              @error="imgNotAvailable"
            />
          </div>
          <div
            v-else
            class="text-avatar relative overflow-hidden rounded-full border-white bg-gradient-to-bl from-primary-500 to-primary-600 font-bold shadow-2xl"
            :class="[`h-${size} w-${size}`, get_font_size]"
            :style="{ borderWidth: `${size / 4}px` }"
          >
            <div
              class="absolute inset-0 flex items-center justify-center truncate"
              :class="[role !== null ? 'animate-fadeoutfadein' : '']"
            >
              {{ user ? user.initials : '##' }}
            </div>
            <div
              v-if="role !== null"
              class="absolute inset-0 flex animate-fadeinfadeout items-center justify-center"
            >
              <FontAwesomeIcon :icon="['fas', roleUser]" />
            </div>
          </div>
          <span
            v-if="displayStatus && user && user.status"
            class="absolute bottom-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white"
            :class="state_color"
          ></span>
        </div>

        <div v-if="badge" class="text-gray-700">
          <div class="whitespace-nowrap font-bold leading-tight">
            {{ user?.full_name }}
          </div>
          <div
            v-if="subtitle && subtitle.length"
            class="truncate whitespace-nowrap text-xs"
          >
            {{ subtitle }}
          </div>
        </div>
      </PopoverButton>
      <PopoverPanel
        v-slot="{ close }"
        class="z-10 w-screen max-w-sm px-4 sm:px-0 lg:w-72"
      >
        <div
          class="flex flex-col divide-y rounded-md border bg-white text-left shadow-2xl"
        >
          <div class="px-4 pb-3 pt-2">
            <h3 class="truncate text-xl font-semibold text-slate-800">
              {{ user.full_name }}
            </h3>
            <div>
              <span
                class="truncate rounded px-1.5 py-0.5 text-xs"
                :class="[state_color]"
              >
                {{ state_text }}
              </span>
            </div>
          </div>
          <div class="px-4 py-3">
            <div class="flex flex-row gap-1">
              <button
                v-for="contact in contactChannel"
                :key="contact.key"
                class="flex aspect-square w-8 items-center justify-center rounded border text-primary hover:bg-primary hover:text-white"
                @click="
                  () => {
                    composeType = contact.key
                    isComposeDialogOpen = true
                  }
                "
              >
                <FontAwesomeIcon :icon="contact.icon" size="lg" />
              </button>
            </div>
          </div>
          <div
            v-if="
              user.phone_number?.length ||
              user.mobile_number?.length ||
              user.email?.length
            "
            class="flex flex-col gap-1 px-4 py-3 font-semibold text-slate-700"
          >
            <div v-if="user.phone_number" class="flex items-center gap-2">
              <FontAwesomeIcon
                fixed-width
                :icon="['fal', 'phone']"
                class="shrink-0"
              />
              <div class="flex-1 truncate">{{ user.phone_number }}</div>
            </div>
            <div v-if="user.mobile_number" class="flex items-center gap-2">
              <FontAwesomeIcon
                fixed-width
                :icon="['fal', 'mobile']"
                class="shrink-0"
              />
              <div class="flex-1 truncate">{{ user.mobile_number }}</div>
            </div>
            <div v-if="user.email" class="flex items-center gap-2">
              <FontAwesomeIcon
                fixed-width
                :icon="['fal', 'envelope']"
                class="shrink-0"
              />
              <div class="flex-1 truncate">
                <a
                  :href="`mailto:${user.email}`"
                  class="truncate text-primary"
                  target="_blank"
                  >{{ user.email }}</a
                >
              </div>
            </div>
          </div>
          <div v-if="$slots.panel" class="px-4 py-3">
            <slot name="panel" :close="close" :user-id="user_id"></slot>
          </div>
        </div>
        <NotificationComposeDialog
          v-model:is-open="isComposeDialogOpen"
          :user-id="user_id"
          :type="composeType"
        />
      </PopoverPanel>
    </Float>
  </Popover>
</template>

<script>
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import NotificationComposeDialog from '@c/Notification/Compose/NotificationComposeDialog.vue'

export default {
  name: 'AvatarComponent',
  components: {
    NotificationComposeDialog,
    Float,
    PopoverPanel,
    PopoverButton,
    FontAwesomeIcon,
    Popover
  },
  props: {
    user_id: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: Number,
      required: false,
      default: 9
    },
    badge: {
      type: Boolean,
      required: false,
      default: false
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    displayStatus: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      imgAvailable: true,
      contactAvailable: [
        'toast',
        'database',
        'sms',
        // 'mail',
        'slack'
      ],
      isComposeDialogOpen: false,
      composeType: null
    }
  },
  computed: {
    ...mapGetters({ getUser: 'metadata/getAllUserMetadata' }),
    contactChannel() {
      return [
        {
          key: 'toast',
          icon: ['fal', 'bell'],
          iconColor: 'text-primary'
        },
        {
          key: 'database',
          icon: ['fal', 'database'],
          iconColor: 'text-primary'
        },
        {
          key: 'sms',
          icon: ['fal', 'message-sms'],
          iconColor: 'text-primary'
        },
        {
          key: 'mail',
          icon: ['fal', 'envelope'],
          iconColor: 'text-primary'
        },
        {
          key: 'slack',
          icon: ['fab', 'slack'],
          iconColor: 'text-primary'
        }
      ].filter((item) => this.contactAvailable.includes(item.key))
    },
    user() {
      return this.getUser(this.user_id)
    },
    get_font_size() {
      switch (true) {
        case this.size <= 8:
          return 'text-xs'
        case this.size === 40:
          return 'text-6xl'
        default:
          return 'text-sm'
      }
    },
    state_color() {
      if (!this.user.online) {
        return 'bg-red-700 text-white'
      }
      switch (this.user.status) {
        case 'available':
          return 'bg-green-400 text-slate-800'
        case 'unavailable':
        case 'busy':
          return 'bg-yellow-500 text-slate-800'
        case 'offline':
          return 'bg-red-700 text-white'
        default:
          return 'bg-gray-300  text-slate-800'
      }
    },
    state_text() {
      if (!this.user.online) {
        return this.$t('enums.user.status.offline')
      }
      return this.$t(`enums.user.status.${this.user.status}`)
    },
    roleUser() {
      switch (this.role) {
        case 'user':
          return 'user'
        case 'driver':
          return 'steering-wheel'
        case 'carrier':
          return 'people-carry-box'
        case 'speaker':
          return 'microphone'
        default:
          return 'user'
      }
    }
  },
  methods: {
    imgNotAvailable() {
      this.imgAvailable = false
    }
  }
}
</script>
