<template>
  <h1
    v-if="withTitle"
    class="col-start-1 text-2xl font-medium capitalize-first"
  >
    {{ $t(`enums.config.languages.${language}`) }}
  </h1>
  <template v-if="model">
    <template v-if="withName">
      <BaseShowEditInput
        v-if="nameKey in model"
        v-model="model[nameKey][language]"
        :class="nameClass"
        :edition-mode="editionMode"
        :errors="
          errors[nameKey] && displayError ? errors[nameKey][language] : null
        "
        :label="cNameLabel"
        :required="required"
        :name="nameKey"
      />
    </template>
    <template v-if="withShortName">
      <BaseShowEditInput
        v-if="shortNameKey in model"
        v-model="model[shortNameKey][language]"
        :edition-mode="editionMode"
        :errors="errors[shortNameKey] ? errors[shortNameKey][language] : null"
        :label="cShortNameLabel"
        :name="shortNameKey"
        :required="true"
      />
    </template>
    <template v-if="withVarianteName">
      <BaseShowEditInput
        v-if="varianteNameKey in model"
        v-model="model[varianteNameKey][language]"
        :edition-mode="editionMode"
        :errors="
          errors[varianteNameKey] ? errors[varianteNameKey][language] : null
        "
        :label="cVarianteNameLabel"
        :name="varianteNameKey"
      />
    </template>
    <template v-if="withRemarks">
      <BaseShowEditTextArea
        v-if="remarksKey in model"
        v-model="model[remarksKey][language]"
        :class="remarksClass"
        :label="cRemarksLabel"
        :edition-mode="editionMode"
        :errors="errors[remarksKey] ? errors[remarksKey][language] : null"
        :name="remarksKey"
      />
    </template>
    <template v-if="withInternalNote">
      <BaseShowEditTextArea
        v-if="internalNoteKey in model"
        v-model="model[internalNoteKey][language]"
        :label="cInternalNoteLabel"
        :edition-mode="editionMode"
        :errors="
          errors[internalNoteKey] ? errors[internalNoteKey][language] : null
        "
        :name="internalNoteKey"
      />
    </template>
  </template>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseShowEditTextArea from '@c/addf-package/components/BaseShowEditInput/BaseShowEditTextArea.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'

export default {
  name: 'TranslationComponent',
  components: { BaseShowEditInput, BaseShowEditTextArea },
  props: {
    model: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: true
    },
    withName: {
      type: Boolean,
      required: false,
      default: true
    },
    withShortName: {
      type: Boolean,
      required: false,
      default: true
    },
    withRemarks: {
      type: Boolean,
      required: false,
      default: true
    },
    withInternalNote: {
      type: Boolean,
      required: false,
      default: true
    },
    withVarianteName: {
      type: Boolean,
      required: false,
      default: true
    },
    withTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    remarksKey: {
      type: String,
      required: false,
      default: 'remarks'
    },
    remarksLabel: {
      type: String,
      required: false,
      default: null
    },
    internalNoteKey: {
      type: String,
      required: false,
      default: 'internal_note'
    },
    internalNoteLabel: {
      type: String,
      required: false,
      default: null
    },
    nameKey: {
      type: String,
      required: false,
      default: 'name'
    },
    nameLabel: {
      type: String,
      required: false,
      default: null
    },
    shortNameKey: {
      type: String,
      required: false,
      default: 'short_name'
    },
    shortNameLabel: {
      type: String,
      required: false,
      default: null
    },
    varianteNameKey: {
      type: String,
      required: false,
      default: 'variante_name'
    },
    varianteNameLabel: {
      type: String,
      required: false,
      default: null
    },
    remarksClass: {
      type: String,
      required: false,
      default: null
    },
    nameClass: {
      type: String,
      required: false,
      default: 'col-start-1'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    displayError: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors'
    }),
    cNameLabel() {
      if (!this.nameLabel) {
        return this.withTitle
          ? this.$t('attributes.name')
          : this.$t('attributes.name_description', {
              var: this.$t(`enums.config.languages.${this.language}`)
            })
      }
      return this.withTitle
        ? this.nameLabel
        : this.$t(`attributes.${this.nameKey}_description`, {
            var: this.$t(`enums.config.languages.${this.language}`)
          })
    },
    cShortNameLabel() {
      if (!this.shortNameLabel) {
        return this.withTitle
          ? this.$t('attributes.short_name')
          : this.$t('attributes.short_name_description', {
              var: this.$t(`enums.config.languages.${this.language}`)
            })
      }
      return this.withTitle
        ? this.shortNameLabel
        : this.$t(`attributes.${this.shortNameKey}_description`, {
            var: this.$t(`enums.config.languages.${this.language}`)
          })
    },
    cVarianteNameLabel() {
      if (!this.varianteNameLabel) {
        return this.withTitle
          ? this.$t('attributes.variante_name')
          : this.$t('attributes.variante_name_description', {
              var: this.$t(`enums.config.languages.${this.language}`)
            })
      }
      return this.withTitle
        ? this.varianteNameLabel
        : this.$t(`attributes.${this.varianteNameKey}_description`, {
            var: this.$t(`enums.config.languages.${this.language}`)
          })
    },
    cRemarksLabel() {
      if (!this.remarksLabel) {
        return this.withTitle
          ? this.$t('attributes.remarks')
          : this.$t('attributes.remarks_description', {
              var: this.$t(`enums.config.languages.${this.language}`)
            })
      }
      return this.withTitle
        ? this.remarksLabel
        : this.$t(`attributes.${this.remarksKey}_description`, {
            var: this.$t(`enums.config.languages.${this.language}`)
          })
    },
    cInternalNoteLabel() {
      if (!this.internalNoteLabel) {
        return this.withTitle
          ? this.$t('attributes.internal_note')
          : this.$t('attributes.internal_note_description', {
              var: this.$t(`enums.config.languages.${this.language}`)
            })
      }
      return this.withTitle
        ? this.internalNoteLabel
        : this.$t(`attributes.${this.internalNoteKey}_description`, {
            var: this.$t(`enums.config.languages.${this.language}`)
          })
    }
  }
}
</script>
