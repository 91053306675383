<template>
  <div
    class="my-10 flex h-screen items-center bg-white py-5 dark:bg-slate-700 dark:text-white md:my-0 md:h-auto md:py-0"
  >
    <div
      class="mx-auto my-auto w-full rounded-md border border-gray-200 bg-white px-5 py-8 shadow-md dark:rounded-md dark:border dark:border-white dark:bg-slate-800 dark:text-white sm:w-3/4 sm:px-8 lg:w-3/4 xl:ml-20 xl:w-3/4 xl:bg-transparent xl:p-3 xl:shadow-none"
    >
      <h2 class="text-center text-2xl font-bold capitalize-first xl:text-3xl">
        {{ $t('navigation.login_title') }}
      </h2>

      <div
        v-if="!!requestFailed"
        class="mt-4 rounded bg-rose-200 p-3 text-rose-900"
      >
        <!--        Identifiant ou mot de passe incorrect-->
        {{ $t('validation.auth_failed') }}
      </div>

      <form @submit.prevent="submitForm">
        <div class="mt-8 space-y-8">
          <div>
            <input
              v-model.trim="user_name"
              type="text"
              class="login__input form-control block border-gray-300 px-4 py-3"
              :placeholder="$t('attributes.username')"
              required="required"
            />

            <template v-if="errors.user_name">
              <div
                v-for="(error, index) in errors.user_name"
                :key="index"
                class="form-help text-red-600"
              >
                {{ $t(error, { attribute: $t('attributes.user_name') }) }}
              </div>
            </template>
          </div>

          <div>
            <div class="relative">
              <input
                v-model.trim="password"
                :type="passwordShowType"
                class="login__input form-control block border-gray-300 px-4 py-3 dark:bg-slate-800 dark:text-white"
                :placeholder="$t('attributes.password')"
                required="required"
              />
              <div
                class="absolute inset-y-0 right-0 top-0 mr-4 flex items-center"
              >
                <FontAwesomeIcon
                  class="cursor-pointer text-gray-500"
                  :icon="['fal', 'eye']"
                  size="lg"
                  @click="switchPasswordVisibility"
                />
              </div>
            </div>

            <div
              v-if="errors && errors.password"
              class="form-help text-red-600 capitalize-first"
            >
              {{ $t('validation.wrong_password') }}
            </div>
          </div>
        </div>

        <div
          class="mt-4 flex text-xs text-gray-700 dark:text-gray-300 sm:text-sm"
        >
          <div class="mr-auto flex items-center">
            <input
              id="remember-me"
              v-model="remember_me"
              type="checkbox"
              class="form-check-input mr-2 border"
            />
            <label
              class="cursor-pointer select-none capitalize-first"
              for="remember-me"
              >{{ $t('attributes.remember_me') }}</label
            >
          </div>

          <!--          <div-->
          <!--            class="cursor-pointer text-theme-primary"-->
          <!--            @click="isShowingUrlInput = !isShowingUrlInput"-->
          <!--          >-->
          <!--            <font-awesome-icon :icon="['fal', 'cogs']" />-->
          <!--          </div >-->
        </div>
        <div class="mt-5 text-center xl:mt-8 xl:text-left">
          <button
            class="btn w-full bg-primary px-4 py-3 align-top text-contrast-primary dark:border dark:border-primary dark:bg-slate-800 dark:text-primary dark:hover:bg-slate-700 xl:mr-3 xl:w-32"
          >
            <span class="capitalize-first">
              {{ $t('button.login') }}
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'LoginForm',
  components: { FontAwesomeIcon },
  data() {
    return {
      user_name: null,
      password: null,
      tenantId: localStorage.getItem('tenantId'),
      remember_me: false,
      passwordShowType: 'password',
      isShowingUrlInput: false,
      requestFailed: false
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors',
      isAuthenticated: 'auth/isAuthenticated'
    })
  },
  props: {},
  watch: {
    tenantId(newValue) {
      localStorage.setItem('tenantId', newValue)
    }
  },
  mounted() {
    /* if (
     localStorage.getItem('tenantId') &&
     this.mIsValidHttpUrl(localStorage.getItem('url'))
     ) {
     this.url = localStorage.getItem('url')
     }*/
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    submitForm() {
      this.requestFailed = false
      localStorage.setItem('tenantId', this.tenantId)
      this.login({
        tenantId: this.tenantId,
        auth_form: {
          user_name: this.user_name,
          password: this.password,
          remember_me: this.remember_me ? 'on' : undefined
        }
      })
        .then((_response) => {
          this.$router.push({ name: 'dashboard_main_page_route' })
        })
        .catch((_reason) => {
          this.requestFailed = true
        })
    },
    switchPasswordVisibility() {
      this.passwordShowType =
        this.passwordShowType === 'password' ? 'text' : 'password'
    }
  }
}
</script>
