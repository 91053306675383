<template>
  <SelectMultipleAjax
    v-model:values="localEmailables"
    v-model:value-object="localEmailables"
    :url="'notification/notifiable-global-search'"
    :allow-tags="true"
    :regex-tag="regex"
    :label="label"
    :validity-attribute="'emailable'"
    :ajax-extra-params="{ enable: 'emailable' }"
    :disabled="disabled"
  >
    <template #option="{ option }">
      <span>{{ option.name }}</span>
      <span
        v-if="option.emailable && option.emailable.match(new RegExp(regex, 'g'))"
      >
        &mdash;
        <span class="text-xs">{{ option.emailable }}</span>
      </span>
      <span v-else>
        &mdash;
        <span class="text-xs">
          {{ $t('validation.in').replace(':attribute', $t('global.email')) }}
        </span>
      </span>
    </template>
    <template #pill="{ value }">
      {{ value.name }}
      {{ value['emailable'] ? ' &mdash; <' + value['emailable'] + '>' : '' }}
    </template>
  </SelectMultipleAjax>
  <ErrorContainer :error-key="name" :errors="errors"/>
</template>

<script>
import {defineComponent} from 'vue'
import {debounce} from '@u/debounce'
import SelectMultipleAjax from '@c/SelectAjaxComponent/component/SelectMultipleAjax.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default defineComponent({
  name: 'SelectEmailable',
  components: {ErrorContainer, SelectMultipleAjax},
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    emailables: {
      type: Array,
      required: true,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    allowTags: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:emailables'],
  data() {
    return {
      regex: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'
    }
  },
  computed: {
    localEmailables: {
      get() {
        return this.emailables
      },
      set(value) {
        this.$emit('update:emailables', value)
      }
    }
  },
  created() {
    this.debouncedSearch = debounce((terms) => {
      if (terms && terms.length >= 2) {
        this.search(terms)
      }
    }, 1000)
  }
})
</script>
