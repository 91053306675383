<template>
  <div class="w-full">
    <div class="fm-form-input" :class="label ? 'mt-2' : ''">
      <label v-if="label"
        >{{ label }}
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <div class="relative rounded-md">
        <input
          v-model="localModelValue"
          :type="passwordShowType"
          autocomplete="new-password"
          class="w-full rounded-md"
        />
        <div class="absolute inset-y-0 right-3 flex items-center">
          <button
            class="text-gray-500 dark:bg-slate-800"
            @click="switchPasswordVisibility"
          >
            <FontAwesomeIcon :icon="['fal', 'eye']" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <ErrorContainer :error-key="name" :errors="errors" />
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'PasswordInput',
  components: { ErrorContainer, FontAwesomeIcon },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: [Object, Array],
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      passwordShowType: 'password'
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.$emit('change')
      }
    }
  },
  methods: {
    switchPasswordVisibility() {
      this.passwordShowType =
        this.passwordShowType === 'password' ? 'text' : 'password'
    }
  }
}
</script>
