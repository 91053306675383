<template>
  <div
    class="box relative mx-5 mx-auto my-10 flex flex h-full flex-col gap-4 overflow-y-auto bg-gray-50 p-4 px-14 pt-20"
  >
    home
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TheGeneralSettingsComponent',
  components: {},
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      settings: 'settings/getSettings',
      auth: 'auth/getAuth'
    }),
    deathPlaceTypesOptions() {
      return this.initialMetadata?.enums?.place_types?.filter(
        (place) => place.id !== 'LDEC' && place.id !== 'LCER'
      )
    }
  }
}
</script>
