<template>
  <div>
    <dd
      class="mt-1 text-sm text-gray-900"
      :class="{
        'pointer-events-none': disabled,
        'opacity-70': disabled
      }"
    >
      <ul
        role="list"
        :class="{
          'divide-y divide-slate-300 rounded-md border border-slate-300 shadow-sm':
            hasAttachments
        }"
      >
        <template
          v-if="email.new_attachments && email.new_attachments.length > 0"
        >
          <li
            v-for="(document, idx) in email.new_attachments"
            :key="idx"
            class="flex items-center justify-between py-2 pl-3 pr-4 text-sm"
          >
            <EmailAttachment
              :title="document.name"
              :attachment="document"
              :attachment-index="document"
              @delete-attachment="
                () => handleDeleteAttachment('new_attachments', idx)
              "
            />
          </li>
        </template>

        <template
          v-if="email.email_attachments && email.email_attachments.length > 0"
        >
          <li
            v-for="(attachment, index) in email.email_attachments"
            :key="index"
            class="flex items-center justify-between py-2 pl-3 pr-4 text-sm"
          >
            <EmailAttachment
              :title="attachment.name"
              :attachment="attachment"
              :attachment-index="index"
              :url="attachment.original_url"
              @delete-attachment="
                () => handleDeleteAttachment('email_attachments', index)
              "
            />
          </li>
        </template>

        <template v-if="email.attachable && email.attachable.length > 0">
          <li
            v-for="(attachment, index) in email.attachable"
            :key="index"
            class="flex items-center justify-between py-2 pl-3 pr-4 text-sm"
          >
            <EmailAttachment
              :title="attachment.name"
              :attachment="attachment"
              :attachment-index="index"
              :url="attachment.original_url"
              @delete-attachment="
                () => handleDeleteAttachment('attachable', index)
              "
            />
          </li>
        </template>

        <li
          class="flex items-center justify-between text-sm"
          :class="{
            'py-2 pl-3 pr-4': hasAttachments
          }"
        >
          <div
            class="flex w-0 flex-1 cursor-default items-center text-gray-600"
          >
            <FontAwesomeIcon
              :icon="['fal', 'plus']"
              class="h-3 w-3 flex-shrink-0"
              aria-hidden="true"
            />
            <span class="ml-2 w-0 flex-1 select-none truncate">
              <label class="cursor-pointer font-bold text-sky-600">
                {{ $t('button.add_attachment') }}
                <input
                  ref="fileInput"
                  type="file"
                  class="hidden"
                  @change="onFilePicked"
                />
              </label>
            </span>
          </div>
        </li>
      </ul>
    </dd>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div>
  </div>
</template>

<script>
import EmailAttachment from '@ui/modal/email/components/EmailAttachment'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'EmailAttachmentList',
  components: { FontAwesomeIcon, EmailAttachment },
  props: {
    errors: {
      type: Array,
      required: false,
      default: null
    },
    documentDossier: {
      type: Array,
      required: false,
      default: null
    },
    email: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['deleteDocumentSelected'],
  computed: {
    ...mapGetters({
      defaultAttachments: 'email/getDefaultAttachments'
    }),
    hasAttachments() {
      if (
        this.email.email_attachments &&
        this.email.email_attachments.length > 0
      ) {
        return true
      } else if (
        this.email.new_attachments &&
        this.email.new_attachments.length > 0
      ) {
        return true
      } else if (
        this.email.document_dossier &&
        this.email.document_dossier.length > 0
      ) {
        return true
      } else if (this.email.attachable && this.email.attachable.length > 0) {
        return true
      } else if (!this.email.id) {
        return true
      }

      return false
    }
  },
  methods: {
    handleDeleteAttachment(type, idx) {
      if (this.email) {
        if (!this.email[type]) {
          this.email[type] = []
        }

        if (type === 'new_attachments') {
          this.email.new_attachments.splice(idx, 1)
        } else if (type === 'email_attachments') {
          this.email.email_attachments.splice(idx, 1)
        } else if (type === 'attachable') {
          this.email.attachable.splice(idx, 1)
        }
      }
    },
    onFilePicked(event) {
      if (!this.email?.new_attachments?.length) {
        this.email.new_attachments = []
      }

      this.email.new_attachments.push(...event.target.files)
      this.$refs.fileInput.value = ''
    }
  }
}
</script>
