<template>
  <div
    class="mx-auto mt-12 flex w-1/2 flex-col gap-y-12 text-center text-theme-primary"
  >
    <p class="mt-12 text-5xl capitalize-first">page under construction</p>
    <FontAwesomeIcon :icon="['fal', 'user-hard-hat']" size="10x" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'DossierInProgressComponent',
  components: { FontAwesomeIcon }
}
</script>
