<template>
  <div
    class="flex flex-col gap-1 border border-slate-100 bg-white p-2 text-xs shadow-md"
  >
    <template v-if="items.length">
      <button
        v-for="(item, idx) in items"
        :key="idx"
        class="rounded px-1.5 py-1 text-left hover:bg-primary hover:text-contrast-primary"
        :class="{
          'bg-primary text-contrast-primary': idx === selectedIndex,
          'bg-white text-primary': idx !== selectedIndex
        }"
        @click="selectItem(idx)"
      >
        {{ item.name }}
      </button>
    </template>
    <div v-else class="w-32 rounded px-1.5 py-1 text-left text-primary">
      No result
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedIndex: 0
    }
  },
  mounted() {
    console.log(this.items)
  },
  watch: {
    items() {
      console.log(this.items)
      this.selectedIndex = 0
    }
  },
  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }
      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }
      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }
      return false
    },
    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },
    enterHandler() {
      this.selectItem(this.selectedIndex)
    },
    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ id: item.id, label: item.name })
      }
    }
  }
}
</script>
