<template>
  <div
    v-if="label"
    :class="[
      labelClass
        ? labelClass
        : 'text-xs font-medium text-gray-900 capitalize-first',
      ' select-none capitalize-first '
    ]"
  >
    {{ label }} <span v-if="required" class="capitalize text-red-500">*</span>
  </div>
</template>
<script>
export default {
  name: 'BaseEditLabel',
  props: {
    label: {
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    labelClass: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
