import apiClient from '@u/apiClient'
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async fetchPersonList({ commit }) {
    await apiClient
      .get(`person`)
      .then(async (response) => {
        await commit('setPersonList', response.data.people)
      })
      .catch(async (reason) => {
        await console.error('fetchPersonList', reason)
      })
  },
  fetchNewPerson: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`model/new?model=person`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  searchForAPerson: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`search-person`, {
          params: payload.query
        })
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async searchForAPersonLocal({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`search-person?${payload.query}`)
        .then(async (response) => {
          resolve(response.data.personList)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  FetchPersonById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`person/${payload.person_id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  fetchInvoiceableById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`invoiceable/${payload.invoiceable_id}`, {
          params: {type: payload.invoiceable_type}
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async storePerson({ commit }, payload) {
    await apiClient
      .post(`person`, payload.person)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setPerson', response.data.person)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('storePerson', reason)
      })
  },

  updatePerson: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`person/${payload.person_id}`, payload.person)
        .then((response) => {
          // voir pour supprimer ceci
          if (response.status === 200) {
            commit('setPerson', response.data.person)
            processSuccessCallback(payload)
          }
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
