import apiClient from '@u/apiClient'

export default {
  getSquareUpLocations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`site/${payload.id}/square-up/get-location`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  addSiteToSquareUpLocations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`site/${payload.id}/square-up/add-location`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
