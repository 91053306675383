<template>
  <SelectComponent
    v-if="filteredLanguages.length > 0"
    v-model="selectedValues"
    :values="filteredLanguages"
    :label="$t('attributes.language')"
    :required="required"
    :is-feminine="true"
    name="language"
    :errors="errors"
    :edition-mode="editionMode"
  >
    <template #option="{ selected, option }">
      <span
        class="flex flex-row items-center truncate"
        :class="{ 'font-medium': selected, 'font-normal': !selected }"
      >
        <span class="flex-1">{{ option.name }}</span>
        <span class="shrink-0">
          <img
            class="h-4 w-6 rounded"
            :src="LanguagesFlags[option.id]"
            :alt="option.id"
          />
        </span>

      </span>
    </template>
  </SelectComponent>
</template>

<script>
import {mapGetters} from 'vuex'
import {computed} from 'vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent'
import LanguagesFlags from '@/assets/enums/config/LanguagesFlags'

export default {
  name: 'SelectLanguages',
  components: {SelectComponent},
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    modelValue: {
      type: [Array, Number],
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    customerLanguageOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    customLanguages: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return {selectedValues}
  },
  computed: {
    LanguagesFlags() {
      return LanguagesFlags
    },
    ...mapGetters({
      languages: 'metadata/getLanguagesMetadata',
      available_languages: 'metadata/getAvailableLanguages'
    }),
    filteredLanguages() {
      // if (!this.customerLanguageOnly) {
      //   return this.languages
      // }
      // return this.languages.filter(
      //   (language) =>
      //     language.id === this.modelValue ||
      //     this.available_languages.includes(language.id)
      // )
      const baseLanguages = this.customerLanguageOnly
        ? this.languages.filter(language =>
          language.id === this.modelValue ||
          this.available_languages.includes(language.id)
        )
        : this.languages

      if (this.customLanguages?.length > 0) {
        return [...baseLanguages, ...this.customLanguages]
      }

      return baseLanguages
    }
  }
}
</script>
