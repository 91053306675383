const EventType = Object.freeze({
  // HOPITAL: 'HOP',
  ASHES_RECOVERY: 'ASR',
  BODY_RAISING: 'BDR',
  BURIAL: 'BUR',
  CEREMONY: 'CER',
  COFFIN_ARRIVAL: 'CAR',
  COFFIN_CLOSING: 'COC',
  COFFIN_PLACING: 'COP',
  CONSERVATION: 'CON',
  CREMATION: 'CRE',
  DECLARATION: 'DECL',
  DISPERTION: 'DIS',
  EXHUMATION: 'EXH',
  FORMATION: 'FOR',
  MAINTENANCE: 'MAI',
  MEMORIAL_CEREMONY: 'MEM_CER',
  OFF_DAY: 'OD',
  OTHER: 'OTH',
  RECEPTION: 'REC',
  REUNION: 'REU',
  SPECIAL_EVENT: 'SEV',
  THANATO: 'THAN',
  TRANSFERT: 'TRA',
  VISIT: 'VIS',
  ROOM_OCCUPANCY: 'ROO',
  WAKE: 'WAK',
  APPOINTMENT: 'APPOI'
})

export default EventType
