export default {

  getFilters(state) {
    return state.filters
  },
  getSupplierOrders(state) {
    return state.supplierOrders
  },
  getSupplierOrder(state) {
    return state.supplierOrder
  },
  getSupplierOrderMetadata(state) {
    return state.supplierOrderMetadata
  },

  getSupplierOrderItem(state) {
    return state.supplierOrderItem
  },
  // getSupplierOrderItems(state) {
  //   return state.invoiceItems
  // },
  getSendInvoiceData(state) {
    return state.sendInvoiceData
  },
}
