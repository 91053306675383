<template>
  <div class="box flex h-full flex-col divide-y shadow-md">
    <!--region Header-->
    <div v-if="!city" class="flex shrink-0 items-center p-5">
      <LoadingComponent :display-label="false" />
    </div>
    <template v-else>
      <div class="flex shrink-0 flex-col p-5 pb-2.5 pt-4">
        <div class="flex flex-row">
          <div class="flex flex-1 flex-col">
            <h2 class="text-2xl font-extrabold leading-tight">
              {{ city.name[$i18n.locale] }}
            </h2>
            <h3 class="text-small font-medium">
              {{ `${city.country?.toUpperCase()} ${city?.zip}` }}
            </h3>
          </div>
          <div class="flex shrink-0 flex-col items-center gap-2">
            <button @click="toggleFavoriteFromCity">
              <FontAwesomeIcon
                :icon="['fas', 'star']"
                size="xl"
                :class="[
                  city.is_favorite ? 'text-yellow-300' : 'text-gray-300'
                ]"
              />
            </button>
            <BaseButton
              icon="pen"
              color="info"
              size="extra-small"
              @click="
                $router.push({
                  name: 'edit_wiki_city_information_page_route',
                  params: { city_id: $route.params.city_id }
                })
              "
            />
          </div>
        </div>

        <div
          class="block w-full mt-9 relative"
        >
          <button
            v-if="auth.roles?.includes('super-admin')"
            class="inline-block absolute -top-[24px] right-0 border border-gray-300 px-1 rounded bg-white"
            @click.prevent="$router.push({
              name: 'create_wiki_city_information_page_route_with_parent',
              params: {
                parent_id: $route.params.city_id,
              },
            })"
          >
            <FontAwesomeIcon
              class="text-[12px]"
              :icon="['fas', 'plus']"
            />
          </button>

          <div
            class="cities-children flex flex-col gap-y-3 w-full overflow-y-auto"
            :class="{
              'max-h-[80px]': !citiesChildrenExpend,
              'max-h-[50vh]': citiesChildrenExpend,
              'hidden': !citiesChildren.length,
            }"
          >
            <button
              v-for="childCity in citiesChildren"
              :key="childCity.id"
              :title="childCity.zip + ' ' + childCity.name[$i18n.locale]"
              class="flex w-full items-center justify-between"
              :class="{
                  'cursor-context-menu': !auth?.roles?.includes('super-admin')
                }"
              @click.prevent="editChildCity(childCity)"
            >
              <span>
                {{ childCity.zip }} {{ childCity.name[$i18n.locale] }}
              </span>
            </button>
          </div>

          <div
            class="more-container mt-3 flex items-center justify-center"
            :class="{
              'hidden': !citiesChildren.length,
            }"
          >
            <button
              class="inline-flex border border-gray-300 px-2 rounded"
              @click="citiesChildrenExpend = !citiesChildrenExpend"
            >
              <FontAwesomeIcon
                :icon="['fas', 'ellipsis']"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="mainMenu"
        class="flex flex-1 flex-col gap-1.5 overflow-auto overflow-x-hidden px-4 py-5"
      >
        <template v-for="entry in mainMenu" :key="entry">
          <DossierNavLink
            v-if="entry.gate"
            :to="{ name: entry.routeName, params: { id: cityId } }"
            :icon="entry.icon"
            :label="entry.label"
            :route-name="entry.routeName"
            :sub-routes="entry.subRoutes"
            :disabled="false"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import DossierNavLink from '@/views/dossier/form/sideBar/nav/DossierNavLink.vue'
import { mapActions, mapGetters } from 'vuex'
import LoadingComponent from '@c/TheLoadingComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'TheWikiCityFormNav',
  components: { BaseButton, FontAwesomeIcon, LoadingComponent, DossierNavLink },
  props: {
    cityId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      citiesChildrenExpend: false
    }
  },
  computed: {
    ...mapGetters({
      city: 'wiki/getCity',
      initialMetadata: 'metadata/getInitialMetadata',
      auth: 'auth/getAuth'
    }),
    citiesChildren() {
      return this.city?.children || []
    },
    mainMenu() {
      return [
        {
          label: this.$t('attributes.municipal_administration'),
          routeName: 'wiki_city_municipal_administration_index_page_route',
          subRoutes: [
            'wiki_city_edit_municipal_administration_page_route',
            'wiki_city_edit_municipal_administration_information_page_route',
            'wiki_city_edit_municipal_administration_translation_page_route',
            'wiki_city_edit_municipal_administration_opening_time_page_route',
            'wiki_city_edit_municipal_administration_contact_page_route',
            'wiki_city_edit_municipal_administration_items_page_route',
            'wiki_city_create_municipal_administration_page_route',
            'wiki_city_create_municipal_administration_information_page_route',
            'wiki_city_create_municipal_administration_translation_page_route',
            'wiki_city_create_municipal_administration_opening_time_page_route',
            'wiki_city_create_municipal_administration_contact_page_route',
            'wiki_city_create_municipal_administration_items_page_route'
          ],
          icon: ['fas', 'city'],
          gate: true
        },
        {
          label: this.$t('attributes.cemetery'),
          routeName: 'wiki_city_cemetery_index_page_route',
          subRoutes: [
            'wiki_city_edit_cemetery_page_route',
            'wiki_city_edit_cemetery_information_page_route',
            'wiki_city_edit_cemetery_translation_page_route',
            'wiki_city_edit_cemetery_burial_page_route',
            'wiki_city_edit_cemetery_drone_page_route',
            'wiki_city_edit_cemetery_contact_page_route',
            'wiki_city_edit_cemetery_items_page_route',
            'wiki_city_create_cemetery_page_route',
            'wiki_city_create_cemetery_information_page_route',
            'wiki_city_create_cemetery_translation_page_route',
            'wiki_city_create_cemetery_burial_page_route',
            'wiki_city_create_cemetery_drone_page_route',
            'wiki_city_create_cemetery_contact_page_route',
            'wiki_city_create_cemetery_items_page_route'
          ],
          icon: ['fas', 'tombstone'],
          gate: true
        },
        {
          label: this.$t('attributes.ceremony'),
          routeName: 'wiki_edit_city_ceremony_index_page_route',
          subRoutes: [
            'wiki_city_edit_ceremony_page_route',
            'wiki_city_edit_ceremony_information_page_route',
            'wiki_city_edit_ceremony_translation_page_route',
            'wiki_city_edit_ceremony_contact_page_route',
            'wiki_city_edit_ceremony_items_page_route',
            'wiki_city_create_ceremony_page_route',
            'wiki_city_create_ceremony_information_page_route',
            'wiki_city_create_ceremony_translation_page_route',
            'wiki_city_create_ceremony_contact_page_route',
            'wiki_city_create_ceremony_items_page_route'
          ],
          icon: ['fas', 'church'],
          gate: true
        },
        {
          label: this.$t('attributes.hospital'),
          routeName: 'wiki_edit_city_hospital_index_page_route',
          subRoutes: [
            'wiki_city_edit_hospital_page_route',
            'wiki_city_edit_hospital_information_page_route',
            'wiki_city_edit_hospital_translation_page_route',
            'wiki_city_edit_hospital_contact_page_route',
            'wiki_city_edit_hospital_items_page_route',
            'wiki_city_create_hospital_page_route',
            'wiki_city_create_hospital_information_page_route',
            'wiki_city_create_hospital_translation_page_route',
            'wiki_city_create_hospital_contact_page_route',
            'wiki_city_create_hospital_items_page_route'
          ],
          icon: ['fas', 'tombstone'],
          gate: true
        },
        {
          label: this.$t('attributes.rest_home'),
          routeName: 'wiki_edit_city_rest_home_page_route',
          subRoutes: [
            'wiki_city_edit_rest_home_page_route',
            'wiki_city_edit_rest_home_information_page_route',
            'wiki_city_edit_rest_home_translmation_page_route',
            'wiki_city_edit_rest_home_contact_page_route',
            'wiki_city_edit_rest_home_items_page_route',
            'wiki_city_create_rest_home_page_route',
            'wiki_city_create_rest_home_information_page_route',
            'wiki_city_create_rest_home_translmation_page_route',
            'wiki_city_create_rest_home_contact_page_route',
            'wiki_city_create_rest_home_items_page_route'
          ],
          icon: ['fas', 'house-night'],
          gate: true
        },
        {
          label: this.$t('attributes.fees_and_taxes'),
          routeName: 'wiki_edit_city_fees_and_taxes_page_route',
          subRoutes: [
            'wiki_city_edit_fees_and_taxes_page_route',
            'wiki_city_edit_fees_and_taxes_information_page_route'
          ],
          icon: ['fas', 'euro-sign'],
          gate: true
        },
        {
          label: this.$t('attributes.crematorium'),
          routeName: 'wiki_edit_city_crematorium_page_route',
          subRoutes: [
            'wiki_city_edit_crematorium_page_route',
            'wiki_city_edit_crematorium_information_page_route',
            'wiki_city_edit_crematorium_translation_page_route',
            'wiki_city_edit_crematorium_contact_page_route',
            'wiki_city_edit_crematorium_items_page_route',
            'wiki_city_create_crematorium_page_route',
            'wiki_city_create_crematorium_information_page_route',
            'wiki_city_create_crematorium_translation_page_route',
            'wiki_city_create_crematorium_contact_page_route',
            'wiki_city_create_crematorium_items_page_route'
          ],
          icon: ['fas', 'fireplace'],
          gate: true
        },
        {
          label: this.$t('global.funerarium'),
          routeName: 'wiki_edit_city_funerarium_page_route',
          subRoutes: [
            'wiki_city_edit_funerarium_page_route',
            'wiki_city_edit_funerarium_information_page_route',
            'wiki_city_edit_funerarium_translation_page_route',
            'wiki_city_edit_funerarium_contact_page_route',
            'wiki_city_edit_funerarium_items_page_route',
            'wiki_city_create_funerarium_page_route',
            'wiki_city_create_funerarium_information_page_route',
            'wiki_city_create_funerarium_translation_page_route',
            'wiki_city_create_funerarium_contact_page_route',
            'wiki_city_create_funerarium_items_page_route'
          ],
          icon: ['fas', 'hotel'],
          gate: true
        },
        {
          label: this.$t('global.documents'),
          routeName: 'wiki_edit_city_documents_page_route',
          icon: ['fas', 'file-lines'],
          gate: true
        },
        {
          label: this.$t('global.contacts'),
          routeName: 'wiki_edit_city_contacts_page_route',
          subRoutes: [
            'wiki_city_edit_contact_page_route',
            'wiki_city_edit_contact_information_page_route',
            'wiki_city_create_contact_page_route',
            'wiki_city_create_contact_information_page_route'
          ],
          icon: ['fas', 'users'],
          gate: true
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      processChangeFavoriteCityAction: 'wiki/changeFavoriteCity',
      processFetchMetadataAction: 'metadata/fetchAllMetadata'
    }),
    toggleFavoriteFromCity() {
      this.processChangeFavoriteCityAction({
        city_id: this.city.id,
        is_favorite: !this.city.is_favorite
      }).then((response) => {
        this.$h.toastSuccess(this.$t('toasts.updated_favorite_status_city'))
        this.city.is_favorite = !this.city.is_favorite
        this.processFetchMetadataAction()
      })
    },
    editChildCity(childCity) {
      if (this.auth.roles.includes('super-admin')) {
        this.$router.push({
          name: 'edit_wiki_city_information_page_route',
          params: {
            city_id: childCity.id,
            parent_id: childCity.parent_id
          }
        })
      }
    }
  }
}
</script>
