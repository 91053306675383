import apiClient from '@u/apiClient'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region NEW DASHBOARD

  async fetchTemplateDashboardById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`template-dashboard/${payload.id}`)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async fetchTemplateDashboardElementById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`template-dashboard/${payload.id}/${payload.element_id}`)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async subscribeToTemplateDashboard({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .post(`template-dashboard/subscribe`, payload)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async unsubscribeToTemplateDashboard({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .post(`template-dashboard/unsubscribe`, payload)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async fetchTemplateDashboardPublic() {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`template-dashboard/public`)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async createTemplateDashboard({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .post(`template-dashboard`, payload.data)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async duplicateTemplateDashboard({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .post(`template-dashboard/duplicate`, payload.data)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async updateTemplateDashboardById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .put(`template-dashboard/${payload.id}`, payload.data)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async updateTemplateDashboardElementById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .put(
          `template-dashboard/${payload.id}/${payload.element.id}`,
          payload.element
        )
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async updateTemplateDashboardUserById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .put(`/template-dashboard-user/${payload.id}`, payload.data)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async addTemplateDashboardElementById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .post(
          `template-dashboard/${payload.id}/${payload.rowId}/add`,
          payload.element
        )
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async updateTemplateDashboardUserPosition({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .post(`/template-dashboard-user/update-position`, payload)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  // deprecated
  async updateColumnDashboard({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .put(`dashboard/column/${payload.id}`, payload.data)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async setFieldToColumnDashboard({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .put(`template-dashboard-set-field/${payload.id}`, payload.data)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async fetchListDossier({ commit }, payload) {
    console.log('fetchListDossier')

    return apiClient
      .post('dossier-list', payload)
      .then(async (response) => response)
  },

  fetchListInvoicingDashboard: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`invoicing-dashboard`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // endregion

  // region fetch
  async fetchDashboard({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`dashboard/template/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  /*async fetchContactList({ commit }) {
     await apiClient
     .get(`api/contact`)
     .then(async (response) => {
     await commit('setContactList', response.data.contacts)
     })
     .catch(async (reason) => {
     await console.error('fetchContactList', reason)
     })
     },

     async fetchRoleContactList({ commit }, payload) {
     await apiClient
     .get(`api/contact?role=${payload.id}`)
     .then(async (response) => {
     commit('setRoleContactList', response.data.contacts)
     commit('setContactMetadata', response.data.metadata)
     })
     .catch(async (reason) => {
     console.error('fetchContactList', reason)
     })
     },

     async fetchNewContact({ commit }, predefinedRole) {
     commit('setContact', {})
     commit('auth/setErrors', baseErrors, { root: true })
     await apiClient
     .get(`api/model/new?model=contact`)
     .then(async (response) => {
     await console.log('fetchNewContact', response)

     if (predefinedRole) {
     let temp = response.data.contact
     temp.role = predefinedRole
     await commit('setContact', temp)
     } else {
     await commit('setContact', response.data.contact)
     }
     })
     .catch(async (reason) => {
     await console.error('fetchNewContact', reason)
     })
     },*/
  // endregion

  // region store
  async storeNewColumn({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .post('dashboard/column', payload)
      .then(async (response) => {
        if (response.status === 200) {
          // processSuccessCallback(payload)
          await commit(
            'setCurrentTemplateColumnList',
            response.data.template_column_list
          )
          await commit('setFullColumnList', response.data.full_column_list)
        }
      })
      .catch((reason) => {
        console.error('error storeNewColumn', reason)
      })
  },
  // endregion

  // region update
  async updateColumn({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .put(`dashboard/column/${payload.id}`, payload.column)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          await commit(
            'setCurrentTemplateColumnList',
            response.data.template_column_list
          )
          await commit('setFullColumnList', response.data.full_column_list)
        }
      })
      .catch((reason) => {
        console.error('updateContact', reason)
      })
  },

  async updateTemplate({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .put(`dashboard/template/${payload.template.id}`, payload.template)
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          commit('setTemplateList', response.data.template_list)
        }
      })
      .catch((reason) => {
        console.error('updateContact', reason)
      })
  },

  async createTemplate({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .post(`dashboard/new-template`, payload.template)
      .then((response) => {
        if (response.status == 200) {
          console.log('response', response.data)
          // processSuccessCallback(payload)
          commit('setTemplateList', response.data.template_list)
          commit('setFullColumnList', response.data.column_list)
        }
      })
      .catch((reason) => {
        console.error('updateTemplate', reason)
      })
  },
  async updateTemplateName({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .post(`dashboard/update-template-name`, payload)
      .then((response) => {
        if (response.status === 200) {
          // processSuccessCallback(payload)
          commit('setTemplateList', response.data.template_list)
        }
      })
      .catch((reason) => {
        console.error('updateTemplate', reason)
      })
  },

  // endregion

  // region delete
  async deleteColumn({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .delete(`dashboard/column-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          await commit(
            'setCurrentTemplateColumnList',
            response.data.template_column_list
          )
          await commit('setFullColumnList', response.data.full_column_list)
        }
      })
      .catch((reason) => {
        console.error('updateContact', reason)
      })
  },

  deleteTemplateDashboardById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`template-dashboard/${payload.id}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  undoDeleteTemplateDashboardById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`template-dashboard/undo-delete/${payload.id}`)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
