<template>
  <div class="grid w-full grid-cols-3 gap-2" :class="$attrs.class">
    <div class="col-span-3">
      <BaseShowEditInput
        v-model="localStreet"
        class="col-start-1"
        :label="$t('attributes.road')"
        :errors="errors?.street"
        name="street"
        :edition-mode="editionMode"
      />
    </div>
    <div v-if="!disableExtraStreet" class="col-span-3">
      <BaseShowEditInput
        v-model="localExtraStreet"
        :label="$t('attributes.extra_street')"
        :errors="errors?.extra_street"
        name="extra_street"
        :edition-mode="editionMode"
      />
    </div>
    <template
      v-if="
        disableWikiCity ||
        ![Country.BELGIUM, Country.FRANCE, Country.LUXEMBOURG].includes(
          localCountry
        )
      "
    >
      <div class="col-span-1">
        <BaseShowEditInput
          v-model="localZip"
          :label="$t('attributes.zip')"
          :errors="errors?.zip"
          name="zip"
          :edition-mode="editionMode"
        />
      </div>
      <div class="col-span-2">
        <BaseShowEditInput
          v-model="localCity"
          :label="$t('attributes.city')"
          :errors="errors?.city"
          name="city"
          :edition-mode="editionMode"
        />
      </div>
    </template>
    <div v-else class="col-span-3">
      <SelectCity
        v-model:cityId="localCityId"
        v-model:zip="localZip"
        v-model:city="localCity"
        v-model:city-object="localCurrentCityObject"
        :country="localCountry"
        name="city_id"
        :errors="errors?.city_id"
        :edition-mode="editionMode"
        :required="requiredCity"
      />
    </div>
    <div class="col-span-3">
      <SelectCountries
        v-model="localCountry"
        :edition-mode="editionMode"
        required
      />
    </div>
  </div>
</template>

<script>
import SelectCountries from '@c/InitialStateFormComponent/Enums/SelectCountries.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import { Country } from '@/assets/enums'
import { mapGetters } from 'vuex'
import SelectCity from '@c/SelectAjaxComponent/SelectCity.vue'

export default {
  name: 'BaseShowEditAddress',
  components: {
    SelectCity,
    BaseShowEditInput,
    SelectCountries
  },
  props: {
    street: {
      type: String,
      required: true
    },
    extraStreet: {
      type: String,
      required: false,
      default: null
    },
    city: {
      type: String,
      required: true
    },
    zip: {
      type: String,
      required: true
    },
    cityId: {
      type: String,
      required: false,
      default: null
    },
    currentCityObject: {
      type: Object,
      required: false,
      default: null
    },
    country: {
      type: String,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    requiredCity: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
      required: false,
      default: () => {}
    },
    name: {
      type: String,
      required: false,
      default: 'address'
    },
    disableWikiCity: {
      type: Boolean,
      required: false,
      default: false
    },
    disableExtraStreet: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'update:street',
    'update:extraStreet',
    'update:zip',
    'update:city',
    'update:cityId',
    'update:country',
    'update:currentCityObject'
  ],
  computed: {
    ...mapGetters({
      default_country: 'metadata/getDefaultCountry'
    }),
    defaultAjax() {
      if (
        this.currentCityObject &&
        this.currentCityObject.id &&
        this.currentCityObject.name
      ) {
        return this.currentCityObject
      }
      return {}
    },
    Country() {
      return Country
    },
    localStreet: {
      get() {
        return this.street
      },
      set(value) {
        this.$emit('update:street', value)
      }
    },
    localExtraStreet: {
      get() {
        return this.extraStreet
      },
      set(value) {
        this.$emit('update:extraStreet', value)
      }
    },
    localZip: {
      get() {
        return this.zip
      },
      set(value) {
        this.$emit('update:zip', value)
      }
    },
    localCity: {
      get() {
        return this.city
      },
      set(value) {
        this.$emit('update:city', value)
      }
    },
    localCityId: {
      get() {
        return this.cityId
      },
      set(value) {
        this.$emit('update:cityId', value)
      }
    },
    localCountry: {
      get() {
        return this.country
      },
      set(value) {
        this.$emit('update:country', value)
      }
    },
    localCurrentCityObject: {
      get() {
        return this.currentCityObject
      },
      set(value) {
        this.$emit('update:currentCityObject', value)
      }
    }
  },
  mounted() {
    if (
      !this.country ||
      this.country.length === 0 ||
      !Object.values(Country).includes(this.country)
    ) {
      this.localCountry = this.default_country
    }
  }
}
</script>

<style scoped></style>
