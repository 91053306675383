import apiClient from '@u/apiClient'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    if ('new_id' in payload) {
      payload.successCallback(payload.new_id)
    } else {
      payload.successCallback()
    }
  }
}

export default {
  // region fetch

  fetchAutomationList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`automation`, { params: { model: payload.model } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchModelConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/automation/config-model`, {
          params: {
            model_id: payload.model_id,
            model_type: payload.model_type
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateModelConfig({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`/automation/config-model`, {
          model_id: payload.model_id,
          model_type: payload.model_type,
          automation_id: payload.automation_id,
          event: payload.event
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewAutomation({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=automation')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchAutomationById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`automation/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  storeNewAutomation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post('automation', payload.automation)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateAutomation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`automation/${payload.id}`, payload.automation)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  duplicateAutomationById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`automation/${payload.id}/duplicate`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateAutomationStatus({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`automation/${payload.id}/update-status`, {
          active: payload.active
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  deleteAutomationById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`automation/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undoDelete
  undoDeleteAutomationById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`automation/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
