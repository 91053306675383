<template>
  <div v-if="errorKey" class="form-help-container">
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ errorText(error) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorContainer',
  props: {
    errors: {
      type: Object,
      required: true
    },
    errorKey: {
      type: String,
      required: true
    }
  },
  onErrorCaptured(err) {
    return false
  },
  methods: {
    errorText(errors) {
      let text = Array.isArray(errors) ? errors.join(',') : errors

      if (text?.includes('attributes.*')) {
        const replacement = ' ' + this.$t(`attributes.${this.errorKey}`)
        text = text.replace('attributes.*', replacement)
      }
      if (text?.includes('empty')) {
        text = text.replace('empty', this.$t('attributes.empty'))
      }

      return text
    }
  }
}
</script>
