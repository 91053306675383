<template>
  <Popover v-slot="{ open, close }" class="relative z-100">
    <PopoverButton
      class="flex h-10 w-10 items-center justify-center rounded-full border border-slate-300 bg-primary text-contrast-primary active:scale-95 active:bg-primary-400 dark:bg-primary-700 md:flex md:w-auto md:flex-row md:gap-2 md:pl-3 md:pr-4"
    >
      <font-awesome-icon :icon="['fal', 'plus']" size="lg" />
      <span
        class="hidden truncate text-center text-sm font-medium capitalize md:inline"
      >
        {{ $t('button.add') }}
      </span>
    </PopoverButton>
    <Transition
      enter-active-class="transform duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transform duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel class="absolute left-0 z-100 mt-3 transform px-4 sm:px-0">
        <div class="overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
          <div
            class="flex flex-col gap-3 rounded-lg bg-white p-5 pt-4 dark:border dark:border-white dark:bg-slate-800 dark:text-white"
          >
            <h2 class="text-xl font-bold capitalize-first">
              {{ $t('button.add') }}
            </h2>
            <div
              v-for="(items, idx) in solutions"
              :key="idx"
              class="flex flex-1 flex-row gap-3"
            >
              <div v-for="item in items" :key="item.name">
                <div
                  v-if="item.gate"
                  @click="
                    () => {
                      $router.push(item.to)
                      close()
                    }
                  "
                  class="flex w-24 cursor-pointer flex-col items-center rounded-md bg-primary-100 p-2 text-gray-700 focus:outline-none active:scale-95 active:bg-primary-400"
                >
                  <div
                    class="flex h-8 w-8 shrink-0 items-center justify-center sm:h-8 sm:w-8"
                  >
                    <FontAwesomeIcon :icon="['fal', item.icon]" size="2xl" />
                  </div>
                  <p
                    class="w-full truncate text-center text-sm font-medium capitalize"
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel
} from '@headlessui/vue'
import { mapGetters } from 'vuex'
import { Country } from '@/assets/enums'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FontAwesomeIcon,
    Popover,
    PopoverPanel,
    PopoverButton,
    PopoverOverlay
  },
  name: 'QuickAdd',
  data() {
    return {
      Country: Country
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata'
    }),
    solutions() {
      return [
        [
          {
            name: this.$t('global.dossier'),
            description: 'lorem ipsum',
            to: { name: 'dossier_create_route' },
            icon: 'user',
            gate: this.$can('manage_dossier')
          }
        ],
        [
          {
            name: this.$t('global.invoicing_type.INV'),
            description: 'lorem ipsum',
            to: { name: 'invoice_wizard_route' },
            icon: 'file-invoice',
            gate: this.$can('manage_invoicing')
          },
          {
            name: this.$t('global.invoicing_type.SUPORD'),
            description: 'lorem ipsum',
            to: { name: 'purchase_order_wizard_route' },
            icon: 'file-invoice',
            gate: this.$can('manage_invoicing')
          },
          {
            name: this.$t('global.invoicing_type.QUO'),
            description: 'lorem ipsum',
            to: { name: 'quote_wizard_route' },
            icon: 'file-invoice',
            gate: this.$can('manage_invoicing')
          },
          {
            name: this.$t('global.invoicing_type.CN'),
            description: 'lorem ipsum',
            to: { name: 'credit_note_wizard_route' },
            icon: 'file-invoice',
            gate: this.$can('manage_invoicing')
          }
        ],
        [
          {
            name: this.$t('global.email'),
            description: 'lorem ipsum',
            to: { name: 'mail_box_create_route' },
            icon: 'envelope',
            gate: this.$can('see_content')
          }
        ]
      ]
    }
  }
}
</script>
