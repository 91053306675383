<template>
  <div class="flex flex-col overflow-hidden">
    <div class="flex flex-col gap-1">
      <h3 class="text-base font-semibold">{{ funeralHome.name }}</h3>
      <div>
        <PricingTableByFuneralHome
          :price_htva="selectedPricing.default_price_htva"
          :price_tvac="selectedPricing.default_price_tvac"
          :price_encoding_type="price_encoding_type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PricingTableByFuneralHome from '@/views/catalog/components/PricingTableByFuneralHome.vue'

export default {
  name: 'PricingTable',
  components: { PricingTableByFuneralHome },
  props: {
    item: {
      type: Object,
      required: true
    },
    selectedFuneralHome: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      funeralHomes: 'metadata/getFuneralHomesMetadata'
    }),
    price_encoding_type() {
      return this.metadata.settings.price_encoding_type
    },
    funeralHome() {
      return this.funeralHomes.find(
        (funeralHome) => funeralHome.id === this.selectedFuneralHome
      )
    },
    selectedPricing() {
      let specific = this.item.specifics.find(
        (specific) => specific.funeralHomeId === this.selectedFuneralHome
      )
      return {
        ...this.item,
        default_price_htva:
          specific?.specific_price_htva ?? this.item.default_price_htva,
        default_price_tvac:
          specific?.specific_price_tvac ?? this.item.default_price_tvac,
        id: this.item.id
      }
    }
  }
}
</script>
