const TheAccountingCodeIndexPage = () =>
  import(
    '@/views/settings/invoicing/accountingCode/TheAccountingCodeIndexPage.vue'
  )

const TheAccountingCodeFormPage = () =>
  import(
    '@/views/settings/invoicing/accountingCode/TheAccountingCodeFormPage.vue'
  )

export const settingsAccountingCodeRoutes = [
  {
    path: 'accounting-code',
    children: [
      {
        path: '',
        name: 'settings_accounting_codes_route',
        component: TheAccountingCodeIndexPage
      },
      {
        path: ':accountingCodeId',
        name: 'settings_accounting_code_route',
        component: TheAccountingCodeFormPage,
        props: true
      },
      {
        path: 'new',
        name: 'settings_new_accounting_code_route',
        component: TheAccountingCodeFormPage,
        props: true
      }
    ]
  }
]
