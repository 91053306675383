<template>
  <Combobox
    v-model="localValueObject"
    :disabled="disabled"
    multiple
  >
    <div
      class="relative rounded-md border border-slate-300 px-3 py-2 shadow-sm"
      :class="{
          'mt-2': !!label?.length,
          'pointer-events-none': disabled,
          'bg-gray-100': disabled,
        }"
    >
      <label
        v-if="label"
        for="name"
        class="absolute -top-2 left-2 z-10 -mt-px inline-block rounded-md bg-white/75 px-1 text-xs font-medium text-gray-900 capitalize-first"
      >
        {{ label }}
        <span v-if="required" class="capitalize text-red-500">*</span>
      </label>
      <div class="flex w-full flex-row">
        <ul class="mr-1 w-full flex flex-wrap gap-1 pr-3">
          <li
            v-for="(value, idx) in localValueObject"
            :key="value.id"
            class="inline-flex flex-row items-center gap-1 rounded bg-primary-300 px-1 text-white whitespace-nowrap"
          >
            <span class="shrink-0">
              <slot name="pill" :value="value">
                {{ value[attributeLabel] }}
              </slot>
            </span>
            <button class="inline-flex items-center" @click="remove(idx)">
              <FontAwesomeIcon :icon="['far', 'times']" size="sm"/>
            </button>
          </li>
          <li
            class="inline-flex w-full"
          >
            <ComboboxInput
              class="
                w-full border-0 p-0 focus:border-0 focus:ring-0 -my-0.5 bg-transparent++
              "
              :placeholder="$t('global.search')"
              @change="query = $event.target.value"
            />
          </li>
        </ul>
        <div
          v-if="requesting"
          class="absolute inset-y-0 right-8 flex items-center"
        >
          <FontAwesomeIcon
            :icon="['fal', 'spinner-third']"
            class="animate-spin"
          />
        </div>
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <FontAwesomeIcon :icon="['fal', 'chevron-down']"/>
        </ComboboxButton>
      </div>
      <TransitionRoot
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ComboboxOptions
          class="absolute z-50 -mx-3 mt-2.5 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ComboboxOption
            v-if="allowTags && queryValue && queryValue.name.length"
            v-slot="{ active }"
            :value="queryValue"
          >
            <li
              class="relative cursor-default select-none py-2 pl-8 pr-4"
              :class="{
                'bg-primary-300 text-white': active,
                'text-gray-900': !active
              }"
            >
              <span class="block truncate font-normal">
                {{ $t('button.add') }} "{{ query }}"
              </span>
            </li>
          </ComboboxOption>
          <template v-if="options === null">
            <div class="relative cursor-default select-none px-4 py-2">
              {{ $t('global.start_typing') }}
            </div>
          </template>
          <template v-else-if="options.length === 0">
            <div class="relative cursor-default select-none px-4 py-2">
              {{ $t('global.no_result_found') }}
            </div>
          </template>
          <ComboboxOption
            v-for="option in options"
            v-else
            :key="option.id"
            v-slot="{ selected, active, disabled }"
            as="template"
            :value="option"
            :disabled="
              validityAttribute && option.hasOwnProperty(validityAttribute)
                ? option[validityAttribute] === false
                : false
            "
          >
            <li
              class="relative cursor-default select-none py-2 pl-8 pr-4"
              :class="{
                'bg-gray-200 text-gray-700': disabled,
                'bg-primary-300 text-white': active,
                'text-gray-900': !active
              }"
            >
              <span
                class="block truncate"
                :class="{ 'font-medium': selected, 'font-normal': !selected }"
              >
                <slot name="option" :option="option">
                  {{ option[attributeLabel] }}
                </slot>
              </span>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3"
                :class="{
                  'text-white': active,
                  'text-black': !active
                }"
              >
                <FontAwesomeIcon :icon="['fas', 'check']"/>
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script>
import {defineComponent} from 'vue'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot
} from '@headlessui/vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import apiClient from '@u/apiClient'
import {debounce} from '@u/debounce'

export default defineComponent({
  name: 'SelectMultipleAjax',
  components: {
    ComboboxButton,
    FontAwesomeIcon,
    TransitionRoot,
    ComboboxOption,
    ComboboxOptions,
    ComboboxInput,
    Combobox
  },
  props: {
    values: {
      type: Array,
      required: true,
      default: () => []
    },
    valueObject: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    allowTags: {
      type: Boolean,
      required: false,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    regexTag: {
      type: String,
      required: false,
      default: '.*'
    },
    ajaxExtraParams: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    validityAttribute: {
      type: String,
      required: false,
      default: null
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:values', 'update:valueObject', 'change'],
  data() {
    return {
      requesting: false,
      query: '',
      options: null
    }
  },
  computed: {
    localValues: {
      get() {
        return this.values
      },
      set(value) {
        this.$emit('update:values', value)
      }
    },
    localValueObject: {
      get() {
        return this.valueObject
      },
      set(value) {
        this.localValues = value.map((item) => item[this.trackBy])
        this.$emit('update:valueObject', value)
        this.$emit('change', value)
      }
    },
    queryValue() {
      if (this.allowTags && !!this.query && this.query.length) {
        let re = new RegExp(this.regexTag, 'g')
        if (this.query.match(re)) {
          return {id: null, name: this.query}
        }
      }
      return null
    }
  },
  watch: {
    query(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.debouncedSearch(newValue)
      }
    }
  },
  created() {
    this.debouncedSearch = debounce((terms) => {
      if (terms && terms.length >= 2) {
        this.search(terms)
      }
    }, 1000)
  },
  methods: {
    remove(index) {
      this.localValues.splice(index, 1)
    },
    search(terms) {
      this.requesting = true
      apiClient
        .get(this.url, {
          params: {
            terms: terms,
            ...this.ajaxExtraParams
          }
        })
        .then(async (response) => {
          this.options = response.data
          this.requesting = false
        })
        .catch(async (reason) => {
          this.requesting = false
          alert(reason)
        })
    }
  }
})
</script>
