const TheElectronicSignaturePage = () =>
  import('@/views/electronicSignature/ElectronicSignaturePage.vue')

const TheElectronicSignatureIndexPage = () =>
  import('@/views/electronicSignature/index/ElectronicSignatureIndex.vue')

const TheElectronicSignatureViewPage = () =>
  import('@/views/electronicSignature/form/ElectronicSignatureView.vue')

export const electronicSignatureRootPages = [
  {
    path: 'electronic-signature',
    name: 'electronic_signature_root_route',
    to: { name: 'electronic_signature_index_route' },
    component: TheElectronicSignaturePage,
    props: true,
    children: [
      {
        path: '',
        name: 'electronic_signature_index_route',
        redirect: {
          name: 'electronic_signature_index_route_by_status',
          params: { status: 'not-sent' }
        },
        props: true
      },
      {
        path: 'status/:status(not-sent|draft|ongoing|done|deleted|expired|canceled|approval|rejected)',
        props: true,
        children: [
          {
            path: '',
            name: 'electronic_signature_index_route_by_status',
            component: TheElectronicSignatureIndexPage,
            props: true
          },
          {
            path: ':electronicSignatureId',
            name: 'electronic_signature_view_route',
            component: TheElectronicSignatureViewPage,
            props: true
          }
        ]
      }
    ]
  }
]
