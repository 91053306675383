<template>
  <router-link
    v-if="!disabled"
    :to="{ name: routeName }"
    exact-active-class="bg-blue-50 border-theme-secondary"
    class="group flex flex items-center space-x-2 border-l-4 border-transparent px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
  >
    <FontAwesomeIcon :icon="['fal', icon]" fixed-width />&nbsp;
    <span class="cursor-pointer text-sm capitalize-first">{{ label }}</span>
  </router-link>

  <span
    v-else
    class="group flex flex items-center space-x-2 border-l-4 border-transparent px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
  >
    <FontAwesomeIcon :icon="['fal', icon]" fixed-width />&nbsp;
    <span class="cursor-pointer text-sm capitalize-first">{{ label }}</span>
  </span>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'SettingsNavLinkComponent',
  components: { FontAwesomeIcon },
  props: {
    routeName: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
