import axios from 'axios'
import { helper } from '@u/helper'

const ApiPort = helper.getDevPort()
const ApiUri = '/api/'

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? `http://${window.location.hostname}:${ApiPort}${ApiUri}`
    : ApiUri

const apiClientScreen = axios.create({
  baseURL
})

apiClientScreen.interceptors.request.use(
  (config) => {
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('expiration') &&
      new Date() > new Date(localStorage.getItem('expiration'))
    ) {
      localStorage.removeItem('token')
      localStorage.removeItem('expiration')
      window.location.href = '/connect'
    }
    if (localStorage.getItem('token')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClientScreen.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      let response_data = error.response.data
      switch (error.response.status) {
        case 401:
          window.location.href = '/screen/login'
          break
        default:
          return Promise.reject(error)
      }
    } else {
      helper.toastDanger('erreur serveur')
      return Promise.reject(error)
    }
  }
)

export default apiClientScreen
