import apiClient from '@u/apiClient'
import QuestionActions from './question/actions'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    if ('new_id' in payload) {
      payload.successCallback(payload.new_id)
    } else {
      payload.successCallback()
    }
  }
}

export default {
  ...QuestionActions,
  // region fetch

  fetchSurveyList({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get('surveys')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewSurvey({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=survey')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchSurveyById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`survey/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  storeNewSurvey: ({ commit }, payload) => {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`/survey`, payload.survey)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateSurvey: ({ commit }, payload) => {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise(async (resolve, reject) => {
      apiClient
        .put(`/survey/${payload.id}`, payload.survey)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  deleteSurveyById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`/survey/survey-delete/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  undoDeleteSurveyById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`/survey/undo-delete/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  setDataTransferObject({ commit }, payload) {
    commit('setDataTransferObject', payload)
  },
  setDataSectionObject({ commit }, payload) {
    commit('setDataSectionObject', payload)
  }
}
