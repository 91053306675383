<template>
  <div v-if="item" class="flex h-full flex-col">
    <div class="flex shrink-0 flex-row">
      <h1 class="flex-1 text-2xl font-bold">
        {{ item.name[$i18n.locale] }}
      </h1>
    </div>
    <div class="flex min-w-0 flex-1 flex-row gap-6">
      <div class="w-2/3">
        <div class="relative flex h-full w-full">
          <img
            v-if="masterImageUrl && imgAvailable"
            :src="masterImageUrl"
            class="absolute inset-0 object-contain"
            :alt="item.name"
            @error="imgAvailable = false"
          />
          <div v-else class="absolute inset-0 object-contain">
            <div
              class="flex aspect-video w-full items-center justify-center rounded-xl bg-gray-100 dark:border dark:border-white dark:bg-slate-700 dark:text-white"
            >
              <FontAwesomeIcon
                :icon="['fas', 'image']"
                class="text-8xl text-gray-300"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/3">
        <div class="flex shrink-0 flex-col gap-2">
          <div class="flex">
            <RouterLink
              v-for="subRoute in subRoutes"
              :key="subRoute.label"
              v-slot="{ isActive }"
              class="flex-1"
              :to="subRoute.route"
            >
              <div
                class="border-b-2 px-2 py-1 text-center font-bold capitalize-first"
                :class="
                  isActive
                    ? 'border-primary text-primary'
                    : 'border-transparent'
                "
              >
                {{ subRoute.label }}
              </div>
            </RouterLink>
          </div>
          <div class="flex-1">
            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="-translate-x-1 opacity-0"
              enter-to-class="translate-x-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-x-0 opacity-100"
              leave-to-class="translate-x-1 opacity-0"
              mode="out-in"
            >
              <PricingTable
                v-if="section === 'price'"
                :item="item"
                :metadata="metadata"
                :selected-funeral-home="selectedFuneralHome"
              />
              <div v-else>TEST</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.galery_images" class="flex shrink-0 flex-row gap-3 p-3">
      <img
        v-for="media in item.galery_images"
        :key="media.id"
        class="aspect-square w-24 rounded border object-cover p-1"
        :alt="media.name"
        :src="media.original_url"
        @mouseover="hoveredImageUrl = media.original_url"
        @mouseleave="hoveredImageUrl = null"
      />
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions } from 'vuex'
import PricingTable from '@/views/catalog/components/PricingTable.vue'

export default {
  name: 'SelectedItemCatalogDetailsComponent',
  components: { PricingTable, FontAwesomeIcon },
  props: {
    selectedItem: {
      type: Object,
      required: true
    },
    section: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    selectedFuneralHome: {
      type: String,
      required: true
    }
  },
  emits: ['remove-select-item', 'update:category'],
  data() {
    return {
      imgAvailable: true,
      itemLocal: null,
      stock: null,
      hoveredImageUrl: null
    }
  },
  computed: {
    localCategory: {
      get() {
        return this.category
      },
      set(value) {
        this.$emit('update:category', value)
      }
    },
    item() {
      return this.itemLocal ?? this.selectedItem ?? null
    },
    masterImageUrl() {
      return this.hoveredImageUrl ?? this.item?.master_image_url ?? null
    },
    subRoutes() {
      return [
        {
          label: this.$t('attributes.price'),
          route: {
            name: 'catalog_item_page_route',
            params: {
              itemId: this.item.id
            }
          }
        },
        {
          label: this.$t('global.stock'),
          route: {
            name: 'catalog_item_stock_route',
            params: {
              itemId: this.item.id
            }
          }
        }
      ]
    }
  },
  watch: {
    selectedItem(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getItemForCatalog()
      }
    }
  },
  mounted() {
    this.getItemForCatalog()
  },
  methods: {
    ...mapActions({
      processGetItemForCatalogAction: 'item/getItemForCatalog'
    }),
    getItemForCatalog() {
      this.itemLocal = null
      this.imgAvailable = true
      if (this.selectedItem) {
        this.processGetItemForCatalogAction({
          item_id: this.selectedItem.id
        }).then((response) => {
          this.itemLocal = response.item
          this.stock = response.stock
        })
      }
    }
  }
}
</script>
