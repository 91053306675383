<template>
  <div class="">
    <a
      href=""
      class="flex items-center gap-2 rounded-md p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-slate-400"
      @click.prevent="switchMode"
    >
      <p v-if="!darkModeIconSwitcher">
        <FontAwesomeIcon :icon="['fal', 'moon']" fixed-width />
        Dark mode
      </p>
      <p v-else>
        <FontAwesomeIcon fixed-width :icon="['fal', 'sun']" />
        Light mode
      </p>
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import cash from 'cash-dom/dist/cash'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'TheDarkModeSwitcher2',
  components: { FontAwesomeIcon },
  computed: {
    ...mapGetters('darkMode', ['darkMode']),

    darkModeIconSwitcher() {
      return this.darkMode
    }
  },
  methods: {
    ...mapActions('darkMode', ['setDarkMode']),
    setDarkModeClass() {
      this.darkMode
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    },
    // setDarkModeClass() {
    //   this.darkMode
    //     ? cash('html').addClass('dark')
    //     : cash('html').removeClass('dark')
    // },
    switchMode() {
      this.setDarkMode(!this.darkMode)
      this.setDarkModeClass()
    }
  },
  mounted() {
    this.setDarkModeClass()
  }
}
</script>
