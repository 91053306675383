import apiClient from '@u/apiClient'

export default {
  getSummary() {
    return new Promise((resolve, reject) => {
      apiClient
        .get('stock/summary')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getStockByLocation({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`location/${payload.location_id}/stock`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getStockableItem() {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`item/stock`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getItem({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`stock/item/${payload.item_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getItemStock({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`stock/item-stock/${payload.item_stock_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updateStockItemLocation({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`stock/item-stock/${payload.item_stock_id}/set-location`, {
          location_id: payload.location_id
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  printLabelItemStock({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`stock/item/${payload.item_id}/print-label`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  setDataTransferObject({ commit }, payload) {
    commit('setDataTransferObject', payload)
  }
}
