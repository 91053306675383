import store from '@/store'
import DashboardTypes from '@/assets/enums/dashboard/DashboardTypes'

const TheDossierIndexPage = () =>
  import('@/views/dossier/index/TheDossierIndexPage.vue')

const TheTemplateDashboardIndexPage = () =>
  import('@/views/templateDashboard/TheTemplateDashboardIndexPage')

export const templateDashboardSubPages = [
  {
    path: '/dashboard',
    children: [
      {
        path: '',
        name: 'dashboard_main_page_route',
        component: TheTemplateDashboardIndexPage,
        meta: {
          route_name: 'dashboard_page_route'
        },
        children: [
          {
            path: '',
            beforeEnter: (from, to, next) => {
              let template = store.getters['metadata/getTemplateDashboards']
              if (Array.isArray(template) && template[0]) {
                next({
                  name: 'dashboard_page_route',
                  params: { id: template[0].id }
                })
                return
              }
              next()
              return
            }
          },
          {
            path: ':id',
            name: 'dashboard_page_route',
            component: TheTemplateDashboardIndexPage,
            meta: {
              route_name: 'dashboard_page_route'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/dashboard-dossier',
    children: [
      {
        path: '',
        name: 'dashboard_dossier_main_page_route',
        component: TheDossierIndexPage,
        meta: {
          route_name: 'dashboard_dossier_page_route'
        },
        children: [
          {
            path: '',
            beforeEnter: (from, to, next) => {
              let template = store.getters[
                'metadata/getTemplateDashboardByType'
              ](DashboardTypes.DASHBOARD)
              if (Array.isArray(template) && template[0]) {
                next({
                  name: 'dashboard_dossier_page_route',
                  params: { id: template[0].id }
                })
                return
              }
              next()
            }
          },
          {
            path: ':id',
            name: 'dashboard_dossier_page_route',
            component: TheDossierIndexPage,
            meta: {
              route_name: 'dashboard_dossier_page_route'
            }
          }
        ]
      }
    ]
  }
]
