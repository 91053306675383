<template>
  <template v-if="price_encoding_type === 'TVAC'">
    <div class="flex gap-2">
      <span class="font-bold">
        {{ $h.formatCurrency(price_tvac) }}
      </span>
      <span class="text-gray-400">{{
        $t('invoicing.including_vat_abbreviated')
      }}</span>
    </div>
    <div class="flex gap-2">
      <span class="font-bold">{{ $h.formatCurrency(price_htva) }}</span>
      <span class="text-gray-400">{{
        $t('invoicing.excluding_vat_abbreviated')
      }}</span>
    </div>
  </template>
  <template v-else>
    <div class="flex gap-2">
      <span class="font-bold">
        {{ $h.formatCurrency(price_htva) }}
      </span>
      <span class="text-gray-400">{{
        $t('invoicing.excluding_vat_abbreviated')
      }}</span>
    </div>
    <div class="flex gap-2">
      <span class="font-bold">{{ $h.formatCurrency(price_tvac) }}</span>
      <span class="text-gray-400">{{
        $t('invoicing.including_vat_abbreviated')
      }}</span>
    </div>
  </template>
</template>
<script>
export default {
  name: 'PricingTableByFuneralHome',
  props: {
    price_encoding_type: {
      type: String,
      required: true
    },
    price_tvac: {
      type: String,
      required: true
    },
    price_htva: {
      type: String,
      required: true
    }
  }
}
</script>
