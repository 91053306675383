<template>
  <PageSection :title="$t('global.connection')">
    <div v-if="user" class="grid grid-cols-1 gap-2 md:grid-cols-2">
      <div
        class="col-span-2"
        v-if="$route.name !== 'profile_information_route'"
      >
        <BaseShowEditSwitch
          :key="userHasAccessAppPermission"
          v-model="userHasAccessAppPermission"
          :label="$t('attributes.user_login')"
          :description="$t('global.user_can_login')"
          name="user_login"
          :errors="errors.can_login"
          :edition-mode="editionMode"
          @change="setAccessAppPermission"
        />
      </div>
      <template v-if="userHasAccessAppPermission">
        <BaseShowEditInput
          v-model="user.user_name"
          :label="$t('attributes.username')"
          :errors="errors.user_name"
          name="user_name"
          :placeholder="$t('attributes.username')"
          :edition-mode="editionMode"
          :required="$route.name !== 'profile_information_route'"
          :disabled="$route.name === 'profile_information_route'"
        />
        <template v-if="editionMode" class="col-start-1">
          <BaseButton
            v-if="
              $route.name === 'settings_edit_user_route' ||
              $route.name === 'profile_form_route'
            "
            :type="editPasswordType"
            :icon="editPasswordIcon"
            :title="$t(editPasswordText, { var: $t('attributes.user') })"
            @click="editPassword = !editPassword"
          />
          <div
            v-if="
              errors?.password && userHasAccessAppPermission && !editPassword
            "
            class="form-help text-red-600"
          >
            {{ errors?.password?.join() }}
          </div>
        </template>
        <div v-if="editionMode && editPassword" class="col-start-1">
          <PasswordInput
            v-model="user.password"
            :label="$t('attributes.password')"
            name="password"
            :errors="errors.password"
          />
        </div>
        <div
          v-if="editionMode && editPassword && user.password?.length"
          class="col-start-1"
        >
          <PasswordInput
            v-model="user.password_confirmation"
            :label="$t('attributes.password_confirmation')"
            name="password_confirmation"
            :errors="errors.password_confirmation"
          />
        </div>
      </template>
      <div
        v-if="errors?.user_name && !userHasAccessAppPermission"
        class="form-help -mt-3 text-red-600"
      >
        {{ errors?.user_name?.join() }}
      </div>
      <div
        v-if="errors?.password && !userHasAccessAppPermission"
        class="form-help col-start-1 -mt-4 text-red-600"
      >
        {{ errors?.password?.join() }}
      </div>
    </div>
  </PageSection>
</template>
<script>
import BaseShowEditSwitch from '@c/BaseShowEditSwitch'
import PasswordInput from '@c/PasswordInput'
import SectionTitle from '@c/SectionTitle'
import { mapGetters, mapMutations } from 'vuex'
import PageSection from '@c/PageSection'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'UserConnection',
  components: {
    BaseButton,
    BaseShowEditInput,
    FontAwesomeIcon,
    PageSection,
    BaseShowEditSwitch,
    PasswordInput,
    SectionTitle
  },
  data() {
    return {
      editPassword:
        this.$route.name !== 'settings_edit_user_route' &&
        this.$route.name !== 'profile_form_route'
    }
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    metadata: {
      type: [Object, Array],
      required: true
    },
    disableStickyMargin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors'
    }),
    editPasswordText() {
      return !this.editPassword
        ? this.$t('button.modify_password')
        : this.$t('button.cancel_modification')
    },
    editPasswordIcon() {
      return !this.editPassword ? 'unlock' : 'times'
    },
    editPasswordType() {
      return !this.editPassword ? 'warning' : 'secondary'
    },
    userHasAccessAppPermission() {
      return this.user?.permissions?.includes('access_app')
    }
  },
  methods: {
    ...mapMutations({
      processAddAccessAppPermissionMutation: 'user/addAccessAppPermission',
      processRemoveAccessAppPermissionMutation: 'user/removeAccessAppPermission'
    }),
    setAccessAppPermission(payload) {
      if (payload === false) {
        return this.processRemoveAccessAppPermissionMutation()
      }
      return this.processAddAccessAppPermissionMutation()
    }
  }
}
</script>
