<template>
  <main class="flex h-full flex-row gap-5">
    <aside
      class="lg:2/12 overflow-auto overflow-x-hidden rounded-lg bg-white shadow md:w-3/12"
    >
      <SettingsNav />
    </aside>
    <div class="flex md:w-9/12 lg:w-10/12">
      <RouterView />
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

import SettingsNav from './nav/TheSaManagementNav.vue'

export default {
  name: 'TheSaManagementPage',
  components: {
    SettingsNav
  },
  methods: {
    ...mapActions('sideMenu', ['collapse'])
  }
}
</script>
