<template>
  <BaseCenteredModal
    v-model:is-modal-open="localIsModalOpen"
    size="medium"
    :title="$t('global.printing_options')"
  >
    <template #body>
      <div class="flex flex-col gap-4">
        <div class="min-h-0 flex-1 overflow-y-auto">
          <div class="grid min-h-[8rem] w-full grid-cols-4 gap-x-2 gap-y-1">
            <div class="col-span-2">
              <SelectFuneralHome
                v-model="funeralHome"
                :error-key="funeralHome"
                :edition-mode="false"
              />
            </div>
            <div class="col-span-2">
              <SelectComponent
                v-if="supplier"
                v-model="supplier"
                :name="name"
                :label="$t('attributes.supplier')"
                :values="metadata?.models?.suppliers"
                display-key="name"
                :edition-mode="false"
              />
            </div>
            <div class="col-span-2">
              <WalkmanButton
                v-model="type"
                :options="[
                  { id: 'listing', name: 'Listing' },
                  { id: 'vignette', name: 'vignette' }
                ]"
                track-by="id"
                attribute-label="name"
                label="Type"
                :required="true"
              />
            </div>
            <div>
              <BaseShowLabel
                :label="$t('global.tri')"
                :model-value="$t('attributes.' + sort.tri)"
              />
            </div>
            <div>
              <BaseShowLabel
                :label="$t('attributes.type')"
                :model-value="$t('global.' + sort.type)"
              />
            </div>
          </div>
          <div class="flex gap-2">
            <button class="font-semibold text-primary" @click="selectAll">
              {{ $t('button.select_all') }}
            </button>
            <button class="font-semibold text-primary" @click="unselectAll">
              {{ $t('button.unselect_all') }}
            </button>
          </div>
          <div class="grid grid-cols-2 gap-x-2 gap-y-1 md:grid-cols-3">
            <div v-for="category in filteredCategories" :key="category.id">
              <BaseCheckBox
                v-model="selectedCategories"
                :value="category.id"
                :label="category.name"
                :name="category.id"
                edition-mode
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <BaseButton
            :title="$t('global.generate_catalog')"
            icon="download"
            :disabled="!validForm"
            :display-loader="load"
            @click="processUploadCatalogFile"
          />
        </div>
      </div>
    </template>
  </BaseCenteredModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import WalkmanButton from '@c/WalkmanButton.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SelectFuneralHome from '@c/InitialStateFormComponent/SelectFuneralHome.vue'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'

export default {
  name: 'PrintOptionCatalogModal',
  components: {
    BaseShowLabel,
    SelectComponent,
    BaseCheckBox,
    SelectFuneralHome,
    BaseButton,
    WalkmanButton,
    BaseCenteredModal
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    },
    funeralHome: {
      type: String,
      required: true
    },
    supplier: {
      type: String,
      required: false,
      default: null
    },
    categories: {
      type: Array,
      required: true
    },
    sort: {
      type: [Array, null],
      required: false,
      default: null
    }
  },
  emits: ['close', 'update:is-modal-open'],
  data() {
    return {
      load: false,
      type: 'listing',
      selectedCategories: []
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata',
      allCategories: 'metadata/getMetadataItemCategories'
    }),
    localIsModalOpen: {
      get() {
        return this.isModalOpen
      },
      set(value) {
        this.$emit('update:is-modal-open', value)
      }
    },
    filteredCategories() {
      if (!this.categories) {
        return []
      }
      return this.allCategories.filter((item) =>
        this.categories.includes(item.id)
      )
    },
    validForm() {
      return this.selectedCategories.length > 0
    }
  },
  methods: {
    ...mapActions({
      processExportPDFCatalogAction: 'item/exportPDFCatalog'
    }),
    selectAll() {
      this.selectedCategories = this.filteredCategories.map(
        (category) => category.id
      )
    },
    unselectAll() {
      this.selectedCategories = []
    },
    isCategoryIncluded(categoryId) {
      return this.selectedCategories.includes(categoryId)
    },
    processUploadCatalogFile() {
      this.load = true
      this.processExportPDFCatalogAction({
        data: {
          funeral_home: this.funeralHome,
          supplier: this.supplier,
          type: this.type,
          categories:
            this.selectedCategories.length === 0
              ? this.categories
              : this.selectedCategories,
          options: this.sort
        }
      })
        .then((response) => {
          if (response.message) {
            this.$h.toastInfo(response.message)
          }
          if (response.url) {
            window.open(response.url, '_blank')
            // this.$h.downloadURI(response.url, '_blank')
            this.$emit('close', false)
          }
        })
        .finally((_) => {
          this.load = false
        })
    }
  }
}
</script>
