<template>
  <div
    class="flex w-full flex-row rounded-md border border-primary bg-primary-200"
  >
    <div
      class="flex aspect-square w-16 shrink-0 items-center justify-center p-3 text-primary"
    >
      <FontAwesomeIcon :icon="['fal', 'store']" size="2x" fixed-width />
    </div>
    <div class="flex-1 p-3">
      <p>{{ $t('global.module_not_enabled', { module_name: module }) }}</p>
      <div v-if="$can('see_marketplace')" class="flex">
        <RouterLink
          :to="{
            name: 'marketplace_list'
          }"
          class="underline"
        >
          {{ $t('button.access_to_marketplace') }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'ModuleNotEnabledComponent',
  components: { FontAwesomeIcon },
  props: {
    module: {
      type: String,
      required: true
    }
  }
}
</script>
