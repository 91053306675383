<template>
  <div class="flex flex-row rounded border border-gray-200 p-3">
    <div class="flex-1">{{ automation.name }}</div>
    <div class="flex-1">
      <AutomationConditionBadge
        v-for="condition in automation.conditions"
        :key="condition.id"
        :metadata="metadata"
        :condition="condition"
      />
    </div>
    <div class="flex shrink-0 flex-row items-center">
      <button
        class="flex items-center gap-2 rounded-full bg-primary px-1 py-1 text-white lg:px-2"
        @click="runAutomation"
      >
        <FontAwesomeIcon :icon="['fas ', 'circle-play']" />
        <span class="hidden lg:block">{{ 'run' }}</span>
      </button>
    </div>
    <ConfirmModal ref="confirmRunAutomation" />
  </div>
</template>

<script>
import AutomationConditionBadge from '@/views/settings/management/automations/index/components/AutomationConditionBadge.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions, mapGetters } from 'vuex'
import ConfirmModal from '@c/addf-package/components/modal/ConfirmModal.vue'

export default {
  name: 'AutomationItem',
  components: { ConfirmModal, FontAwesomeIcon, AutomationConditionBadge },
  props: {
    automation: {
      type: Object,
      required: true
    },
    metadata: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      dossier: 'dossier/getDossier'
    })
  },
  methods: {
    ...mapActions({
      processRunAutomationDossierAction: 'dossier/runAutomationDossier'
    }),
    async runAutomation() {
      const confirm = await this.$refs.confirmRunAutomation.show({
        title: this.automation.name,
        message: this.$t('validation.confirm'),
        okButton: this.$t('button.confirm')
      })
      if (confirm) {
        this.processRunAutomationDossierAction({
          dossier_id: this.dossier.id,
          automation_id: this.automation.id
        }).then((response) => {
          this.$h.toastSuccess('cette automation a bien été exécutée')
        })
      }
    }
  }
}
</script>

<style scoped></style>
