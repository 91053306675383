export default {
  install(app, options) {
    app.config.globalProperties.$trans = function (name, params) {
      let value = name

      if (name instanceof Object) {
        value = value[this.$i18n.locale]

        if (!(typeof value === 'string')) {
          const values = Object.values(name)

          if (values.length) {
            return values[0]
          }
        }
      } else if (typeof name === 'string') {
        return this.$t(name, params)
      }

      return value
    }
  }
}
