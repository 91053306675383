import apiClient from '@u/apiClient'
import Promise from 'lodash-es/_Promise'

export default {
  async fetchMediaById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`media/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async updateMediaById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .put(`media/${payload.id}`, payload.data)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  setMediaFavorityById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .put(`media/${payload.id}/set-favorite`, payload)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  getMediaUpscaled({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`media/${payload.id}/upscale`, { responseType: 'blob' })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getMediaColorized({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`media/${payload.id}/colorizer`, { responseType: 'blob' })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getMediaWithoutBackground({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`media/${payload.id}/background-remover`, { responseType: 'blob' })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  changeMediaBackground({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`media/${payload.id}/background-changer`, {
          params: {
            origin: payload.origin,
            ['picture-id']: payload.picture_id
          },
          responseType: 'blob'
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  deleteMediaById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`media/${payload.media_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  undoDeleteMediaById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`media/${payload.media_id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  searchInShutterStock: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/webservice/shutterstock/search`, {
          params: {
            term: payload.term
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  findSimilarInShutterStock: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/webservice/shutterstock/${payload.id}/similar`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  saveShutterStockToGallery: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/webservice/shutterstock/${payload.id}/save-to-gallery`, {
          params: {
            name: payload.name
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getBackgroundGalleryList: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/media/background-gallery`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
