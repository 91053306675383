import apiClient from '@u/apiClient'
let base_errors = {}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}
export default {
  async fetchNewPlace({ commit }, payload) {
    commit('setItemList', [])
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`model/new?model=wikiplace`)
      .then(async (response) => {
        await console.log('', response)
        if (response.status === 200) {
          if (payload.type) response.data.wikiplace.type = payload.type
          if (payload.city_id) response.data.wikiplace.city_id = payload.city_id
          commit('setPlace', response.data.wikiplace)
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },
  async fetchPlacesByType({ commit }, payload) {
    commit('setItemList', [])
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`place/${payload.place_type}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setPlaceList', response.data.places)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchPlacesByType', reason)
      })
  },
  async fetchPlaceByIdAndType({ commit }, payload) {
    commit('setItemList', [])
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`place/${payload.place_id}?type=${payload.type}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setPlace', response.data.place)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchPlaceByIdAndType', reason)
      })
  },

  storePlace: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .post(`place`, payload.place)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updatePlace: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise(async (resolve, reject) => {
      console.log(payload.place)
      apiClient
        .put(`place/update/${payload.place_id}`, payload.place)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async fetchPlacesByCityAndRelation({ commit }, payload) {
    commit('setPlaceList', [])
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`place/city/${payload.city_id}?type=${payload.type}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setPlaceList', response.data.places)
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },
  async fetchWikiPlaceByType({}, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`place-for-select/${payload.type}`, {
          params: payload
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async deleteWikiPlaceById({ commit }, payload) {
    await apiClient
      .delete(`place/delete/${payload.place_id}?type=${payload.place_type}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setPlaceList', response.data.places)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('deleteWikiPlaceById', reason)
      })
  },
  async undoDeleteWikiPlaceById({ commit }, payload) {
    await apiClient
      .get(`place/undo-delete/${payload.place_id}?type=${payload.place_type}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setPlaceList', response.data.places)
        }
        processSuccessCallback(payload)
      })
      .catch(async (reason) => {
        await console.error('undoDeleteWikiPlaceById', reason)
      })
  }
}
