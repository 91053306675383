<template>
  <div class="flex h-full flex-col gap-4">
    <div class="flex shrink-0 justify-end">
      <BaseButton
        :icon="'plus'"
        size="small"
        :title="$t('button.add')"
        @click="$router.push({ name: 'import_new_import_route' })"
      />
    </div>
    <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
      <ImportViewComponent
        v-for="import_ in imports"
        :key="import_.id"
        :import_="import_"
        @updated="fetchImport"
        @delete="deleteImport"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ImportViewComponent from '@/views/sa_management/import/component/ImportViewComponent.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'TheListImport',
  components: { BaseButton, ImportViewComponent },
  data() {
    return {
      imports: []
    }
  },
  computed: {
    ...mapGetters({})
  },
  mounted() {
    this.fetchImport()
  },
  methods: {
    ...mapActions({
      processFetchImportListAction: 'saManagement/fetchImportList',
      processDeleteImportAction: 'saManagement/deleteImport'
    }),
    deleteImport(import_id) {
      this.processDeleteImportAction({
        import_id: import_id
      }).then((response) => this.fetchImport())
    },
    fetchImport() {
      this.processFetchImportListAction().then(
        (response) => (this.imports = response.imports)
      )
    }
  }
}
</script>
