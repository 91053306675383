<template>
  <WikiCityListComponent
    v-model="searchValue"
    :title="$t('global.closest_cities')"
    icon="globe"
  >
    <template v-if="locationUnavailable">
      <div
        class="mx-auto mt-16 cursor-default select-none text-center text-slate-300"
      >
        {{ $t('validation.there_is_no_closest_cities_yet') }}
      </div>
    </template>
    <template v-else>
      <template v-for="city in cities" :key="city.id">
        <WikiCityCard :city="city" />
      </template>
    </template>
  </WikiCityListComponent>
</template>

<script>
import WikiCityListComponent from '@/views/wiki/communes/index/components/WikiCityListComponent.vue'
import WikiCityCard from '@/views/wiki/communes/index/components/WikiCityCard.vue'
import { mapActions } from 'vuex'

export default {
  name: 'WikiClosestCitiesList',
  components: { WikiCityCard, WikiCityListComponent },
  data() {
    return {
      searchValue: '',
      latitude: null,
      longitude: null,
      locationUnavailable: null,
      cities: []
    }
  },
  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position)
          this.locationUnavailable = false
          this.latitude = position.coords.latitude
          this.longitude = position.coords.longitude
          this.fetchCities()
        },
        (_error) => {
          this.locationUnavailable = true
        },
        { timeout: 10000 }
      )
    }
  },
  methods: {
    ...mapActions({
      processFetchClosestCitiesAction: 'wiki/fetchClosestCities'
    }),
    fetchCities() {
      console.log('fetchCities')
      this.processFetchClosestCitiesAction({
        params: {
          longitude: this.longitude,
          latitude: this.latitude
        }
      }).then((response) => {
        this.cities = response.cities
      })
    }
  }
}
</script>
