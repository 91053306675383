export default {

  setFilters(state, filters) {
    state.filters = filters
  },
  setSupplierOrders(state, supplierOrders) {
    state.supplierOrders = supplierOrders
  },
  setSupplierOrder(state, supplierOrder) {
    state.supplierOrder = supplierOrder
  },
  setSupplierOrderAtIndex(state, payload) {
    state.supplierOrders[payload.index] = payload.supplierOrder
  },
  setSupplierOrderMetadata(state, metadata) {
    state.supplierOrderMetadata = metadata
  },
  setSendSupplierOrderData(state, sendSupplierOrderData) {
    state.sendSupplierOrderData = sendSupplierOrderData
  },
  incrementSupplierOrderItemQuantityByItemId(state, item_id) {
    state.supplierOrder.supplierOrderItems.find(
      (supplierOrder_item) => supplierOrder_item.item_id === item_id
    ).quantity++
  },
  addSupplierOrderItemToSupplierOrder(state, future_supplierOrder_item) {
    state.supplierOrder.supplierOrderItems.push(future_supplierOrder_item)
  },
  removeSupplierOrderAtIndex(state, index) {
    state.supplierOrder?.supplierOrderItems.splice(index, 1);
  },
  setSupplierOrderSupplierOrderAddressProperty(state, payload) {
    if (!('supplier_order_property' in state.supplierOrder))
      state.supplierOrder.supplier_order_property = { delivery_model: { name: null } }

    state.supplierOrder.supplier_order_property.delivery_place_id = payload?.id
    state.supplierOrder.supplier_order_property.delivery_place_type = payload?.type
    state.supplierOrder.supplier_order_property.delivery_model.name = payload?.label
  },
  setSupplierOrderRecipientID(state, recipient_id) {
    state.supplierOrder.recipient_id = recipient_id
  },
  setSupplierOrderRecipient(state, recipient) {
    state.supplierOrder.recipient = recipient
  },

  resetSupplierOrder(state) {
    state.supplierOrder = {
      dossier: {},
      contact: {}
    }
  },

  // endregion

  // region supplierOrderItem
  setSupplierOrderItem(state, supplierOrderItem) {
    state.supplierOrderItem = supplierOrderItem
  },
  // endregion


}
