const TheSurveyIndexPage = () =>
  import(
    /* webpackChunkName: "group-settings" */ '@/views/settings/management/survey/index/TheSurveyIndexPage'
  )
const TheSurveyForm = () =>
  import(
    /* webpackChunkName: "group-settings" */ '@/views/settings/management/survey/form/TheSurveyForm'
    )

export const settingsSurveyRoutes = [
  {
    path: 'survey',
    children: [
      {
        path: '',
        name: 'settings_list_survey_route',
        component: TheSurveyIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_edit_survey_route',
        props: true,
        component: TheSurveyForm,
        children: [
            {
                path: '',
                components: TheSurveyForm,
                name: 'settings_edit_survey_route'
            }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_automation_title', active: false },
            { title: 'content_automation_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_survey_route',
        component: TheSurveyForm,
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        }
      }
    ]
  }
]
