<template>
  <div>
    <div
      class="fm-form-input"
      :class="{
        'mt-2': !!label,
        'bg-white': !disabled,
        'bg-gray-100': disabled
      }"
    >
      <label v-if="label">
        {{ label }}
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <Combobox v-model="localModelValue" multiple :disabled="disabled">
        <Float
          adaptive-width
          :auto-placement="{
            autoAlignment: true,
            allowedPlacements: ['bottom', 'top']
          }"
          :offset="1"
          @leave="query = ''"
        >
          <div
            class="flex w-full cursor-default !flex-col overflow-hidden rounded-lg text-left"
            :class="{
              '!bg-white': !disabled,
              'bg-gray-100': disabled
            }"
          >
            <ul
              v-if="localModelValue.length > 0"
              class="relative flex w-full flex-wrap gap-2 pl-2 pr-9 pt-3"
            >
              <li
                v-for="(item, idx) in localModelValue"
                :key="item"
                class="flex flex-row items-center gap-1 overflow-hidden rounded bg-primary-300 px-1 text-white"
              >
                <div class="truncate">
                  {{
                    values.find((value) => item === value[trackBy])?.[
                      displayKey
                    ]
                  }}
                </div>
                <button
                  class="flex items-center"
                  type="button"
                  tabindex="-1"
                  :disabled="disabled"
                  @click="remove(idx)"
                >
                  <FontAwesomeIcon :icon="['far', 'times']" size="sm" />
                </button>
              </li>
            </ul>
            <ComboboxInput
              autocomplete="nope"
              autofill="nope"
              class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              @change="query = $event.target.value"
            />
            <ComboboxButton
              class="absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <FontAwesomeIcon :icon="['fal', 'chevron-down']" />
            </ComboboxButton>
          </div>
          <ComboboxOptions
            class="max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <div
              v-if="filteredOptions.length === 0 && query !== ''"
              class="relative cursor-default select-none px-4 py-2 text-gray-700"
            >
              Nothing found.
            </div>

            <ComboboxOption
              v-for="item in filteredOptions"
              :key="item[trackBy]"
              v-slot="{ selected, active, disabled }"
              as="template"
              :value="item[trackBy]"
            >
              <li
                class="relative cursor-default select-none py-2 pl-8 pr-4"
                :class="{
                  'bg-gray-200 text-gray-700': disabled,
                  'bg-primary-500 text-contrast-primary': selected,
                  'bg-primary-300 text-white': active,
                  'text-gray-900': !active && !selected
                }"
              >
                <slot
                  name="option"
                  :option="item"
                  :active="active"
                  :selected="selected"
                >
                  <span
                    class="flex w-full flex-row items-center gap-1.5 truncate"
                  >
                    <span v-if="colored" class="shrink-0">
                      <span
                        :data-tint="item.bg_color"
                        class="block h-3 w-3 rounded-full bg-primary"
                      ></span>
                    </span>
                    <span
                      class="flex-1"
                      :class="[
                        selected ? 'font-medium' : 'font-normal',
                        capitalize ? 'capitalize-first' : ''
                      ]"
                    >
                      {{ item[displayKey] }}
                    </span>
                  </span>
                </slot>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3"
                >
                  <FontAwesomeIcon :icon="['fas', 'check']" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </Float>
      </Combobox>
    </div>
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
</template>

<script>
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { Float } from '@headlessui-float/vue'

export default {
  name: 'MultiSelectSearchableComponent',
  components: {
    Float,
    ErrorContainer,
    ComboboxOption,
    ComboboxOptions,
    ComboboxButton,
    ComboboxInput,
    Combobox,
    FontAwesomeIcon
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'normal'
    },
    values: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    displayKey: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    selectedLabel: {
      type: String,
      required: false,
      default: null
    },
    noneSelectedLabel: {
      type: String,
      required: false,
      default: null
    },
    numberDisplayed: {
      type: Number,
      required: false,
      default: 1
    },
    includeSelectAllOption: {
      type: Boolean,
      required: false,
      default: true
    },
    favorite: {
      type: Array,
      required: false,
      default: null
    },
    favoriteOnTop: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    colored: {
      type: Boolean,
      required: false,
      default: false
    },
    capitalize: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      query: ''
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    filteredOptions() {
      return this.query === ''
        ? this.orderedValues
        : this.orderedValues.filter((value) => {
            return value[this.displayKey]
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(this.query.toLowerCase().replace(/\s+/g, ''))
          })
    },
    orderedValues() {
      if (
        this.favorite === null ||
        !Array.isArray(this.favorite) ||
        this.favorite.length === 0 ||
        !this.favoriteOnTop
      ) {
        return this.values
      }
      return this.values.sort((a, b) =>
        this.favorite.includes(a.id) ? -1 : this.favorite.includes(b.id) ? 1 : 0
      )
    },
    selectedValuesLabel() {
      if (!this.selectedValues) {
        return this.noneSelectedLabel ?? this.$t('global.aucun_selectionne')
      }
      var lSelected = this.selectedValues.length
      if (lSelected > this.numberDisplayed) {
        return (
          lSelected +
          ' ' +
          (this.selectedLabel ?? this.$t('global.selectionnes'))
        )
      } else if (lSelected === 0) {
        return this.noneSelectedLabel ?? this.$t('global.aucun_selectionne')
      } else if (
        lSelected <= this.numberDisplayed &&
        this.selectedValues.length > 0
      ) {
        return this.values
          .filter((value) => this.modelValue.includes(value[this.trackBy]))
          .map((value) => value[this.displayKey])
          .join(', ')
      }
    },
    sizeClass() {
      switch (this.size) {
        case 'small':
          return 'py-2'
        case 'normal':
          return 'py-2'
      }
    },
    listboxOptionClass() {
      switch (this.size) {
        case 'small':
          return ''
        case 'normal':
          return 'mt-4'
      }
    }
  },
  methods: {
    selectAll() {
      this.selectedValues = this.values.map((value) => value[this.trackBy])
    },
    unselectAll() {
      this.selectedValues = []
    },
    remove(index) {
      this.localModelValue.splice(index, 1)
    }
  }
}
</script>

<style scoped></style>
