<template>
  <div class="flex shrink-0 flex-row">
    <h2 class="flex-1 text-2xl font-bold capitalize-first">Stock</h2>
    <div class="w-96">
      <SelectStorageLocation
        v-model="selectedLocation"
        edition-mode
        :error-key="'sites'"
      />
    </div>
  </div>
  <div class="flex min-h-0 flex-1 flex-col gap-6">
    <TheAlertComponent :location-id="locationId" />
    <UpdateStockComponent :location-id="locationId" />
  </div>
</template>

<script>
import TheAlertComponent from '@/views/stock/summary/component/TheAlertComponent.vue'
import UpdateStockComponent from '@/views/stock/summary/component/UpdateStockComponent.vue'
import SelectStorageLocation from '@c/InitialStateFormComponent/SelectStorageLocation.vue'

export default {
  name: 'TheSummaryPage',
  components: {
    SelectStorageLocation,
    UpdateStockComponent,
    TheAlertComponent
  },
  props: {
    locationId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      selectedLocation: null
    }
  },
  watch: {
    locationId(newValue, oldValue) {
      this.selectedLocation = newValue
    },
    selectedLocation(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.$router.push({
            name: 'stock_location_summary_route',
            params: {
              locationId: newValue
            }
          })
        } else {
          this.$router.push({
            name: 'stock_summary_route'
          })
        }
      }
    }
  },
  mounted() {
    this.selectedLocation = this.locationId
  }
}
</script>
