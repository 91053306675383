<template>
  <WikiCityListComponent
    v-model="searchValue"
    :title="$t('global.favorite_cities')"
    icon="star"
  >
    <template v-if="filteredCities.length < 1">
      <div
        class="mx-auto mt-16 cursor-default select-none text-center text-slate-300"
      >
        {{ $t('validation.there_is_no_favorite_cities_yet') }}
      </div>
    </template>
    <template v-else>
      <template v-for="(city, index) in filteredCities" :key="index">
        <WikiCityCard :city="city" />
      </template>
    </template>
  </WikiCityListComponent>
</template>

<script>
import WikiCityListComponent from '@/views/wiki/communes/index/components/WikiCityListComponent.vue'
import { mapActions, mapGetters } from 'vuex'
import WikiCityCard from '@/views/wiki/communes/index/components/WikiCityCard.vue'

export default {
  name: 'WikiFavoriteCitiesList',
  components: { WikiCityCard, WikiCityListComponent },
  data() {
    return {
      searchValue: ''
    }
  },
  computed: {
    ...mapGetters({
      favoriteCities: 'wiki/getFavoriteCities',
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    filteredCities() {
      if (this.searchValue.length < 1) return this.favoriteCities
      return this.favoriteCities?.filter((favoriteCity) =>
        favoriteCity?.name[this.$i18n.locale]
          ?.toLowerCase()
          .includes(this.searchValue?.toLowerCase())
      )
    }
  },
  beforeMount() {
    this.processFetchFavoriteCitiesAction()
  },
  methods: {
    ...mapActions({
      processFetchFavoriteCitiesAction: 'wiki/fetchFavoriteCities'
    })
  }
}
</script>
