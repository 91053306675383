import apiClient from '@u/apiClient'
import actionsScreenConfig from '@/store/modules/settings/management/screenSettings/actions.screenConfig'

export default {
  ...actionsScreenConfig,

  fetchScreenSettingsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('screen-settings')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchNewScreenSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=Screen')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchScreenSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`screen-settings/${payload.screen_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  storeScreen({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`screen-settings`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updateScreen({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`screen-settings/${payload.id}`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchDossierByLocation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`screen/data/get-dossier-by-location`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchListDossiersByLocation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`screen/data/get-list-dossiers-by-location`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getEvents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`screen/data/get-events`, {
          params: payload
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  saveAccessCode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`screen-settings/${payload.screen_id}/access-code`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  logoffScreen({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`screen-settings/${payload.screen_id}/logoff`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
