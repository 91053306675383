const Partners = Object.freeze({
  ENAOS: 'enaos',
  FRAGMENT: 'fragment',
  LEGACIO: 'legacio',
  FUNE_UP: 'fune_up',
  YOUSIGN: 'yousign',
  CREMAGEST: 'cremagest',
  SORENIR: 'sorenir',
  KITTY_FOR_RELATIVES: 'kitty_for_relatives',
  CHIFT: 'chift',
  SQUARE_UP: 'square_up'
})

export default Partners
