import store from '@/store'
import DashboardTypes from '@/assets/enums/dashboard/DashboardTypes'

const TheSupplierOrdersPage = () =>
  import('@/views/supplierOrder/TheSupplierOrdersPage.vue')
const TheSupplierOrderPage = () =>
  import('@/views/supplierOrder/TheSupplierOrderPage.vue')

export const supplierOrderSubPages = [
  {
    path: 'dashboard-supplier-order',
    children: [
      {
        path: '',
        name: 'supplier_order_page_route',
        component: TheSupplierOrdersPage,
        meta: {
          route_name: 'supplier_order_dashboard_route_id'
        },
        children: [
          {
            path: '',
            beforeEnter: (from, to, next) => {
              let template = store.getters[
                'metadata/getTemplateDashboardByType'
              ](DashboardTypes.SUPPLIER_ORDER)
              if (Array.isArray(template) && template[0]) {
                next({
                  name: 'supplier_order_dashboard_route_id',
                  params: { id: template[0].id }
                })
                return
              }
              next()
              return
            }
          },
          {
            path: ':id',
            name: 'supplier_order_dashboard_route_id',
            component: TheSupplierOrdersPage
          }
        ]
      }
    ]
  },
  {
    path: 'supplier-order',
    children: [
      {
        path: ':supplierOrderId',
        name: 'supplier_order_page_route_id',
        redirect: {
          name: 'supplier_order_page_edit_id'
        },
        children: [
          {
            path: '',
            name: 'supplier_order_page_edit_id',
            component: TheSupplierOrderPage,
            props: (route) => ({
              view: 'edit',
              supplierOrderId: route.params.supplierOrderId // Passer le paramètre ici
            })
          },
          {
            path: 'print',
            name: 'supplier_order_page_print_id',
            component: TheSupplierOrderPage,
            props: (route) => ({
              view: 'print',
              supplierOrderId: route.params.supplierOrderId // Passer le paramètre ici
            })
          }
        ]
      }
    ]
  }
]
