import EventType from '../enums/events/EventType'

export const base_order_funeral = [
  EventType.DECLARATION,
  EventType.CREMATION,
  EventType.CEREMONY,
  EventType.ASHES_RECOVERY,
  EventType.BURIAL,
  EventType.EXHUMATION,
  EventType.WAKE,
  EventType.BODY_RAISING,
  EventType.REUNION,
  EventType.VISIT,
  EventType.ROOM_OCCUPANCY
]

export const base_order_remain = [
  EventType.TRANSFERT,
  EventType.THANATO,
  EventType.COFFIN_PLACING,
  EventType.COFFIN_CLOSING
]
