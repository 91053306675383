<template>
  <div
    v-if="dossier"
    class="sticky left-0 right-0 top-0 z-100 flex flex-row items-center gap-x-3 rounded-t p-3"
    :class="{
      'bg-gradient-to-r from-primary-500 via-primary-600 via-60% to-orange-400 to-90%':
        hasSpecialParticularities,
      'bg-gradient-to-r from-primary-500 to-primary-600 ':
        !hasSpecialParticularities
    }"
  >
    <!-- The image content -->
    <div
      class="flex max-w-fit flex-1 flex-row items-end gap-x-2"
      :class="{
        'absolute left-3 top-3': requirePictureOutside
      }"
    >
      <div
        class="group/dossier-picture relative overflow-hidden rounded-md border border-slate-300 bg-white p-0.5 shadow-xl transition-width"
        :class="{
          'aspect-photo w-32': requirePictureOutside,
          'aspect-photo w-20': isNotCropped,
          'aspect-square w-12': isCropped
        }"
      >
        <img
          v-if="dossier?.pictures?.picture_rectangle?.original_url"
          :src="
            cropped
              ? dossier.pictures?.picture_square?.original_url
              : dossier.pictures?.picture_rectangle?.original_url
          "
          class="w-full overflow-hidden rounded object-cover"
          :alt="dossier.concernedPerson.full_name"
        />
        <div
          v-else
          class="absolute inset-0 flex flex-row items-center justify-center text-primary-200"
        >
          <FontAwesomeLayers
            class="transition-all"
            :class="{
              'fa-5x': requirePictureOutside,
              'fa-4x': isNotCropped,
              'fa-2x': isCropped
            }"
          >
            <FontAwesomeIcon :icon="['fal', 'circle']" />
            <FontAwesomeIcon :icon="['fas', 'camera']" transform="shrink-7" />
          </FontAwesomeLayers>
        </div>
        <div
          v-if="canEditPicture"
          class="absolute bottom-1 right-1 flex cursor-pointer flex-row items-center justify-center"
          :class="{}"
        >
          <RouterLink
            :to="
              dossier?.pictures?.picture
                ? { name: 'dossier_picture_preview_route' }
                : { name: 'dossier_gallery_list_route' }
            "
            class="rounded bg-primary-100/75 p-0.5 text-gray-600"
          >
            <FontAwesomeIcon :icon="['fal', 'edit']" fixed-width />
          </RouterLink>
        </div>
      </div>

      <slot name="header_actions" />
    </div>

    <!-- The content text -->
    <div
      class="flex min-w-0 flex-1 flex-col text-white"
      :class="{
        'pl-36': requirePictureOutside
      }"
    >
      <div
        class="flex flex-row items-center justify-between gap-x-1"
        :class="{
          hidden: isCropped
        }"
      >
        <div
          class="whitespace-nowrap text-2xl font-bold leading-none capitalize-first"
        >
          {{ civiliteFormated }}
        </div>

        <div
          class="whitespace-nowrap text-2xl font-bold leading-none capitalize-first"
        >
          {{ funeralHomeName }}
        </div>
      </div>

      <div class="flex flex-row items-center justify-between gap-x-1">
        <div
          class="inline-block overflow-hidden text-ellipsis whitespace-nowrap font-bold"
          :class="{
            'text-2xl': isCropped,
            'text-3xl': isNotCropped || requirePictureOutside
          }"
        >
          {{ dossier.concernedPerson.full_name }}
        </div>

        <div
          class="flex flex-row justify-end"
          :class="{
            hidden: isCropped
          }"
        >
          <div>
            <AvatarComponent
              :user_id="dossier.responsable_id"
              :badge="true"
              size="6"
            />
          </div>
        </div>
      </div>

      <div
        class="flex flex-row items-center justify-between gap-x-1"
        :class="{
          hidden: isCropped
        }"
      >
        <div
          class="text-md inline-block overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{
            $h.formatDate(
              dossier.concernedPerson.birth_date,
              'L',
              $i18n.locale.substring(0, 2)
            )
          }}
          {{
            dossier.concernedPerson.death_date_time
              ? '-' +
                $h.formatDate(
                  dossier.concernedPerson.death_date_time,
                  'L',
                  $i18n.locale.substring(0, 2)
                ) +
                ' (' +
                dossier.concernedPerson.age +
                ')'
              : ''
          }}&nbsp;
        </div>

        <div v-if="!!dossier.properties" class="flex flex-row gap-x-1">
          <div
            v-if="!!dossier.properties.destitute"
            class="whitespace-nowrap rounded-md bg-rose-600 px-2 py-0.5 text-xs font-medium text-contrast-primary capitalize-first"
          >
            {{ $t('attributes.destitute') }}
          </div>
          <div
            v-if="!!dossier.properties.funeral_in_strict_privacy"
            class="whitespace-nowrap rounded-md bg-rose-600 px-2 py-0.5 text-xs font-medium text-contrast-primary capitalize-first"
          >
            {{ $t('attributes.funeral_in_strict_privacy') }}
          </div>
          <div
            v-if="!!dossier.properties.no_flower"
            class="whitespace-nowrap rounded-md bg-rose-600 px-2 py-0.5 text-xs font-medium text-contrast-primary capitalize-first"
          >
            {{ $t('attributes.no_flower') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarComponent from '@c/AvatarComponent.vue'
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'

export default {
  name: 'DossierInfosHeader',
  components: {
    AvatarComponent,
    FontAwesomeLayers,
    FontAwesomeIcon
  },
  props: {
    containerScrollName: {
      type: String,
      required: false,
      default: null
    },
    pictureOutside: {
      type: Boolean,
      required: false,
      default: false
    },
    pictureEditable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      cropped: false,
      containerScroll: null
    }
  },
  computed: {
    ...mapGetters({
      dossier: 'dossier/getDossier',
      civilities: 'metadata/getCivilityMetadata',
      getFuneralHome: 'metadata/getFuneralHomeMetadata'
    }),
    canEditPicture() {
      return !this.cropped && this.pictureEditable
    },
    isCropped() {
      return this.cropped && !this.pictureOutside
    },
    isNotCropped() {
      return !this.cropped && !this.pictureOutside
    },
    requirePictureOutside() {
      return this.pictureOutside
    },
    hasSpecialParticularities() {
      return (
        !!this.dossier.properties?.no_flower ||
        !!this.dossier.properties?.funeral_in_strict_privacy ||
        !!this.dossier.properties?.destitute
      )
    },
    civiliteFormated() {
      return (
        this.civilities.find(
          (civility) => civility.id === this.dossier.concernedPerson.civility
        )?.name ?? null
      )
    },
    funeralHomeName() {
      return this.getFuneralHome(this.dossier.funeral_home_id)?.name
    }
  },
  mounted() {
    this.checkScrollEvent()
  },
  methods: {
    checkScrollEvent() {
      if (this.containerScrollName) {
        this.containerScroll = document.getElementById(this.containerScrollName)

        if (this.containerScroll) {
          this.containerScroll.addEventListener('scroll', (e) => {
            const scrollTop = this.containerScroll.scrollTop

            if (scrollTop >= 75) {
              if (!this.cropped) {
                this.cropped = true
              }
            }

            if (scrollTop === 0) {
              if (this.cropped) {
                this.cropped = false
              }
            }
          })
        }
      }
    }
  }
}
</script>
