<template>
  <div class="flex h-full flex-col gap-2 rounded border p-3 pt-2.5">
    <div class="flex shrink-0 flex-row">
      <div class="min-w-0 flex-1">
        <h5
          :title="option.name"
          class="flex-1 cursor-context-menu truncate text-lg font-bold leading-tight first-letter:capitalize"
        >
          {{ option.name }}
        </h5>
        <div>
          <span
            v-for="type in option.types"
            :key="type"
            class="rounded bg-primary px-1.5 py-0 text-xs text-contrast-primary"
          >
            {{ type }}
          </span>
        </div>
      </div>
      <div v-if="option.img" class="shrink-0">
        <img :src="option.img" class="h-8" :alt="option.name" />
      </div>
    </div>
    <div class="flex-1">
      <p class="text-xs">{{ option.summary }}</p>
      <div>
        <RouterLink
          :to="{
            name: 'marketplace_option_info',
            params: {
              optionId: option.id
            }
          }"
          class="text-xs text-primary"
        >
          {{ $t('global.more_information') }}...
        </RouterLink>
      </div>
    </div>
    <div class="flex shrink-0 items-center justify-between">
      <div v-if="option.available">
        <span class="rounded border px-1">
          {{
            getTenantModuleBillingTypesMetadata.find(
              (element) => element.id === option.billing_type
            )?.name ?? '???'
          }}
        </span>
      </div>
      <div>
        <WalkmanButton
          v-if="option.available"
          v-model="localEnabled"
          :options="[
            {
              value: false,
              label: $t('global.disabled'),
              bgClass:
                'bg-yellow-500 hover:bg-yellow-400 focus:bg-yellow-400 active:bg-yellow-400 !text-black'
            },
            {
              value: true,
              label: $t('global.enabled'),
              bgClass:
                'bg-green-500 hover:bg-green-400 focus:bg-green-400 active:bg-green-400'
            }
          ]"
          size="extra-small"
        />
        <div v-else>
          <span class="rounded border px-1 py-0">
            {{ $t('global.available_soon') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WalkmanButton from '@c/WalkmanButton.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListOptionComponent',
  components: { WalkmanButton },
  props: {
    option: {
      type: Object,
      required: true
    },
    enabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['reload-modules'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getTenantModuleBillingTypesMetadata:
        'metadata/getTenantModuleBillingTypesMetadata'
    }),
    localEnabled: {
      get() {
        return this.enabled
      },
      set(value) {
        if (value !== this.enabled) {
          if (value) {
            this.processEnableMarketplaceModuleAction({
              module_id: this.option.id
            }).then((response) => {
              if (response.success) {
                this.$emit('reload-modules')
                this.processFetchAllMetadataAction().then((response) => {
                  this.emitter.emit('reload-menu')
                })
              }
            })
          } else {
            this.processDisableMarketplaceModuleAction({
              module_id: this.option.id
            }).then((response) => {
              if (response.success) {
                this.$emit('reload-modules')
                this.processFetchAllMetadataAction().then((response) => {
                  this.emitter.emit('reload-menu')
                })
              }
            })
          }
        }
      }
    }
  },
  mounted() {
    this.localEnabled = this.enabled
  },
  methods: {
    ...mapActions({
      processEnableMarketplaceModuleAction:
        'marketplace/enableMarketplaceModule',
      processDisableMarketplaceModuleAction:
        'marketplace/disableMarketplaceModule',
      processFetchAllMetadataAction: 'metadata/fetchAllMetadata'
    })
  }
}
</script>
