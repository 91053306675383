<template>
  <div v-if="editionMode">
    <template v-if="!multiple">
      <template v-if="!searchable">
        <SelectSimple2Component
          v-model="localModelValue"
          :values="values"
          :name="name"
          :size="size"
          :required="required"
          :track-by="trackBy"
          :display-key="displayKey"
          :label="label"
          :selected-label="selectedLabel"
          :none-selected-label="noneSelectedLabel"
          :number-displayed="numberDisplayed"
          :include-select-all-option="includeSelectAllOption"
          :favorite="favorite"
          :errors="errors"
          :capitalize="capitalize"
          :is-feminine="isFeminine"
          :colored="colored"
          :disabled="disabled"
        >
          <template #option="{ option, selected, active }">
            <slot
              name="option"
              :option="option"
              :active="active"
              :selected="selected"
            >
            </slot>
          </template>
        </SelectSimple2Component>
      </template>
      <template v-else>
        <SelectSimpleSearchableComponent
          v-model="localModelValue"
          :values="values"
          :name="name"
          :size="size"
          :required="required"
          :track-by="trackBy"
          :display-key="displayKey"
          :label="label"
          :selected-label="selectedLabel"
          :none-selected-label="noneSelectedLabel"
          :number-displayed="numberDisplayed"
          :include-select-all-option="includeSelectAllOption"
          :favorite="favorite"
          :errors="errors"
          :capitalize="capitalize"
          :is-feminine="isFeminine"
          :colored="colored"
          :disabled="disabled"
        >
          <template #option="{ option, selected, active }">
            <slot
              name="option"
              :option="option"
              :active="active"
              :selected="selected"
            >
            </slot>
          </template>
        </SelectSimpleSearchableComponent>
      </template>
    </template>
    <template v-else>
      <template v-if="!searchable">
        <MultiSelectComponent
          v-model="localModelValue"
          :values="values"
          :name="name"
          :size="size"
          :required="required"
          :track-by="trackBy"
          :display-key="displayKey"
          :label="label"
          :selected-label="selectedLabel"
          :none-selected-label="noneSelectedLabel"
          :number-displayed="numberDisplayed"
          :include-select-all-option="includeSelectAllOption"
          :favorite="favorite"
          :errors="errors"
          :disabled="disabled"
        >
          <template #option="{ option, selected, active }">
            <slot
              name="option"
              :option="option"
              :active="active"
              :selected="selected"
            >
            </slot>
          </template>
        </MultiSelectComponent>
      </template>
      <template v-else>
        <MultiSelectSearchableComponent
          v-model="localModelValue"
          :values="values"
          :name="name"
          :size="size"
          :required="required"
          :track-by="trackBy"
          :display-key="displayKey"
          :label="label"
          :selected-label="selectedLabel"
          :none-selected-label="noneSelectedLabel"
          :number-displayed="numberDisplayed"
          :include-select-all-option="includeSelectAllOption"
          :favorite="favorite"
          :errors="errors"
          :capitalize="capitalize"
          :is-feminine="isFeminine"
          :colored="colored"
          :disabled="disabled"
        >
          <template #option="{ option, selected, active }">
            <slot
              name="option"
              :option="option"
              :active="active"
              :selected="selected"
            >
            </slot>
          </template>
        </MultiSelectSearchableComponent>
      </template>
    </template>
  </div>
  <BaseShowLabel v-else v-model="selectedValue" :label="label" />
</template>

<script>
import SelectSimple2Component from '@c/BaseFormComponent/SelectSimple2Component.vue'
import MultiSelectComponent from '@c/BaseFormComponent/MultiSelectComponent.vue'
import SelectSimpleSearchableComponent from '@c/BaseFormComponent/SelectSimpleSearchableComponent.vue'
import MultiSelectSearchableComponent from '@c/BaseFormComponent/MultiSelectSearchableComponent.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'

export default {
  name: 'SelectComponent',
  components: {
    SelectSimple2Component,
    MultiSelectComponent,
    SelectSimpleSearchableComponent,
    MultiSelectSearchableComponent,
    BaseShowLabel
  },
  props: {
    editionMode: {
      type: Boolean,
      required: false,
      default: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'normal'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: Array,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    displayKey: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    selectedLabel: {
      type: String,
      required: false,
      default: null
    },
    noneSelectedLabel: {
      type: String,
      required: false,
      default: null
    },
    numberDisplayed: {
      type: Number,
      required: false,
      default: 1
    },
    includeSelectAllOption: {
      type: Boolean,
      required: false,
      default: true
    },
    favorite: {
      type: Array,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    capitalize: {
      type: Boolean,
      required: false,
      default: true
    },
    isFeminine: {
      type: Boolean,
      required: false,
      default: false
    },
    colored: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.$emit('change', value)
      }
    },
    selectedValue() {
      if (this.multiple) {
        if (Array.isArray(this.values) && this.values.length) {
          return this.values
            .filter((value) => value[this.trackBy] === this.modelValue)
            .map((value) => value[this.trackBy][this.displayKey])
            .join(', ')
        }
        return ''
      }
      if (this.values) {
        return this.values.find(
          (value) => value[this.trackBy] === this.modelValue
        )?.[this.displayKey]
      }
      return ''
    }
  },
  mounted() {
    if (this.alphabeticalOrder) {
      return this.values.sort((a, b) => a.name.localeCompare(b.name))
    }
  }
}
</script>
