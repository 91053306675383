const TheAccountingDiaryIndexPage = () =>
  import(
    '@/views/settings/invoicing/accountingDiary/TheAccountingDiaryIndexPage.vue'
  )

const TheAccountingDiaryFormPage = () =>
  import(
    '@/views/settings/invoicing/accountingDiary/TheAccountingDiaryFormPage.vue'
  )

export const settingsAccountingDiaryRoutes = [
  {
    path: 'accounting-diary',
    children: [
      {
        path: '',
        name: 'settings_accounting_diaries_route',
        component: TheAccountingDiaryIndexPage
      },
      {
        path: ':accountingDiaryId',
        name: 'settings_accounting_diary_route',
        component: TheAccountingDiaryFormPage,
        props: true
      },
      {
        path: 'new',
        name: 'settings_new_accounting_diary_route',
        component: TheAccountingDiaryFormPage,
        props: true
      }
    ]
  }
]
