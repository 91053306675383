<template>
  <div v-if="editionMode">
    <SelectAjax
      v-model:value="localCityId"
      v-model:valueObject="localCityObject"
      :ajax-extra-params="ajaxExtraParams"
      url="city-for-select"
      attribute-label="full_name"
      :label="label ?? $t('attributes.city')"
      :errors="errors"
      :name="name"
      :required="required"
      :allow-tags="allowTags"
      @clear="clear"
    >
      <template #option="{ option, selected, active }">
        <span
          class="flex flex-row items-center truncate"
          :class="{ 'font-medium': selected, 'font-normal': !selected }"
        >
          <span class="flex-1">{{ option.full_name }}</span>
          <span class="shrink-0">
            <img
              class="h-4 w-6 rounded"
              :src="CountryFlags[option.country.toUpperCase()]"
              :alt="option.country"
            />
          </span>
        </span>
      </template>
    </SelectAjax>
  </div>
  <div v-else>
    <BaseShowLabel
      :label="label ?? $t('attributes.city')"
      :model-value="localCityObject?.full_name"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SelectAjax from '@c/SelectAjaxComponent/component/SelectAjax.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import CountryFlags from '@/assets/enums/config/CountryFlags'

export default defineComponent({
  name: 'SelectCity',
  components: { BaseShowLabel, SelectAjax },
  props: {
    cityId: {
      type: [String, Number],
      required: false,
      default: null
    },
    cityObject: {
      type: Object,
      required: false,
      default: () => {}
    },
    city: {
      type: String,
      required: false,
      default: ''
    },
    zip: {
      type: String,
      required: false,
      default: ''
    },
    country: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Object,
      required: false,
      default: () => {}
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    allowTags: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:cityId', 'update:cityObject', 'update:city', 'update:zip', 'change'],
  computed: {
    CountryFlags() {
      return CountryFlags
    },
    localCityId: {
      get() {
        return this.cityId
      },
      set(value) {
        this.$emit('update:cityId', value)
      }
    },
    localCityObject: {
      get() {
        return this.cityObject
      },
      set(value) {
        if (value) {
          this.localCity = value?.city ?? value?.full_name ?? null
          this.localZip = value?.zip ?? null
        }
        this.$emit('update:cityObject', value)
      }
    },
    localCity: {
      get() {
        return this.city
      },
      set(value) {
        console.log(value)
        this.$emit('update:city', value)
        this.$emit('change', value)
      }
    },
    localZip: {
      get() {
        return this.zip
      },
      set(value) {
        this.$emit('update:zip', value)
      }
    },
    ajaxExtraParams() {
      if (!this.country) return null

      return {
        country: this.country
      }
    }
  },
  mounted() {
    if (
      this.cityId !== null &&
      (!this.cityObject || !this.cityObject?.full_name || !this.cityObject?.id)
    ) {
      this.localCityObject = {
        id: this.cityId,
        full_name: `${this.zip} ${this.city}`,
        city: this.city,
        zip: this.zip
      }
    }
  },
  methods: {
    clear() {
      this.localZip = ''
      this.localCity = ''
    }
  }
})
</script>
