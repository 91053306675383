<template>
  <div
    class="relative flex flex-row rounded-md border border-slate-300 shadow-sm focus-within:border-primary-400 focus-within:ring-0"
    :class="{
      'mt-2': !!label,
      'bg-white': !disabled,
      'bg-gray-100': disabled
    }"
  >
    <label
      v-if="label"
      for="name"
      class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first dark:rounded-md"
      >{{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <Listbox v-model="selectedValues" multiple :disabled="disabled">
      <Float
        adaptive-width
        :offset="2"
        :auto-placement="{
          autoAlignment: true,
          allowedPlacements: ['bottom', 'top']
        }"
      >
        <ListboxButton
          class="block w-full border-0 px-3 py-2 text-gray-900 placeholder-gray-500 outline-0 focus:ring-0 dark:rounded-md dark:border dark:border-white dark:bg-slate-500 dark:text-white sm:text-sm"
        >
          <span class="block truncate text-left">{{
            selectedValuesLabel
          }}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <FontAwesomeIcon :icon="['fal', 'chevron-down']" />
          </span>
        </ListboxButton>
        <ListboxOptions
          class="z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-white dark:bg-slate-500 dark:text-white sm:text-sm"
        >
          <div v-if="includeSelectAllOption" class="flex flex-row p-2 pt-1">
            <button
              type="button"
              class="flex-1 rounded-l-md border border-l border-r-0 py-1 text-center hover:bg-primary-500"
              tabindex="-1"
              @click="selectAll"
            >
              {{ $t('global.all') }}
            </button>
            <button
              type="button"
              class="flex-1 rounded-r-md border border-r py-1 text-center hover:bg-primary-500"
              tabindex="-1"
              @click="unselectAll"
            >
              {{ $t('global.none') }}
            </button>
          </div>
          <ListboxOption
            v-for="value in orderedValues"
            v-slot="{ active, selected }"
            :key="value"
            :value="value[trackBy]"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-primary-300 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-8 pr-4'
              ]"
            >
              <slot
                name="option"
                :option="value"
                :active="active"
                :selected="selected"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate'
                  ]"
                  >{{ value[displayKey] }}</span
                >
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-600"
                >
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                  ></font-awesome-icon>
                </span>
              </slot>
              <span
                v-if="favorite !== null && favorite.includes(value[trackBy])"
                :class="[
                  'absolute inset-y-0 right-0 flex items-center pr-4 text-yellow-300'
                ]"
              >
                <FontAwesomeIcon :icon="['fas', 'star-of-life']" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </Float>
    </Listbox>
  </div>
  <ErrorContainer :error-key="name" :errors="errors" />
</template>

<script>
// https://davidstutz.github.io/bootstrap-multiselect/#configuration-options-includeSelectAllOption

import { computed } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Float } from '@headlessui-float/vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'MultiSelectComponent',
  components: {
    ErrorContainer,
    Float,
    FontAwesomeIcon,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'normal'
    },
    values: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    displayKey: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    selectedLabel: {
      type: String,
      required: false,
      default: null
    },
    noneSelectedLabel: {
      type: String,
      required: false,
      default: null
    },
    numberDisplayed: {
      type: Number,
      required: false,
      default: 1
    },
    includeSelectAllOption: {
      type: Boolean,
      required: false,
      default: true
    },
    favorite: {
      type: Array,
      required: false,
      default: null
    },
    favoriteOnTop: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return { selectedValues }
  },
  computed: {
    orderedValues() {
      if (
        this.favorite === null ||
        !Array.isArray(this.favorite) ||
        this.favorite.length === 0 ||
        !this.favoriteOnTop
      ) {
        return this.values
      }
      return this.values.sort((a, b) =>
        this.favorite.includes(a.id) ? -1 : this.favorite.includes(b.id) ? 1 : 0
      )
    },
    selectedValuesLabel() {
      if (!this.selectedValues) {
        return this.noneSelectedLabel ?? this.$t('global.aucun_selectionne')
      }
      var lSelected = this.selectedValues.length
      if (lSelected > this.numberDisplayed) {
        return (
          lSelected +
          ' ' +
          (this.selectedLabel ?? this.$t('global.selectionnes'))
        )
      } else if (lSelected === 0) {
        return this.noneSelectedLabel ?? this.$t('global.aucun_selectionne')
      } else if (
        lSelected <= this.numberDisplayed &&
        this.selectedValues.length > 0
      ) {
        return this.values
          .filter((value) => this.modelValue.includes(value[this.trackBy]))
          .map((value) => value[this.displayKey])
          .join(', ')
      }
    }
  },
  methods: {
    selectAll() {
      this.selectedValues = this.values.map((value) => value[this.trackBy])
    },
    unselectAll() {
      this.selectedValues = []
    }
  }
}
</script>

<style scoped></style>
