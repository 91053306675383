<template>
  <BaseCenteredModal
    :title="$t('global.scan_qr_code')"
    :large="false"
    :translucent="false"
    :is-modal-open="opened"
    @close="onClose"
  >
    <template #body>
      <div v-if="generated" class="w-full flex flex-col gap-5 md:flex-row md:items-start md:flex-nowrap">
        <div class="w-full flex flex-wrap items-start justify-center gap-3">
          <div class="w-full text-center">
            {{ $t('global.qrcode_expiration_in') }}
          </div>

          <div class="bg-gray-100 py-5 px-5 rounded border text-center text-5xl text-gray-700 font-bold">
            {{ restTime }}
          </div>
        </div>

        <div class="w-full flex items-center justify-center">
          <QrcodeVue
            :value="qrcodeDataString"
            :level="'L'"
            :size="250"
          />
        </div>
      </div>

      <div v-else class="w-full flex flex-col gap-4">
        <p>{{ $t('global.confirm_qrcode_password') }}</p>

        <BaseInput
          v-model:model-value="userPassword"
          :name="'qrcode_pwd'"
          :type="'password'"
          :disabled="disableForm"
          :errors="errorPassword ? [$t('validation.wrong_password')] : null"
        />

        <BaseButton
          :title="$t('global.generate')"
          :display-loader="disableForm"
          :disabled="disableForm || !userPassword"
          :class-name="'w-full'"
          @click="handleQRCodePassword"
        />
      </div>
    </template>
  </BaseCenteredModal>
</template>

<script>
import BaseCenteredModal from "@c/addf-package/components/modal/BaseCenteredModal.vue";
import BaseInput from "@c/addf-package/components/BaseInput/BaseInput.vue";
import BaseButton from "@c/addf-package/components/BaseButton/BaseButton.vue";
import apiClient from "@u/apiClient";
import QrcodeVue from 'qrcode.vue'
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: 'AuthQRCodeGeneratorComponent',
  components: {BaseButton, BaseInput, BaseCenteredModal, QrcodeVue},
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['action:close'],
  data: () => {
    let time = new Date()
    let mobile_qrcode_expiration = localStorage.getItem('mobile_qrcode_expiration')
      ? new Date(localStorage.getItem('mobile_qrcode_expiration'))
      : null
    let dataString = localStorage.getItem('mobile_qrcode_data')
    let qrcodeData = dataString ? JSON.parse(dataString) : null
    let generated = qrcodeData && mobile_qrcode_expiration && mobile_qrcode_expiration > time

    return {
      generated: generated,
      disableForm: false,
      userPassword: null,
      qrcodeData: qrcodeData,
      qrcodeDataString: dataString,
      qrcodeExpiration: mobile_qrcode_expiration,
      currentTime: generated ? moment() : null,
      currentTimeInterval: null,
      errorPassword: false,
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
    }),
    restTime() {
      let current = this.currentTime
      let minutes = current.diff(this.qrcodeExpiration, 'minutes') * -1
      let seconds = current.diff(this.qrcodeExpiration, 'seconds') * -1

      if (minutes > 0) {
        seconds -= minutes * 60
      }

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    }
  },
  watch: {
    opened(newValue, oldValue) {
      if (newValue) {
        this.changeCurrentTime()
      }
    },
  },
  mounted() {
    this.changeCurrentTime()
  },
  methods: {
    handleQRCodePassword() {
      this.disableForm = true
      this.errorPassword = false

      apiClient.post('/login/qrcode/generate', {
        password: this.userPassword,
      }).then(({data}) => {
        setTimeout(() => {
          this.generateQRCode(data)
        }, 500)
      })
    },
    onClose() {
      this.$emit('action:close')
      this.userPassword = null
      if (this.currentTimeInterval) {
        clearInterval(this.currentTimeInterval)
        this.currentTimeInterval = null
      }
    },
    generateQRCode(data) {
      if (data.success) {
        const time = new Date()

        time.setMinutes(time.getMinutes() + 5)

        localStorage.setItem('mobile_qrcode_expiration', time.toString())

        this.qrcodeData = {
          user_name: data.user_name,
          user_sub_domain: data.tenant,
          user_domain: location.host.replace(`${data.tenant}.`, ''),
          password: this.userPassword,
        }

        this.qrcodeDataString = JSON.stringify(this.qrcodeData)

        localStorage.setItem('mobile_qrcode_data', this.qrcodeDataString)

        this.qrcodeExpiration = time

        this.generated = true

        this.changeCurrentTime()

        this.userPassword = null
      } else {
        this.errorPassword = true
      }

      this.disableForm = false
    },
    changeCurrentTime() {
      this.currentTime = moment()
      this.generated = this.qrcodeExpiration > new Date()

      if (this.generated) {
        this.currentTimeInterval = setInterval(() => {
          this.currentTime = moment()

          this.generated = this.qrcodeExpiration > new Date()

          if (!this.generated) {
            clearInterval(this.currentTimeInterval)
            this.currentTimeInterval = null
          }
        }, 1000)
      } else if (this.currentTimeInterval) {
        clearInterval(this.currentTimeInterval)
        this.currentTimeInterval = null
      }
    },
  },
}
</script>

<style>

</style>
