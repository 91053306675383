import apiClient from '@u/apiClient'

let base_errors = {deceased: {}, events: []}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async deleteWikiDocumentById({commit}, payload) {
    const url = payload.type ?
      `wiki-document/delete/${payload.wiki_document_id}/?type=${payload.type}` :
      `wiki-document/delete/${payload.wiki_document_id}`
    await apiClient
      .delete(url)
      .then(async (response) => {
        if (response.status === 200) {
          if (payload.type) {
            commit('setPlaceList', response.data.places)
          } else {
            await commit('setCity', response.data.wiki_city)
          }
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('deleteWikiItemById', reason)
      })
  },
  async undoDeleteWikiDocumentById({commit}, payload) {
    const url = payload.type ?
      `wiki-document/undo-delete/${payload.wiki_document_id}/?type=${payload.type}` :
      `wiki-document/undo-delete/${payload.wiki_document_id}`
    await apiClient
      .get(url)
      .then(async (response) => {
        if (response.status === 200) {
          console.log(payload.type)
          if (payload.type) {
            commit('setPlaceList', response.data.places)
          } else {
            await commit('setCity', response.data.wiki_city)
          }
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('undoDeleteWikiItemById', reason)
      })
  }
}
