/* eslint-disable camelcase */
import apiClient from '@u/apiClient'
// import router from '@/router'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  async fetchPackIndex({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('pack')
      .then(async (response) => {
        await commit('setPackList', response.data.packs)
        await commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchPackIndex', reason)
      })
  },
  async fetchPackByID({ commit }, id) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`pack/${id}`)
      .then(async (response) => {
        commit('setPack', response.data.pack)
        commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchPackByID', reason)
      })
  },
  async fetchNewPack({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=pack')
      .then(async (response) => {
        await commit('setPack', response.data.pack)
        await commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNewPack', reason)
      })
  },
  async fetchPackActivitiesByPackID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`pack/fetch-activities/${payload.id}`)
      .then(async (response) => {
        await commit('setMetadataActivities', response.data.metadata)
        await commit('setPackActivity', response.data.activities)
      })
      .catch(async (reason) => {
        await console.error('fetchPackActivitiesByPackID', reason)
      })
  },
  // endregion

  // region store
  async storeNewPack({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post('pack', payload.pack)
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('storeNewPack', reason)
      })
  },
  // endregion

  // region update
  async updatePack({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`pack/${payload.id}`, payload.pack)
      .then((response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('updatePack', reason)
      })
  },
  // endregion

  // region delete
  async deletePackById({ commit }, payload) {
    await apiClient
      .delete(`pack/pack-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setPackList', response.data.packs)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deletePackById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeletePackById({ commit }, payload) {
    await apiClient
      .get(`pack/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setPackList', response.data.packs)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeletePackById', reason)
      })
  },
  // endregion

  //region order pack items
  async reorderInvoiceItems({ commit }, payload) {
    apiClient
      .put(`pack/reorder-items/${payload.pack_id}`, {
        items: payload.ordered_list
      })
      .then(async (response) => {
        if (response.status === 200) {
          commit('setPack', response.data.pack)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  }
  //endregion
}
