import apiClient from '@u/apiClient'
import actionsChift from '@/store/modules/settings/corporation/company/actions.chift'
import actionsSquareup from '@/store/modules/settings/corporation/company/actions.squareup'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  ...actionsChift,
  ...actionsSquareup,

  // region fetch
  async fetchCompanyIndex({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('company')
      .then(async (response) => {
        await commit('setCompanyList', response.data.companies)
        await commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchCompanyIndex', reason)
      })
  },
  async fetchCompanyByID({ commit }, id) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`company/${id}`)
      .then(async (response) => {
        commit('setCompany', response.data.company)
        commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchCompanyByID', reason)
      })
  },
  async fetchNewCompany({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=company')
      .then(async (response) => {
        await commit('setCompany', response.data.company)
        await commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNewCompany', reason)
      })
  },
  async fetchCompanyActivitiesByCompanyID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`company/fetch-activities/${payload.company_id}`)
      .then(async (response) => {
        await commit('setCompanyActivity', response.data.activities)
        await commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error('fetchCompanyActivitiesByCompanyID', reason)
      })
  },
  // endregion

  // region store
  async storeNewCompany({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()
    // console.log(payload.company)
    formData.append('data', JSON.stringify(payload.company))
    if (payload.company.new_logo_file) {
      formData.append('new_logo_file', payload.company.new_logo_file)
    }

    await apiClient
      .post('company', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('storeNewCompany', reason)
      })
  },
  // endregion

  // region update
  async updateCompany({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.company))
    if (payload.company.new_logo_file) {
      formData.append('new_logo_file', payload.company.new_logo_file)
    }
    formData.append('_method', 'PUT')

    await apiClient
      .post(`company/${payload.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('updateCompany', reason)
      })
  },
  // endregion

  // region delete
  async deleteCompanyById({ commit }, payload) {
    await apiClient
      .delete(`company/company-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setCompanyList', response.data.companies)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteCompanyById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteCompanyById({ commit }, payload) {
    await apiClient
      .get(`company/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setCompanyList', response.data.companies)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteCompanyById', reason)
      })
  }
  // endregion
}
