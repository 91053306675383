import apiClient from '@u/apiClient'
import Promise from "lodash-es/_Promise";

const base_errors = {}
export default {
  async searchForAPerson({ commit }, payload) {
    await apiClient
      .get(`search-person?term=${payload.filterText}`)
      .then(async (response) => {
        await commit('setPersonList', response.data.personList)
      })
      .catch(async (reason) => {
        await console.error('searchForAPerson', reason)
      })
  },

  checkContact({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .post(`check-person/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
}
