export default {
  getSurveys(state) {
    return state.surveys
  },
  getSurvey(state) {
    return state.survey
  },
  getDataTransferObject(state) {
    return state.dataTransferObject
  },
  getDataSectionObject(state) {
    return state.dataSectionObject
  }
}
