<template>
  <div class="flex h-5/6 max-h-screen px-36">
    <div class="flex h-full w-full flex-col gap-y-8">
      <div v-if="$can('manage_wiki_city')" class="flex justify-end">
        <BaseButton
          :title="$t('button.add_wiki_city')"
          icon="plus"
          @click="
            $router.push({ name: 'create_wiki_city_information_page_route' })
          "
        />
      </div>

      <div class="grid max-h-full w-full grid-cols-2 gap-x-16">
        <WikiClosestCitiesList />

        <WikiFavoriteCitiesList />
      </div>
    </div>
  </div>
</template>

<script>
import WikiClosestCitiesList from '@/views/wiki/communes/index/components/WikiClosestCitiesList.vue'
import WikiFavoriteCitiesList from '@/views/wiki/communes/index/components/WikiFavoriteCitiesList.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'TheWikiCityIndexPage',
  components: { BaseButton, WikiFavoriteCitiesList, WikiClosestCitiesList }
}
</script>
