<template>
  <button
    type="button"
    class="btn flex space-x-2"
    :class="className"
    aria-expanded="false"
  >
    <FontAwesomeIcon v-if="icon" :icon="[iconType, icon]" />
    <span v-if="label" class="inline-block capitalize-first"
      >{{ label }} extra</span
    >
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseButtonEXTRA',
  components: { FontAwesomeIcon },
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    iconType: {
      type: String,
      required: false,
      default: 'fal'
    },
    className: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {}
}
</script>
