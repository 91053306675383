import store from '@/store'
import DashboardTypes from '@/assets/enums/dashboard/DashboardTypes'

const WorkInProgressComponent = () =>
  import('@/views/wip/WorkInProgressComponent')
const TheStatisticsIndexPage = () =>
  import('@/views/statistics/TheStatisticsIndexPage.vue')

export const statisticsRootPages = {
  path: 'statistics',
  name: 'statistics_page_route',
  component: WorkInProgressComponent
}

export const statisticsSubPages = [
  {
    path: 'statistics/dashboard',
    children: [
      {
        path: '',
        name: 'dashboard_statistics_route',
        component: TheStatisticsIndexPage,
        meta: {
          route_name: 'dashboard_statistics_route_id'
        },
        children: [
          {
            path: '',
            beforeEnter: (from, to, next) => {
              console.log('beforeEnter')
              let template = store.getters[
                'metadata/getTemplateDashboardByType'
              ](DashboardTypes.PLANNING)
              if (Array.isArray(template) && template[0]) {
                next({
                  name: 'dashboard_statistics_route_id',
                  params: { id: template[0].id }
                })
                return
              }
              next()
              return
            }
          },
          {
            path: ':id',
            name: 'dashboard_statistics_route_id',
            component: TheStatisticsIndexPage
          }
        ]
      }
    ]
  },
  {
    path: 'statistics/investigations',
    name: 'investigations_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/deceased',
    name: 'deceased_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/funeral',
    name: 'funeral_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/employees',
    name: 'employees_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/invoicing',
    name: 'invoicing_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/locations',
    name: 'locations_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/products',
    name: 'products_statistics_route',
    component: WorkInProgressComponent
  }
]
