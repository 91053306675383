<template>
  <BaseCenteredModal
    v-model:is-modal-open="open"
    :title="$t('global.unread_version_note')"
    :large="true"
    :full-screen="true"
    @close="$emit('close')"
  >
    <template #body>
      <div
        v-if="versions"
        class="mx-auto mb-12 h-screen w-10/12 overflow-auto py-6 pb-8 scrollbar-thin scrollbar-track-white scrollbar-thumb-gray-300"
      >
        <div class="mx-auto mb-8 flex w-10/12 justify-end gap-x-2">
          <BaseButton
            :title="$t('global.see_all_version_note')"
            @click="redirectToAllVersionNote"
          />
          <BaseButton
            :title="$t('button.mark_all_as_read')"
            color="warning"
            @click="confirmReadingAllVersionNotes()"
          />
        </div>
        <div
          v-for="(version, index) in versions"
          :key="index"
          class="mx-auto mb-4 block w-10/12 rounded border border-slate-300 p-4"
        >
          <VersionCardComponent
            :version="version"
            @confirm-reading-version-note="confirmReadingVersionNote"
          />
        </div>
      </div>
    </template>
  </BaseCenteredModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VersionCardComponent from '@ui/modal/versionNote/VersionCardComponent.vue'
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'UnreadedVersionNoteModal',
  components: { BaseButton, BaseCenteredModal, VersionCardComponent },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    versions: {
      type: Object,
      required: true
    }
  },
  emits: ['close', 'closeModalVersion'],
  computed: {
    ...mapGetters({ initialMetadata: 'metadata/getInitialMetadata' })
  },
  methods: {
    ...mapActions({
      processAddReadVersionNoteAction: 'metadata/addReadVersionNote',
      processRefreshSpecificInitialMetadataModelsAction:
        'metadata/refreshSpecificInitialMetadataModels'
    }),
    async confirmReadingVersionNote(version) {
      await this.processAddReadVersionNoteAction({
        versions: [version],
        successCallback: async () => {
          this.$h.toastify(
            this.$t('global.version_marked_as_read', { var: version.version }),
            { className: 'toastify-content success' }
          )
          await this.processRefreshSpecificInitialMetadataModelsAction({
            model: 'unreaded_version_notes'
          })
          if (
            this.initialMetadata?.models?.unreaded_version_notes?.length < 1
          ) {
            this.$emit('closeModalVersion', false)
          }
        }
      })
    },
    confirmReadingAllVersionNotes() {
      this.processAddReadVersionNoteAction({
        versions: this.versions,
        successCallback: () => {
          this.$h.toastify(this.$t('global.all_versions_marked_as_read'), {
            className: 'toastify-content success'
          })
          this.processRefreshSpecificInitialMetadataModelsAction({
            model: 'unreaded_version_notes'
          })
          this.$emit('closeModalVersion', false)
        }
      })
    },
    redirectToAllVersionNote() {
      this.$router.push({ name: 'settings_version_note_index_route' })
      this.$emit('closeModalVersion', false)
    }
  }
}
</script>
