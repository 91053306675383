<template>
  <div
    class="flex h-full max-h-full flex-col gap-2 overflow-hidden rounded-lg bg-white p-6 pt-3 dark:bg-slate-700"
  >
    <RouterView />
  </div>
</template>

<script>
export default {
  name: 'TheStockPage'
}
</script>
