<template>
  <div
    class="my-3 flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-100 bg-white px-6 py-5 shadow-sm"
  >
    <div class="flex-1">
      <button
        class="flex flex-row gap-2 text-sm font-medium text-gray-900"
        @click="redirectToCityPage"
      >
        <span>{{ city.zip }}</span>
        <span >{{ city?.name[$i18n.locale] }}</span>
      </button>
    </div>
    <div v-if="city.distance_in_kilometers" class="shrink-0">
      <span class="rounded bg-primary px-2 py-1 text-xs text-contrast-primary">
        {{ city.distance_in_kilometers }} km.
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WikiCityCard',
  props: {
    city: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    })
  },
  methods: {
    redirectToCityPage() {
      console.log('redirectToCityPage')
      this.$router.push({
        name: 'wiki_city_municipal_administration_index_page_route',
        params: { city_id: this.city.parent_id ?? this.city.id }
      })
    }
  }
}
</script>
