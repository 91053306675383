export default {
  setContacts(state, contacts) {
    if (!('contacts' in state.dossier)) {
      state.dossier.contacts = []
    }
    state.dossier.contacts = contacts
  },
  addContact(state, contact) {
    if (!('contacts' in state.dossier)) {
      state.dossier.contacts = []
    }
    state.dossier.contacts.push({
      ...contact,
      brut_last_name: false,
      brut_first_name: false,
      last_name: '',
      first_name: '',
      is_company: 0
    })
  },
  removeContact(state, index) {
    state.dossier.contacts.splice(index, 1)
  }
}
