import apiClient from '@u/apiClient'
import actionsSquareup from '@/store/modules/settings/corporation/site/actions.squareup'

const baseErrors = []

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  ...actionsSquareup,

  // region fetch
  async fetchSiteIndex({ commit }) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get('site')
      .then(async (response) => {
        await commit('setSiteList', response.data.sites)
        await commit('setSiteMetaData', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchSiteIndex', reason)
      })
  },
  async fetchSiteByID({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get(`site/${payload.id}`)
      .then(async (response) => {
        await commit('setSite', response.data.site)
        await commit('setSiteMetaData', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchSiteByID', reason)
      })
  },
  async fetchNewSite({ commit }) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get('model/new?model=site')
      .then(async (response) => {
        await commit('setSite', response.data.site)
        await commit('setSiteMetaData', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNewSite', reason)
      })
  },
  async fetchSiteActivitiesBySiteID({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get(`site/fetch-activities/${payload.site_id}`)
      .then(async (response) => {
        await commit('setSiteActivities', response.data.activities)
        await commit('setMetadataActivities', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },
  // endregion

  // region store
  async storeNewSite({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })

    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.site))

    if (payload.site.new_logo_file) {
      formData.append('new_logo_file', payload.site.new_logo_file)
    }

    await apiClient
      .post('site', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.status === 201) {
          commit('auth/setErrors', baseErrors, { root: true })
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('storeNewSite', reason)
      })
  },
  // endregion

  // region update
  async updateSite({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })

    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.site))

    if (payload.site.new_logo_file) {
      formData.append('new_logo_file', payload.site.new_logo_file)
    }
    formData.append('_method', 'PUT')

    await apiClient
      .post(`site/${payload.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.status === 201) {
          commit('auth/setErrors', baseErrors, { root: true })
          processSuccessCallback(payload)
          // router.push({ name: 'settings_list_site_route' })
        }
      })
      .catch((reason) => {
        console.error('updateSite', reason)
      })
  },
  // endregion

  // region delete
  async deleteSiteById({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .delete(`site/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setSiteList', response.data.sites)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteSiteById', reason)
      })
  },
  async deleteLocationBySiteId({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .delete(`site/${payload.site_id}/${payload.location_id}/`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setSite', response.data.site)
          await commit('setSiteMetaData', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteLocationById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteSiteById({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get(`site/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setSiteList', response.data.sites)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteSiteById', reason)
      })
  },

  async undoDeleteLocationBySiteId({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .delete(`site/undo-delete/${payload.site_id}/${payload.location_id}/`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setSite', response.data.site)
          await commit('setSiteMetaData', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteLocationById', reason)
      })
  }
  // endregion
}
