export default {
  setEvents(state, eventsList) {
    state.eventsList = eventsList
  },
  setEventsUnplanned(state, eventsListUnplanned) {
    state.eventsListUnplanned = eventsListUnplanned
  },
  // filterEventsUnplanned(state, payload) {
  //   console.log('filteredUnplannedEvents')
  //   let tempUnplannedEvents = state.eventsListUnplanned;
  //   let visibleTypes = payload.funeralHomeList.filter(type => !type.hidden).map(type => type.id);
  //   state.eventsListUnplanned = tempUnplannedEvents.filter(el => visibleTypes.includes(el.type))
  // },
  setEvent(state, event) {
    state.event = event
  },
  setPlanningMetadata(state, metadata) {
    state.planningMetadata = metadata
  },
  setEventMetadata(state, metadata) {
    state.eventMetadata = metadata
  },
  setEventDisponibilities(state, timeslots) {
    state.eventDisponibilities = timeslots
  },
  setEventTimeSlotInEvent(state, timeslots) {
    state.event.timeslots = timeslots
  },
  setCalendarState(state, calendarState) {
    state.calendarState = calendarState
  },
  setEventDossier(state, dossier) {
    state.event.dossier_id = dossier.id
    state.event.funeral_home_id = dossier.funeral_home_id
    state.event.dossier.title = dossier.title
  },
  alterEvent(state, payload) {
    state.event[payload.attribute] = payload.value
  },
  setEventDateFrom(state, date_from) {
    state.event.date_from = date_from
    state.event.date_from_undefined_time = false
  },
  setEventDateTo(state, date_to) {
    state.event.date_to = date_to
    state.event.date_to_undefined_time = false
  },
  setEventFullDay(state, fullDay) {
    state.event.full_day = fullDay
  },
  deleteEventKey(state, attribute) {
    delete state.event[attribute]
  },
  updateEventInList(state, event) {
    state.eventsList = [
      ...state.eventsList.filter((ev) => ev.id !== event.id),
      event
    ]
  },
  alterEventListAddOrRemoveEvent(state, event) {
    state.eventsList.push(event)
  },
  updateEvent(state, eventData) {
    // state.eventsList.push(event)
  },
}
