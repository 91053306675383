export default {
  setSurveyList(state, surveyList) {
    state.surveyList = surveyList
  },
  setSurvey(state, survey) {
    state.survey = survey
  },
  setDataTransferObject(state, payload) {
    state.dataTransferObject = payload
  },
  setDataSectionObject(state, payload) {
    state.dataSectionObject = payload
  }
}
