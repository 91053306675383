import apiClient from '@u/apiClient'

export default {
  getSquareUpAuthLink({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`company/${payload.id}/square-up/get-auth-link`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getSquareUpAuthStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`company/${payload.id}/square-up/auth-status`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
