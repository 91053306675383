const TheScreenIndexPage = () =>
  import(
    '@/views/settings/management/screenSettings/index/TheScreenIndexPage.vue'
  )

const ScreenEditPage = () =>
  import('@/views/settings/management/screenSettings/form/ScreenEditPage.vue')

const TheScreenConfigIndexPage = () =>
  import(
    '@/views/settings/management/screenSettings/index/TheScreenConfigIndexPage.vue'
  )

const ScreenConfigEditPage = () =>
  import(
    '@/views/settings/management/screenSettings/form/ScreenConfigEditPage.vue'
  )

export const settingsScreenSettingsRoutes = [
  {
    path: 'screen-settings',
    children: [
      {
        path: '',
        name: 'screen_settings_index_page',
        component: TheScreenIndexPage
      },
      {
        path: ':screenId',
        name: 'screen_settings_edit_page',
        component: ScreenEditPage,
        props: true
      },
      {
        path: 'new',
        name: 'screen_settings_new_page',
        component: ScreenEditPage
      },
      {
        path: 'screen-config',
        children: [
          {
            path: '',
            name: 'screen_config_index_page',
            component: TheScreenConfigIndexPage
          },
          {
            path: ':screenConfigId',
            name: 'screen_config_edit_page',
            component: ScreenConfigEditPage,
            props: true
          },
          {
            path: 'new',
            name: 'screen_config_new_page',
            component: ScreenConfigEditPage
          }
        ]
      }
    ]
  }
]
