<template>
  <div
    class="flex h-full w-full flex-col divide-y bg-white shadow sm:rounded-lg"
  >
    <div class="flex shrink-0 flex-row items-center">
      <div class="flex-1 p-4">
        <h3 class="text-3xl font-bold leading-6 text-gray-900">Import</h3>
      </div>
      <div class="shrink-0 px-4 py-2"></div>
    </div>
    <div class="min-h-0 flex-1 overflow-y-auto p-4">
      <RouterView />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TheImportPage',
  computed: {
    ...mapGetters({})
  },
  mounted() {},
  methods: {}
}
</script>
